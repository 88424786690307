<template>
  <component
    :is="to ? 'router-link' : tag"
    :class="[
      'icon',
      `icon-theme--${theme}`,
      `icon--size-${size}`,
      `icon-${name}`,
    ]"
    :to="to"
    @click.stop="fn"
  >
    <inline-svg
      :style="
        rotation && rotation !== 0
          ? { transform: `rotate(${rotation}deg)` }
          : null
      "
      :src="require(`@/assets/svg/icons/${name}.svg`)"
    />
  </component>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'm',
    },
    fn: {
      type: Function,
      default: () => {},
    },
    to: {
      type: [String, Object],
      default: () => {},
    },
    tag: {
      type: String,
      default: 'div',
    },
    rotation: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
  .icon {
    cursor: pointer;
    display: grid;

    svg {
      fill: none;
      overflow: visible;
      transition: transform 0.2s ease;
    }

    path {
      stroke: var(--black);
      stroke-width: var(--line);
    }

    &--size-xs {
      width: $unit * 1.5;
      height: $unit * 1.5;
      svg {
        width: $unit * 1.5;
      }
    }

    &--size-s {
      --size: 10px;
      width: var(--size);
      height: var(--size);
      svg {
        width: var(--size);
        height: var(--size);
      }
    }

    &--size-m {
      --size: 12px;
      width: var(--size);
      height: var(--size);
      svg {
        width: var(--size);
        height: var(--size);
      }

      // @include mq(m) {
      //   --size: 36px;
      // }
    }

    &--size-l {
      --size: 22px;
      width: var(--size);
      height: var(--size);
      svg {
        width: var(--size);
        height: var(--size);
      }

      // @include mq(m) {
      //   --size: 36px;
      // }
    }

    &--size-xl {
      --size: 30px;
      width: var(--size);
      height: var(--size);
      svg {
        width: var(--size);
        height: var(--size);
      }

      // @include mq(m) {
      //   --size: 36px;
      // }
    }

    &-theme {
      // &--default {

      // }

      &--light {
          path {
            fill: var(--white);
            stroke: var(--white);
            transform: scale(0.8);
            transform-origin: center;
          }
        }
      &--dark {
          path {
            transform: scale(0.8);
            transform-origin: center;
          }
        }

      &--filled {
        mix-blend-mode: initial;
        path {
          fill: var(--black);
          stroke: var(--black);
          transition: fill 0.2s;
        }

        // @media (hover: hover) {
        //   &:hover {
        //     path {
        //       fill: transparent;
        //     }
        //   }
        // }

        &--light {
          mix-blend-mode: initial;
          path {
            fill: var(--yellow);
            stroke: var(--grey);
            transition: fill 0.2s;
          }
        }
      }

      &--fillable {
        mix-blend-mode: initial;
        path {
          fill: transparent;
          stroke: var(--black);
          transition: fill 0.2s;
        }

        // @media (hover: hover) {
        //   &:hover {
        //     path {
        //       fill: var(--white);
        //     }
        //   }
        // }

        &--light {
          mix-blend-mode: initial;
          path {
            fill: transparent;
            stroke: var(--yellow);
            transition: fill 0.2s;
          }
        }
      }

      &--warning {
        mix-blend-mode: initial;
        path {
          stroke: var(--warning-color);
        }
      }
      &--invert {
        path {
          fill: var(--white);
        }
      }

      &--circle {
        background: var(--black);
        border-radius: 50%;
        path {
          fill: var(--white);
        }
      }
    }

    &--close {
      position: absolute;
      top: $unit * 0.5;
      right: $unit * 0.5;
      z-index: 10;
    }

    &-select {
      margin-bottom: 1px;
    }
  }
</style>
