var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.favourites && _vm.favourites.length > 0)?_c('div',{staticClass:"favorites"},[(_vm.data.title && _vm.data.title !== '')?_c('Title',{attrs:{"data":{
      value: _vm.data.title,
      tag: _vm.data.tag_title,
      justify: _vm.data.title_justification
    }}}):_vm._e(),_c('Archive',{attrs:{"favourites":_vm.favouritesArray,"data":{
      archive_type: 'archive',
      archive: 'page',
      meta: 'favorite',
      pages: _vm.favouritesArray,
      layout: [
        {
          media_query: 'default',
          layout_size: '12',
          layout_type: 'grid',
        },
        {
          media_query: 's',
          layout_size: '4',
          layout_type: 'grid',
        } ],
    }}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }