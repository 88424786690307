var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{ref:"sidebarMenu",class:[
    'sidebar-menu',
    _vm.menuOverflow ? 'sidebar-menu--overflown' : null,
    _vm.layout && _vm.layout !== '' ? ("sidebar-menu--" + _vm.layout) : null ]},[_c('router-link',{staticClass:"logo typo--3",attrs:{"to":"/"}},[_c('inline-svg',{attrs:{"src":require('@/assets/svg/logo-desktop.svg')}})],1),_c('div',[_c('div',{staticClass:"sidebar-menu--1"},_vm._l((_vm.sidebarMenu1),function(item){return _c('div',{key:item.id},[(_vm.checkIfBlockIsVisible(item.acf.roles_admitted, _vm.userRole))?_c('div',{staticClass:"sidebar-menu__block"},[_c('h4',{staticClass:"trim--4"},[_vm._v(" "+_vm._s(item.content)+" ")]),_vm._l((item.children),function(child){return _c('div',{key:child.id},[(_vm.checkIfBlockIsVisible(child.acf.roles_admitted, _vm.userRole))?_c('div',[(child.children.length === 0)?_c('Cta',{attrs:{"theme":"overflow","data":{
                  url: child.url,
                  title: child.content,
                }}}):_c('Sub',{staticClass:"sidebar-menu__block",attrs:{"item":child},on:{"check-overflow":_vm.checkOverflow}})],1):_vm._e()])})],2):_vm._e()])}),0),_c('div',{staticClass:"sidebar-menu__block"},_vm._l((_vm.sidebarMenu2),function(item){return _c('Cta',{key:item.id,attrs:{"theme":"overflow","data":{
          url: item.url,
          title: item.content,
          target: item.target,
        }}})}),1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }