<template>
  <header :class="['page-cover', data.size ? 'page-cover--l' : null]">
    <Figure
      v-if="data.image"
      class="page-cover__background"
      :data="{
        default: data.image,
        object_fit: 'cover',
      }"
    />
    <div
      v-else-if="data.video"
      class="page-cover__background page-cover__background--video"
    >
      <video
        autoplay
        muted
        loop
        :src="data.video"
      />
    </div>
    <Spacer
      x="m"
      y="m"
      class="page-cover__content fading"
    >
      <div
        v-if="data.rich_text"
        v-visible="false"
        class="page-cover__description typo--6"
        v-html="data.rich_text"
      />
      <div class="page-cover__content__center">
        <h3
          v-if="currentPost && computedLabel"
          class="typo--3"
        >
          {{ computedLabel }}
        </h3>
        <h1
          v-if="currentPost"
          :class="data.title && data.title.length > 80 ? 'typo--2' : null"
        >
          {{ data.title || currentPost.title.rendered }}
        </h1>
        <h1
          v-else-if="data.title"
          :class="data.title && data.title.length > 80 ? 'typo--2' : null"
        >
          {{ data.title }}
        </h1>
      </div>
      <div
        v-if="data.rich_text"
        class="page-cover__description typo--6"
        v-html="data.rich_text"
      />
    </Spacer>
    <FavoriteToggle
      v-if="!$route.meta.noFavorite"
      :id="data.id"
    />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import Figure from '@/components/media/figure';
import FavoriteToggle from '@/components/ui/favorite-toggle';

export default {
  name: 'PageCover',
  components: {
    Figure,
    FavoriteToggle,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(['currentPost']),
    computedLabel() {
      if (this.data.label && this.data.label !== '') {
        return this.data.label;
      }
      if (this.currentPost.parents.length !== 0) {
        return this.currentPost.parents[0].post_title;
      }
      // return this.currentPost.title.rendered;
      return null;
    },
  },
};
</script>

<style lang="scss">
  .page-cover {
    background-color: var(--green);
    display: grid;
    min-height: calc(70 * var(--vh));
    justify-items: center;

    > * {
      grid-area: 1 / 1;
    }

    // &--l {
    //   min-height: 500px;
    // }

    // @include mq(s) {
    //   min-height: 500px;

    //   &--l {
    //     min-height: 700px;
    //   }
    // }

    &__content {
      display: grid;
      max-width: 900px;
      justify-items: center;
      row-gap: var(--spacer-m);
      text-align: center;
      z-index: 1;

      &__center {
        align-self: center;
      }
    }

    &__background {
      &--video {
        max-height: calc(70 * var(--vh));
        width: 100%;

        video {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }

    &__description {
      text-align: center;
      align-self: end;
    }

    .favorite-toggle {
      align-self: start;
      justify-self: end;
      margin: var(--spacer-m);

      @include mq(s) {
        align-self: end;
      }
    }
  }
</style>
