<template>
  <Spacer
    :id="layout.section_id ? layout.section_id : null"
    class="section"
    :style="{
      backgroundColor:
        layout.background_color !== '' ? `var(--${layout.background_color}` : null,
    }"
    tag="section"
    :template="`section-${layout.section_spacer}`"
  >
    <Wrapper
      :template="`section-${layout.gap}`"
      :size="
        layout.section_wrapper && layout.section_wrapper !== 'no'
          ? layout.section_wrapper
          : undefined
      "
    >
      <slot />
    </Wrapper>
  </Spacer>
</template>

<script>
export default {
  name: 'Section',
  props: {
    layout: {
      type: Object,
      required: true,
    },
  },
};
</script>
