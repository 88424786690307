var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-grid"},[_vm._l((_vm.data.columns),function(column,indexA){return _c('div',{key:indexA + 10,staticClass:"text-grid__column"},_vm._l((column.text_grid),function(text,indexB){return _c('RichText',{key:indexB + 100,attrs:{"data":{
        value: text.text,
      }}})}),1)}),_vm._l((_vm.rowsNumber),function(n){return _c('Grid',{key:n,staticClass:"desktop",attrs:{"col":_vm.gridCol}},_vm._l((_vm.data.columns),function(item,indexC){return _c('RichText',{key:indexC + 1000,attrs:{"data":{
        value:
          item.text_grid[n - 1] !== undefined
            ? item.text_grid[n - 1].text
            : '',
      }}})}),1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }