export default {
  SET_LANG(state, value) {
    state.lang = value;
  },
  SET_CURRENT_POST(state, value) {
    state.currentPost = value;
  },
  SET_TRANSITION(state, value) {
    state.transition = value;
  },
  SET_SNACKBAR(state, value) {
    state.snackbar = value;
  },
  INIT_ASSETS_IN_COLLECTION(state, value) {
    state.assetsInCollection = value;
  },
  ADD_ASSET_TO_COLLECTION(state, value) {
    state.assetsInCollection.push(value);
    localStorage.assetsInCollection = JSON.stringify(state.assetsInCollection);
  },
  REMOVE_ASSET_FROM_COLLECTION(state, value) {
    const assetsInLocastorageIndex = state.assetsInCollection.findIndex(
      (x) => x.id === value,
    );
    if (assetsInLocastorageIndex > -1) {
      state.assetsInCollection.splice(assetsInLocastorageIndex, 1);
    }
    localStorage.assetsInCollection = JSON.stringify(state.assetsInCollection);
  },
  CLEAR_ASSETS_IN_COLLECTION(state) {
    state.assetsInCollection = [];
    localStorage.assetsInCollection = [];
  },

  TOGGLE_MOBILE_HEADER(state, value) {
    if (value) {
      state.mobileHeaderIsVisible = true;
    } else {
      state.mobileHeaderIsVisible = false;
    }
  },

  TOGGLE_MOBILE_MENU(state, value) {
    if (value !== undefined) {
      state.mobileMenuIsVisible = value;
    } else {
      state.mobileMenuIsVisible = !state.mobileMenuIsVisible;
    }

    if (state.mobileMenuIsVisible) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.removeAttribute('style');
    }
  },
};
