var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('main',[_c('Media',{ref:"product-gallery",staticClass:"product__gallery",attrs:{"data":{
      type: 'gallery',
      gallery: _vm.productGallery,
      ratio: true,
      lazyload: false
    }}}),_c('Title',{attrs:{"data":{ value: _vm.post.name }}}),_c('Richtext',{attrs:{"data":{
      value: _vm.product.description ? _vm.product.description : _vm.post.description
    }}}),(_vm.hasVariations)?_c('div',_vm._l((_vm.attributes),function(attribute,index){return _c('div',{key:index},[_c('Flex',[_c('Select',{staticClass:"product__variation-selector",attrs:{"data":_vm.getAttribute(attribute),"model":attribute.model},on:{"update:model":function($event){return _vm.$set(attribute, "model", $event)}},model:{value:(attribute.model),callback:function ($$v) {_vm.$set(attribute, "model", $$v)},expression:"attribute.model"}},[_c('label',{attrs:{"slot":"label","for":"terms"},slot:"label"},[_vm._v(_vm._s(attribute.name))])]),(attribute.model)?_c('Icon',{attrs:{"name":"close","theme":"small","fn":function () { return _vm.resetAttribute(attribute); }}}):_vm._e()],1)],1)}),0):_vm._e(),(_vm.hasMetaInfos)?_c('Spacer',{attrs:{"y":"m"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",attrs:{"id":'product-'+_vm.product.id,"tag":"form","novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.callback)}}},_vm._l((_vm.product.acf.meta_infos),function(metaInfo,index){return _c('div',{key:index},[_c(metaInfo.type,{tag:"component",attrs:{"data":{
              id: metaInfo.slug,
              type: 'text',
              model: 'null',
            },"model":_vm.metaInfos[metaInfo.slug]},on:{"update:model":function($event){return _vm.$set(_vm.metaInfos, metaInfo.slug, $event)}}},[_c('label',{attrs:{"slot":"label","for":metaInfo.slug},slot:"label"},[_vm._v(_vm._s(metaInfo.title)+" ")])])],1)}),0)]}}],null,false,2292862714)})],1):_vm._e(),(_vm.product)?_c('Price',{attrs:{"product":_vm.product}}):_vm._e(),_c('Availability',{attrs:{"product":_vm.product}}),_c('Number',{attrs:{"data":{
      id: "number",
      fn: _vm.numberChanged,
      itemKey: _vm.product.id,
      readonly: _vm.cartLoading || _vm.isOos,
    },"value":_vm.numberToBeAdded},on:{"update:value":function($event){_vm.numberToBeAdded=$event}}}),_c('Spacer',{attrs:{"y":"m"}},[_c('Cta',{attrs:{"data":{
        title: _vm.$labels.add_to_cart,
        url: false,
        fn: _vm.addToCart,
      },"disabled":!_vm.addableToCart,"theme":"button"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }