import Vue from 'vue';

const labels = {
  account: 'Account',
  account_created: 'The account has been created successfully.<br>Now you can login.',
  account_details: 'Account details',
  account_number: 'Account number',
  account_not_logged: 'Logging out...',
  accounte_creation_needed: 'You have to create an account to place this order. Plese, login if you\'ve already one.',
  actions: 'Actions',
  add: 'Add',
  add_coupon: 'Apply',
  add_new_card: 'Add new card',
  add_to_cart: 'Add to cart',
  additional_information: 'Additional information',
  address_1: 'Address',
  address_2: 'Address details',
  address_2_placeholder: 'Apartment, suit, unit, etc.',
  addresses: 'Addresses',
  available: 'available',
  back_to_cart: 'Back to cart',
  bank: 'Bank',
  bic_swift: 'BIC/SWIFT',
  billing_address: 'Billing Address',
  cancel: 'Cancel',
  cancel_card: 'Delete card',
  cancel_card_dialog: 'Are you sure you want to delete this card?',
  cancel_order: 'Cancel order',
  cancel_order_dialog: 'Are you sure you want to cancel this order?',
  cancel_subscription: 'Cancel subscription',
  cancel_subscription_dialog: 'Are you sure you want to cancel this subscription?',
  cannot_pay_for_order: 'You cannot pay for this order',
  cart: 'Cart',
  change_payment: 'Change payment',
  change_payment_method: 'Change payment method',
  changed_payment_method: 'Your payment method was updated',
  checkout: 'Checkout',
  choose_option: 'Choose an option',
  city: 'City',
  close: 'Close',
  collection_cta: 'Add to Collection',
  collection_cta_all: 'Add all to Collection',
  collection_cta_remove: 'Remove all to Collection',
  collection_clear: 'Clear Collection',
  company: 'Company',
  country: 'Country',
  coupon: 'Coupon',
  create_account: 'Create account',
  credit_card_incomplete: 'Credit card form incomplete',
  cta: 'Read more',
  date: 'Date',
  delete: 'Delete',
  details: 'Details',
  discount: 'Discount',
  display_name: 'Display name',
  do_you_have_account: 'Do you have an account?',
  download_cta: 'Download',
  download_all_cta: 'Download all',
  edit: 'Edit',
  email: 'Email',
  empty_cart: 'Empty Cart',
  end_date: 'End date',
  ending_in: 'ending in',
  error_confirmed_after: '',
  error_confirmed_pre: 'The field must be the same of',
  error_email: 'The email is not valid',
  error_generic: 'An error occurred',
  error_generic_customer: 'Session expired.',
  error_generic_login: 'Something went wrong during the login. You should login again.',
  error_min_after: 'characters',
  error_min_pre: 'The field must have at least',
  error_required: 'The field is required',
  error_user_addresses_update: 'A problem occurred while saving the data.',
  error_user_update: 'A problem occurred while saving the data. <br>Please check them before retrying.',
  excluded_vat: 'ex. VAT',
  excluded_vat_extended: 'excluded VAT',
  expires: 'expires',
  first_name: 'First name',
  footer_label: 'Next',
  future_payments: 'Future payments',
  go_to: 'Go to',
  go_to_checkout: 'Proceed to checkout',
  go_to_orders: 'Go to your orders',
  go_to_payment: 'Proceed to payment',
  go_to_shop: 'Go back to shop',
  hide_order_summary: 'Hide order summary',
  iban: 'IBAN',
  id: 'ID',
  insert_coupon: 'Insert your coupon',
  last_name: 'Last name',
  last_order_date: 'Last order date',
  logged_in_with_mail: 'You\'re logged with the e-mail',
  login: 'Login',
  login_sso: 'Login with Kerakoll SSO',
  logout: 'Logout',
  lost_password: 'Forgot your password?',
  make_default: 'Make default',
  may_be_interested_in: 'You may  be interested in...',
  my_account: 'My account',
  next_payment_date: 'Next payment date',
  no_order: 'No order has been placed yet.',
  no_saved_card: 'You have no saved cards',
  no_subscription: 'You have no active subscriptions.',
  old_password: 'Old password',
  order: 'Order',
  order_cancelled: 'Your order was cancelled',
  order_details: 'Order details',
  order_failed: 'Unfortunately your order cannot be processed as the originating bank/merchant has declined your transaction. Please attempt your purchase again',
  order_infos: 'Order information',
  order_notes: 'Order notes',
  order_notes_placeholder: 'Need a specific delivery day? Sending a gift? ',
  order_received: 'Your order has been received',
  order_updated: 'Your order was updated',
  order_updates: 'Order updates',
  orders: 'Orders',
  out_of_stock: 'Out of stock',
  password: 'Password *',
  new_password: 'New password *',
  update_password: 'Update password',
  password_lost: 'Forgot password?',
  pay_for_order: 'Pay for order',
  payment: 'Payment',
  payment_method: 'Payment method',
  payment_methods: 'Payment methods',
  phone: 'Phone',
  place_order: 'Place Order',
  postcode: 'Postcode / ZIP',
  product: 'Product',
  products: 'Products',
  profile: 'Profile',
  refunded: 'Refunded',
  register: 'Not registered? <strong>Create an account</strong>',
  recover: 'Recover your password',
  related_orders: 'Related orders',
  remove: 'Remove',
  renew_now: 'Renew now',
  remember_me: 'Remember me',
  repeat_password: 'Repeat password',
  results_empty: 'Nothing found.',
  results_title: 'Results',
  save: 'Save',
  save_card: 'Save card for future payments',
  saved_cards: 'Saved cards',
  saved_changes: 'Changes have been saved',
  search_placeholder: 'Search...',
  select_option: 'Select option',
  send: 'Send',
  share_cta: 'Share',
  ship_different_address: 'Ship to a different address?',
  shipping: 'Shipping',
  shipping_address: 'Shipping Address',
  shipping_exstimated: 'Extimated shipping',
  shipping_method: 'Shipping method',
  shipping_methods: 'Shipping methods',
  shipping_methods_empty_states: 'Please enter your address to know the available shipping methods.',
  shipping_subscription_address: 'Shipping subscription address',
  shop: 'shop',
  show_order_summary: 'Show order summary',
  start_date: 'Start date',
  state: 'State / Country',
  status: 'Status',
  submit: 'Submit',
  subscription: ' Subscription',
  subscription_details: ' Subscription details',
  subscription_totals: ' Subscription totals',
  subscriptions: ' Subscriptions',
  subtotal: 'Subtotal',
  terms: 'I agree with your terms and conditions.',
  thank_you: 'Thank you for your order!',
  thank_you_error: 'There is a problem with your order...',
  title_coupon: 'Do you have a discount code?',
  total: 'Total',
  totals: 'Totals',
  use_new_card: 'Use a new credit card',
  username_email: 'E-mail *',
  vat: 'VAT',
  vat_extimated: 'Extimated VAT',
  view: 'View',
  welcome_back: 'Welcome to Kerakoll Brand&nbsp;Portal',
  wrong_login_data: 'Incorrect data. <br>Please try again or reset your password.',
  wrong_mail: 'This email is not registered in this website.',
  your_cart: 'Your cart',
  your_cart_empty: 'Your cart is currently empty',
};

labels.install = () => {
  Vue.prototype.$labels = labels;
};

Vue.use(labels);

export default labels;
