<template>
  <main class="main--no-hero main--custom-view">
    <PageCover
      ref="cover"
      :data="{ title: $labels.my_account }"
    />
    <Spacer
      v-if="user"
      y="m"
    >
      <Wrapper
        size="m"
      >
        <Form
          id="edit-account"
          :name="$labels.profile"
        />
        <!-- <Cta
          :data="{
            title: $labels.logout,
            url: {name: 'Account'},
            fn: logout
          }"
          theme="default"
        /> -->
      </Wrapper>
    </Spacer>
    <!-- <Wrapper
      v-else
      size="s"
      boxed
    >
      <Spacer
        :bottom="{ default: 's', m: 'm' }"
        top="s"
        class="t-center typo--xl"
      >
        {{ $labels.login }}
      </Spacer>
      <Login />
    </Wrapper> -->
    <div />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import Form from '@/components/form';
import PageCover from '@/components/blocks/page-cover';
// import Login from '@/components/wc/login';
// import Cta from '@/components/typo/cta';

export default {
  name: 'Account',
  components: {
    Form,
    // Login,
    PageCover,
    // Cta,
  },

  data() {
    return {
      // firstLoading: true,
    };
  },
  computed: {
    ...mapGetters(['user', 'customer', 'authLoading']),
  },
  watch: {
    // authLoading() {
    //   if (this.authLoading === false) {
    //     this.firstLoading = false;
    //   }
    // },
  },
  async mounted() {
    if (this.user) {
      // Ricarica dati se utente cambia al checkout
      await this.$store.dispatch('loadCustomerData');
    } else {
      this.$router.push({ name: 'Login' });
    }
  },
  methods: {
    // logout() {
    //   this.$store.dispatch('logout');
    // },
  },
};
</script>
