<template>
  <div class="media-section">
    <component
      :is="components[data.type]"
      v-if="data[data.type]"
      :data="
        Object.assign(data[data.type], {
          lazyload: data.lazyload,
          ratio: data.ratio,
          object_fit: data.object_fit ? 'cover' : 'contain',
        })
      "
    />
    <div v-if="text.title || text.description">
      <h3
        v-if="text.title"
        class="typo--3"
      >
        {{ text.title }}
      </h3>
      <Richtext
        v-if="text.description"
        :data="{ value: text.description }"
      />
    </div>
  </div>
</template>

<script>
import { Figure, Video, Gallery } from '@/components/media';
import Richtext from '@/components/typo/richtext';

export default {
  name: 'MediaSection',
  components: {
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      components: {
        default: Figure,
        image: Figure,
        video: Video,
        gallery: Gallery,
      },
    };
  },
  computed: {
    text() {
      return this.data.text;
    },
  },
};
</script>

<style lang="scss" scoped>
.media-section {
  display: grid;
  row-gap: var(--row-gap-m);
}
</style>
