var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"thumb--image"},[_c('div',{staticClass:"thumb--image__image__container"},[(_vm.data.gds_featured_image)?_c('Figure',{attrs:{"data":Object.assign({}, _vm.data.gds_featured_image, {ratio: 1 / 1})}}):_vm._e(),_c('div',{staticClass:"thumb--image__image__overlay"},[_c('div',{staticClass:"thumb--image__image__overlay__ctas"},[_c('Download',{attrs:{"data":{
            download: _vm.data.gds_featured_image.url.replace(/^.*[\\\/]/, ''),
            external: true,
            target: '_blank',
            url: _vm.data.gds_featured_image.url,
          }}}),_c('Share',{attrs:{"asset":_vm.data}})],1),_c('Icon',{attrs:{"size":"l","name":"zoom"}})],1)],1),_c('Title',{attrs:{"data":{
      value: _vm.data.taxonomy ? _vm.data.name : _vm.data.title.rendered,
      tag: 'h4',
      size: 'p--600',
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }