<template>
  <div
    :class="['favorite-toggle', theme ? `favorite-toggle--${theme}` : false]"
    @mouseenter="togglePopUp"
    @mouseleave="togglePopUp"
  >
    <Icon
      :theme="computedTheme"
      name="star"
      :fn="() => toggleFavourite(id)"
      :size="theme === 'thumb' ? 'm' : 'l'"
    />
    <transition name="fade">
      <Spacer
        v-show="popUpIsVisible"
        all="xs"
        class="pop-up trim--p"
      >
        {{ isFavorite(id) ? "Remove from" : "Add to" }} favorites
      </Spacer>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Icon from '@/components/ui/icon.vue';

export default {
  name: 'FavoriteToggle',
  components: {
    Icon,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      popUpIsVisible: false,
    };
  },
  computed: {
    ...mapGetters(['favourites', 'checkIsFavourite']),
    computedTheme() {
      if (this.theme === 'thumb') {
        if (this.isFavorite(this.id)) {
          return 'filled--light';
        } return 'fillable--light';
      }
      if (this.isFavorite(this.id)) {
        return 'filled';
      } return 'fillable';
    },
  },
  methods: {
    ...mapActions(['toggleFavourite']),
    isFavorite() {
      return this.checkIsFavourite(this.id);
    },
    togglePopUp() {
      this.popUpIsVisible = !this.popUpIsVisible;
    },
  },
};
</script>

<style lang="scss">
  .favorite-toggle {
    position: relative;
    z-index: 2;

    .pop-up {
      background-color: var(--white);
      bottom: -40px;
      box-shadow: 0 0 4px 2px var(--shadow-color);
      right: 0;
      position: absolute;
      width: max-content;

      @include mq(s) {
        bottom: 36px;
      }
    }

    &--thumb {
      .pop-up {
        right: initial;
        left: 0;
      }
    }
  }
</style>
