export default {
  data() {
    return {
      settings: {
        sendLabel: 'create_account',
      },
      groups: {
        register: {
          visible: true,
          fields: {
            email: {
              type: 'email',
              model: null,
              placeholder: 'email',
              label: 'email',
              rules: 'required|email',
            },
            password: {
              type: 'password',
              model: null,
              placeholder: 'password',
              label: 'password',
              rules: 'required|min:6',
              vid: 'password',
            },
            repeatPassword: {
              type: 'password',
              model: null,
              placeholder: 'repeat_password',
              label: 'repeat_password',
              id: 'repeat_password',
              rules: 'required|confirmed:password',
              vid: 'repeat_password',
            },
          },
        },
      },
    };
  },
  methods: {
    init() {

    },
    async callback() {
      const result = await this.$store.dispatch('createUser', {
        email: this.groups.register.fields.email.model,
        password: this.groups.register.fields.password.model,
      });
      if (result) {
        await this.$store.dispatch('loadCustomerData');
        this.$router.push({ name: 'Login' });
      }
    },
  },
};
