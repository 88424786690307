<template>
  <div class="image-bank">
    <h2
      v-if="isFiltered"
      class="results-title"
    >
      {{ $labels.results_title }}
    </h2>
    <div
      v-for="(category, catIndex) in filters
        ? filteredCategories
        : categories"
      :key="catIndex"
      class="image-bank__category"
    >
      <div>
        <h2 class="image-bank__category__header">
          {{ category.name }}
        </h2>
        <Grid
          v-if="category.items.length > 0"
          class="image-bank__category__images"
          :col="{ default: 12, xs: 6, m: 3 }"
          row-gap="l"
          column-gap="m"
        >
          <div
            v-for="(item, index) in category.items"
            :key="item.id"
            class="item"
            @click="setCurrent(item, category, index)"
          >
            <Thumb :data="item" />
          </div>
        </Grid>
      </div>
      <Cta
        :data="{
          icon: 'arrow',
          iconRotation: 90,
          title: $labels.download_all_cta,
          fn: () => downloadZip(category),
        }"
      />
    </div>
    <div v-if="isFiltered && filteredCategories.length === 0">
      {{ $labels.results_empty }}
    </div>
    <transition name="fade">
      <Slider
        v-if="current.item"
        ref="slider"
        :current="current"
        :current-asset="currentAsset"
        @set-current="setCurrent()"
      />
    </transition>
  </div>
</template>

<script>
import Slider from '@/components/archive/image-bank-slider';
import Cta from '@/components/typo/cta';
import Thumb from '@/components/thumb/thumb-image';
import { mapGetters } from 'vuex';

import { fetchZip } from '@/api/wp';

export default {
  components: {
    Cta,
    Thumb,
    Slider,
  },
  props: {
    filters: {
      type: [Object, Boolean],
      default: () => {},
    },
    categories: {
      type: Array,
      default: () => [],
    },
    filteredCategories: {
      type: Array,
      default: () => [],
    },
    isFiltered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      current: false,
      currentAsset: null,
      scrollY: 0,
    };
  },
  computed: {
    ...mapGetters(['customer']),
  },
  methods: {
    async downloadByCat(cat) {
      const ids = cat.items.map((item) => item.gds_featured_image.id);
      const user_id = this.customer.id;
      try {
        const { data: dataPost } = await fetchZip({
          ids,
          user_id,
          // nonce: this.$store.state.wp.session.nonce,
        });
          // console.log(dataPost);
          // return dataPost.collectionUrl;
        return dataPost.fileUrl;
      } catch (err) {
        return err;
      }
    },
    async downloadZip(category) {
      this.loadingDownload = true;
      const url = await this.downloadByCat(category);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Kerakoll Category ${category.name} Download.zip`,
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.loadingDownload = false;
      // this.$store.dispatch('onClearAssetsFromCollection');
    },
    setCurrentAsset(index) {
      this.currentAsset = this.current.category.items[index];
    },
    toggleScroll(value) {
      let scrollY = 0;
      const main = document.querySelector('.main');

      if (!value) {
        scrollY = window.scrollY;
        main.classList.add('main--locked');
        main.style.top = `-${scrollY}px`;
      } else {
        scrollY = main.style.top;
        main.classList.remove('main--locked');
        main.removeAttribute('style');
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      }
    },
    setCurrent(item, category, initialIndex) {
      if (item) {
        this.toggleScroll(false);
        this.current = {
          item,
          category,
          initialIndex,
        };
        this.currentAsset = item;

        this.$nextTick(() => {
          this.setCurrentAsset(initialIndex);
          this.$refs.slider.$refs.slider.slider.on(
            'change',
            this.setCurrentAsset,
          );
        });
      } else {
        this.toggleScroll(true);
        if (this.$refs.slider.$refs.slider?.slider) {
          this.$refs.slider.$refs.slider.slider.off(
            'change',
            this.setCurrentAsset,
          );
        }

        this.current = false;
        this.currentAsset = null;
      }
    },
  },
};
</script>

<style lang="scss">
  .image-bank {
    display: grid;
    row-gap: var(--row-gap-xxl);

    .results-title {
      text-align: center;
    }

    &__category {
      display: grid;
      row-gap: var(--row-gap-xl);

      > div {
        display: grid;
        row-gap: var(--row-gap-l);
      }

      &__header {
        align-items: center;
        display: grid;
        justify-items: center;
        width: 100%;

        > * {
          grid-area: 1 / 1;
        }
      }
      .cta {
        justify-self: center;
      }
    }
  }
</style>
