var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['share-block', _vm.avoid ? 'share-block-avoid' : false]},[_c('Cta',{attrs:{"data":{
      icon: _vm.popUpIsVisible ? 'plus' : 'arrow',
      iconRotation: 315,
      title: _vm.$labels.share_cta,
      fn: function () { return _vm.togglePopUp(); },
    }}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.popUpIsVisible)?_c('div',{class:[
        'share-pop-up',
        _vm.theme && _vm.theme !== '' ? ("share-pop-up--" + _vm.theme) : null ],on:{"mouseleave":_vm.hidePopUp}},[_c('Cta',{attrs:{"data":{ title: 'Copy link', fn: _vm.copyLink }}}),_c('a',{staticClass:"trim--p",attrs:{"href":("mailto:?subject=" + (_vm.asset.title ? _vm.asset.title.rendered : _vm.asset.post_title) + "&body=" + _vm.assetURL),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();return _vm.hidePopUp.apply(null, arguments)}}},[_vm._v("Email")])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }