export default {
  onInitAssetsToCollection({ commit }, assetsInCollection) {
    commit('INIT_ASSETS_IN_COLLECTION', assetsInCollection);
  },
  onAddToCollection({ commit }, assetsInCollection) {
    commit('ADD_ASSET_TO_COLLECTION', assetsInCollection);
  },
  onRemoveFromCollection({ commit }, id) {
    commit('REMOVE_ASSET_FROM_COLLECTION', id);
  },
  onClearAssetsFromCollection({ commit }) {
    commit('CLEAR_ASSETS_IN_COLLECTION');
  },
};
