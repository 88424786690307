<template>
  <div
    v-if="favourites && favourites.length > 0"
    class="favorites"
  >
    <Title
      v-if="data.title && data.title !== ''"
      :data="{
        value: data.title,
        tag: data.tag_title,
        justify: data.title_justification
      }"
    />
    <Archive
      :favourites="favouritesArray"
      :data="{
        archive_type: 'archive',
        archive: 'page',
        meta: 'favorite',
        pages: favouritesArray,
        layout: [
          {
            media_query: 'default',
            layout_size: '12',
            layout_type: 'grid',
          },
          {
            media_query: 's',
            layout_size: '4',
            layout_type: 'grid',
          },
        ],
      }"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Archive from '@/components/blocks/archive';
import Title from '@/components/typo/title';

export default {
  name: 'Favorites',
  components: {
    Archive,
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['favourites', 'favouritesArray']),
  },
};
</script>

<style lang="scss">
.favorites {
  display: grid;
  row-gap: var(--row-gap-xl);

  .title {
    justify-self: center;
  }
}
</style>
