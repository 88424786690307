import axios from 'axios';
import ajax, { ajaxCustom } from '@/api/axios';

// eslint-disable-next-line no-undef
let { url } = __VUE_WORDPRESS__.routing;
url = url.replace(':8888', ':3000');

export const fetchSingle = ({ type, params = {} }) => {
  if (type === 'product') {
    return ajax.get(`gds/v1/wc/products/${params.slug}`);
  }
  return ajax.get(`wp/v2/${type}/`, { params });
};

export const fetchItems = ({ type, params = {} }) => {
  if (type === 'product') {
    return ajax.get('gds/v1/wc/products/', { params });
  }
  if (type === 'search') {
    const ajaxSearch = ajaxCustom({ table: 'search' });
    return ajaxSearch.get('', { params });
  }
  return ajax.get(`wp/v2/${type}/`, { params });
};

export const fetchSearch = ({ params, cancelToken }) => ajax.get('/wp/v2/search', { params, cancelToken });

export const sendAjax = (request) => {
  const formData = new FormData();
  if (!request.action) formData.append('action', 'do_ajax');

  Object.keys(request).forEach((key) => {
    if (key === 'params') {
      formData.append('params', JSON.stringify(request.params));
    } else {
      formData.append(key, request[key]);
    }
  });

  return axios.post(`${url}/wp-admin/admin-ajax.php`, formData);
};

export const fetchZip = ({ ids, user_id }) => {
  const ajaxZip = ajaxCustom({ table: 'zip' });
  return ajaxZip.post('', { ids, user_id });
};

export const toggleFavourite = ({ id }) => {
  const ajaxFavourite = ajaxCustom({ table: 'favourites' });
  return ajaxFavourite.post('', { id });
};
