<template>
  <div class="quote">
    <h3 v-if="data.label && data.label !== ''">
      {{ data.label }}
    </h3>
    <h2 v-if="data.content && data.content !== ''">
      {{ data.content }}
    </h2>
    <Cta
      v-if="data.cta"
      :data="{
        icon: 'arrow',
        title: data.cta.title,
        url: data.cta.url,
      }"
    />
  </div>
</template>

<script>
import Cta from '@/components/typo/cta';

export default {
  components: {
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .quote {
    display: grid;
    justify-items: center;
    text-align: center;

    > * {
      max-width: 900px;
    }

    .cta {
      margin-top: var(--spacer-m);
    }
  }
</style>
