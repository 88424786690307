<template>
  <div :class="['share-block', avoid ? 'share-block-avoid' : false]">
    <Cta
      :data="{
        icon: popUpIsVisible ? 'plus' : 'arrow',
        iconRotation: 315,
        title: $labels.share_cta,
        fn: () => togglePopUp(),
      }"
    />
    <transition name="fade">
      <div
        v-if="popUpIsVisible"
        :class="[
          'share-pop-up',
          theme && theme !== '' ? `share-pop-up--${theme}` : null,
        ]"
        @mouseleave="hidePopUp"
      >
        <Cta :data="{ title: 'Copy link', fn: copyLink }" />
        <a
          class="trim--p"
          :href="`mailto:?subject=${asset.title ? asset.title.rendered : asset.post_title}&body=${assetURL}`"
          target="_blank"
          @click.stop="hidePopUp"
        >Email</a>
      </div>
    </transition>
  </div>
</template>

<script>
import Cta from '@/components/typo/cta';

// eslint-disable-next-line no-undef
// const url = `${__VUE_WORDPRESS__.routing.url}/`;
// const base = url.replace(process.env.VUE_APP_BASE_URL, '');

export default {
  components: {
    Cta,
  },
  props: {
    asset: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      popUpIsVisible: false,
      assetURL: null,
      avoid: false,
    };
  },
  watch: {
    asset: {
      deep: true,
      handler(val) {
        if (val) {
          if (val.type === 'collection' && !this.asset.avoid) {
            this.avoid = false;
            this.assetURL = `${this.asset.url}`;
          } else if (val.type === 'collection' && this.asset.avoid) {
            this.avoid = true;
          }
        }
      },
    },
  },
  created() {
    if (this.asset === 'collection' && this.asset.url === '') this.avoid = true;
    this.assetURL = this.asset.type === 'collection' ? `${this.asset.url}` : this.asset.type === 'image' ? `${this.asset.gds_featured_image.url}` : `${this.asset.acf.asset.url}`;
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.assetURL);
      this.togglePopUp(false);
    },
    togglePopUp() {
      this.popUpIsVisible = !this.popUpIsVisible;
    },
    hidePopUp() {
      this.popUpIsVisible = false;
    },
  },
};
</script>

<style lang="scss">
  .share-block {
    position: relative;

    &-avoid {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
  .share-pop-up {
    background-color: var(--white);
    bottom: var(--spacer-m);
    box-shadow: 0 0 4px 2px var(--shadow-color);
    display: grid;
    min-width: max-content;
    position: absolute;
    right: calc(-1 * var(--spacer-xs));

    > * {
      padding: var(--spacer-xs);
      transition: opacity 0.2s;
      @extend %typo--5;

      @media (hover: hover) {
        &:hover {
          opacity: 0.6;
        }
      }

      &:not(:last-child) {
        border-bottom: var(--line) var(--grey-l) solid;
      }
    }

    &--thumb {
      left: calc(-1 * var(--spacer-xs));
      right: initial;
    }
  }
</style>
