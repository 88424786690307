export default {
  ADD_ITEM(state, { type, item, lang }) {
    if (item && type && !state[type][lang][item.slug]) {
      state[type][lang][item.slug] = item;
    }
  },
  ADD_REQUEST(state, { type, request }) {
    state[type].requests.push(request);
  },
  ADD_LANGUAGES(state, value) {
    value.forEach((lang) => {
      state.pages[lang] = {};

      Object.keys(state.postTypes).forEach((key) => {
        const restBase = state.postTypes[key].rest_base;
        state[restBase][lang] = {};
      });
    });
  },
  DO_LOGIN(state) {
    state.isLoggedIn = true;
  },
};
