<template>
  <component
    :is="mergeProps.tag"
    :class="[
      'title',
      mergeProps.justify === 'center' ? 't-center' : null,
      mergeProps.size ? `typo--${mergeProps.size}` : null,
    ]"
    v-html="mergeProps.value"
  />
</template>

<script>
export default {
  name: 'Title',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    mergeProps() {
      const defaultProps = {
        value: '',
        tag: 'h2',
        size: null,
        justify: 'left',
      };

      Object.keys(this.data).forEach(
        (key) => this.data[key] === undefined && delete this.data[key],
      );
      return { ...defaultProps, ...this.data };
    },
  },
};
</script>

<style lang="scss">
.title {
  max-width: 900px;
}
</style>
