<template>
  <Spacer
    class="typo--3"
    bottom="xs"
  >
    {{ $labels[data.value] }}
  </Spacer>
</template>

<script>

export default {
  name: 'Title',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
  },
};
</script>

<style>

</style>
