<template>
  <Flex class="login-page">
    <div>
      <Spacer
        class="typo--2 t-center"
        x="m"
        :top="{
          default: 'l',
          s: 'xl'
        }"
        bottom="m"
      >
        {{ $labels.create_account }}
      </Spacer>
      <Wrapper
        size="m"
        boxed
      >
        <Form
          id="register"
          class="form--single form-theme--default"
          theme="single"
        />
      </Wrapper>
    </div>
    <div
      class="login-background"
      :style="backgroundImage"
    />
  </Flex>
  <!-- <main class="main--no-hero main--custom-view">
    <Wrapper
      size="s"
      boxed
    >
      <div>
        <Spacer
          bottom="l"
          top="s"
          class="t-center typo--xl"
        >
          {{ $labels.create_accunt }}
        </Spacer>
        <Form
          id="register"
          theme="single"
        />
      </div>
    </Wrapper>
  </main> -->
</template>

<script>
import { mapGetters } from 'vuex';

import Form from '@/components/form';

export default {
  name: 'Register',
  components: {
    Form,
  },
  computed: {
    backgroundImage() {
      if (this.$store.state.wp.options && this.$store.state.wp.options.background_image) {
        return {
          'background-image': `url(${this.$store.state.wp.options.background_image})`,
        };
      }
      return null;
    },
    ...mapGetters(['user']),
  },
  created() {
    if (this.user) {
      this.$router.push({ name: 'Account' });
    }
  },
};
</script>
