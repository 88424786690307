<template>
  <div class="thumb--image">
    <div class="thumb--image__image__container">
      <Figure
        v-if="data.gds_featured_image"
        :data="{ ...data.gds_featured_image, ratio: 1 / 1 }"
      />
      <div class="thumb--image__image__overlay">
        <div class="thumb--image__image__overlay__ctas">
          <Download
            :data="{
              download: data.gds_featured_image.url.replace(/^.*[\\\/]/, ''),
              external: true,
              target: '_blank',
              url: data.gds_featured_image.url,
            }"
          />
          <Share :asset="data" />
        </div>
        <Icon
          size="l"
          name="zoom"
        />
      </div>
    </div>
    <Title
      :data="{
        value: data.taxonomy ? data.name : data.title.rendered,
        tag: 'h4',
        size: 'p--600',
      }"
    />
  </div>
</template>

<script>
import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';
import Download from '@/components/asset-bar/asset-bar-download';
import Share from '@/components/asset-bar/asset-bar-share';
import Icon from '@/components/ui/icon.vue';

export default {
  name: 'ImageThumb',
  components: {
    Figure,
    Title,
    Download,
    Share,
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .thumb {
    &--image {
      cursor: pointer;
      display: grid;
      row-gap: var(--row-gap-s);

      @media (hover: hover) {
        &:hover {
          .thumb--image__image__overlay {
            visibility: initial;
            opacity: 1;
          }
        }
      }
      &__image {
        &__container {
          display: grid;

          > * {
            grid-area: 1 / 1;
          }
        }

        &__overlay {
          align-items: end;
          background-color: var(--overlay-white-09);
          display: grid;
          visibility: hidden;
          opacity: 0;
          padding: var(--spacer-xs);
          transition: visibility 0.4s ease, opacity 0.4s ease;
          z-index: 1;

          > * {
            grid-area: 1 / 1;
          }

          &__ctas {
            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;
          }

          .icon-zoom {
            justify-self: center;
            align-self: center;
            pointer-events: none;
          }
        }
      }
    }
  }
</style>
