var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"thumb__container"},[(_vm.favorite)?_c('FavoriteToggle',{attrs:{"id":_vm.data.id,"theme":"thumb"}}):_vm._e(),_c('router-link',{staticClass:"thumb",attrs:{"to":_vm.data.link}},[(_vm.data.acf.page_preview && _vm.data.acf.page_preview.image)?_c('Figure',{attrs:{"data":Object.assign({}, _vm.data.acf.page_preview.image, {ratio: 3 / 4})}}):_c('div',{staticClass:"thumb__placeholder ratio--43"}),_c('div',[_c('Title',{attrs:{"data":{
          value: _vm.data.taxonomy ? _vm.data.name : _vm.data.title.rendered,
          tag: 'h3',
        }}}),(
          _vm.data.acf.page_preview.excerpt &&
            _vm.data.acf.page_preview.excerpt !== ''
        )?_c('p',[_vm._v(" "+_vm._s(_vm.data.acf.page_preview.excerpt)+" ")]):_vm._e(),_c('Cta',{attrs:{"data":{
          icon: 'arrow',
          title: _vm.$labels.cta,
          url: _vm.data.link,
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }