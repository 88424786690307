<template>
  <Spacer>
    <Title
      v-if="data.title"
      :data="{ value: data.title }"
    />
    <Filters
      v-if="data.filters && filters"
      :data="filters"
      :selected-filters-fn="selectedFiltersFn"
      :template="data.template"
    />
    <template v-if="data.template !== 'image'">
      <Grid
        v-if="items.length > 0"
        :col="gridCol"
        :template="gridTemplate"
        :row-gap="data.template === 'asset' ? '0' : 'xl'"
        column-gap="m"
      >
        <!-- v-show="!favourites || (favourites && favourites.includes(item.id))" -->
        <div
          v-for="item in data.filters ? itemsFiltered : items"
          :key="item.id"
          class="item"
        >
          <component
            :is="thumb(data.meta || item.type)"
            :data="item"
            :favorite="data.meta === 'favorite' ? true : false"
          />
        </div>
        <!-- <div
          v-if="data.template === 'asset'"
          v-show="data.filters ? itemsFiltered.length > 0 : items.length > 0"
        >
          <Spacer
            class="asset-bar asset-bar--no-border"
            y="m"
          >
            <h5 class="typo--p--600">
              {{ allAssets.post_title }}
            </h5>
            <div class="asset-bar__actions">
              <AssetBarCollection
                :data="allAssetsCollection()"
                :added="allAdded"
              />
            </div>
          </Spacer>
        </div> -->
      </Grid>
    </template>
    <template v-else-if="data.template === 'image'">
      <ImageBank
        :filters="data.filters"
        :categories="categories"
        :is-filtered="isFiltered"
        :filtered-categories="categoryFiltered"
      />
    </template>
    <div v-else>
      <Skeleton
        :items="skeletonItems"
        :cols="gridCol"
        :thumb-component="type"
      />
    </div>

    <div
      v-show="items && data.infinite_scroll"
      ref="infinite"
      class="infinite"
    />

    <Cta
      v-if="data.cta"
      :data="data.cta"
    />
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';
import archive from '@/mixins/archive';

import Thumb from '@/components/thumb';
import ThumbProduct from '@/components/thumb/product';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Skeleton from '@/components/ui/skeleton';
import Filters from '@/components/archive/filters';

import Gallery from '@/components/media/gallery';
import AssetBar from '@/components/asset-bar';
import AssetBarCollection from '@/components/asset-bar/asset-bar-collection';
import ImageBank from '@/components/archive/image-bank.vue';

const thumbs = new Map();
thumbs.set('default', Thumb);
// thumbs.set('product', ThumbProduct);
// thumbs.set('simple', ThumbProduct);
// thumbs.set('variable', ThumbProduct);
// thumbs.set('subscription', ThumbProduct);
thumbs.set('variable-subscription', ThumbProduct);
thumbs.set('favorite', Thumb);
thumbs.set('asset', AssetBar);

export default {
  name: 'Archive',
  components: {
    Title,
    Cta,
    Skeleton,
    Filters,
    AssetBar,
    AssetBarCollection,
    Gallery,
    ImageBank,
  },
  mixins: [archive],
  props: {
    data: {
      type: Object,
      required: true,
    },
    favourites: {
      type: [Array, Boolean],
      default: false,
    },
  },
  data() {
    return {
      allAdded: false,
      current: false,
      currentAsset: null,
    };
  },
  computed: {
    ...mapGetters(['assetsInCollection']),
    gridCol() {
      let cols = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_size } = layout;
          cols = {
            ...cols,
            [media_query]:
                layout_size === 'auto'
                  ? (12 / this.items.length).toString()
                  : layout_size,
          };
        });
      } else {
        cols = {
          xs: 12,
          m: 4,
        };
      }
      return cols;
    },
    gridTemplate() {
      let template = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_type } = layout;
          template = {
            ...template,
            [media_query]: layout_type,
          };
        });
      } else {
        template = 'grid';
      }
      return template;
    },
    allAssets() {
      const items = this.data.filters ? this.itemsFiltered : this.items;
      const post_title = `${items.length} ${
        items.length > 1 ? 'items' : 'item'
      }`;
      const assets = items.map((item) => item.acf.asset);
      return {
        post_title,
        assets,
      };
    },
  },
  watch: {
    assetsInCollection(val) {
      if (val.length === 0) {
        this.allAdded = false;
      }
    },
  },
  methods: {
    thumb(key) {
      return key && thumbs.get(key) ? thumbs.get(key) : thumbs.get('default');
    },
    allAssetsCollection() {
      return {
        label: !this.allAdded
          ? this.$labels.collection_cta_all
          : this.$labels.collection_cta_remove,
        fn: this.toggleCollection,
      };
    },
    toggleCollection() {
      this.allAdded = !this.allAdded;

      this.allAssets.assets.forEach((asset) => {
        if (this.allAdded) {
          const {
            id, title, filesize, url,
          } = asset;

          const newAssetInCollection = {
            id,
            title,
            filesize,
            url,
          };

          this.$store.dispatch('onAddToCollection', newAssetInCollection);
        } else {
          this.$store.dispatch('onRemoveFromCollection', asset.id);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .infinite {
    visibility: hidden;
  }
</style>
