<template>
  <Spacer
    all="xs"
    class="account-pop-up"
    @mouseleave.native="hidePopUp"
  >
    <div>
      <h4 class="typo--4">
        Username
      </h4>
      <router-link :to="{ name: 'Account' }">
        {{ customer && customer.email || $labels.account_not_logged }}
      </router-link>
    </div>
    <Cta
      :data="{ title: 'Logout', fn: logout, icon: 'logout', iconFirst: true }"
    />
  </Spacer>
</template>

<script>
import Cta from '@/components/typo/cta';
import { mapGetters } from 'vuex';

export default {
  name: 'AccountPopUp',
  components: {
    Cta,
  },
  emits: ['hide-pop-up'],
  computed: {
    ...mapGetters(['customer', 'user']),
  },
  async mounted() {
    if (this.user) {
      await this.$store.dispatch('loadCustomerData');
    }
  },

  methods: {
    async logout() {
      this.$store.dispatch('logout');
    },
    hidePopUp() {
      this.$emit('hide-pop-up');
    },
  },
};
</script>

<style lang="scss">
  .account-pop-up {
    background-color: var(--white);
    bottom: var(--spacer-m);
    box-shadow: 0 0 4px 2px var(--shadow-color);
    display: grid;
    left: calc(var(--spacer-m) - var(--spacer-xs));
    gap: var(--row-gap-s);
    margin-bottom: 36px;
    position: absolute;
    width: max-content;
    z-index: 1;
  }
</style>
