<template>
  <div class="text-grid">
    <div
      v-for="(column, indexA) in data.columns"
      :key="indexA + 10"
      class="text-grid__column"
    >
      <RichText
        v-for="(text, indexB) in column.text_grid"
        :key="indexB + 100"
        :data="{
          value: text.text,
        }"
      />
    </div>
    <Grid
      v-for="n in rowsNumber"
      :key="n"
      :col="gridCol"
      class="desktop"
    >
      <RichText
        v-for="(item, indexC) in data.columns"
        :key="indexC + 1000"
        :data="{
          value:
            item.text_grid[n - 1] !== undefined
              ? item.text_grid[n - 1].text
              : '',
        }"
      />
    </Grid>
  </div>
</template>

<script>
import RichText from '@/components/typo/richtext';

export default {
  name: 'TextGrid',
  components: {
    RichText,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    gridCol() {
      let cols = null;
      const elementsLength = this.data.columns?.length;
      let defaultColsNumber = 4;

      if (elementsLength) {
        if (elementsLength === 1) {
          defaultColsNumber = 1;
        } else if (elementsLength < 5) {
          defaultColsNumber = 12 / elementsLength;
        } else {
          defaultColsNumber = 4;
        }
      }

      cols = {
        xs: 12,
        s: 6,
        m: defaultColsNumber.toString(),
      };

      return cols;
    },
    rowsNumber() {
      const { columns } = this.data;
      const rowsLength = [];

      columns.forEach((e) => {
        rowsLength.push(e.text_grid.length);
      });

      return Math.max(...rowsLength);
    },
  },
};
</script>

<style lang="scss">
  .text-grid {
    display: grid;
    row-gap: var(--row-gap-m);

    &__column {
      display: grid;
      row-gap: var(--row-gap-m);

      @include mq(s) {
        display: none;
      }
    }

    .desktop {
      display: none;

      @include mq(s) {
        display: grid;
      }
    }
  }
</style>
