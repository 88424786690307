<template>
  <div>
    <Spacer
      :bottom="'page--m'"
      use-margin
    />
    <Wrapper
      size="m"
      boxed
      class="spacer--section-standard"
    >
      <SearchInput />
      <Spacer
        v-visible="resultsNumber"
        top="s"
        use-margin
      >
        {{ `${resultsNumber} result${resultsNumber > 1 ? "s" : ""}` }}
      </Spacer>
    </Wrapper>
    <Wrapper
      size="l"
      boxed
      class="spacer--section-standard"
    >
      <div
        v-if="results"
        class="search-results"
      >
        <div
          v-for="(cat, key) in results"
          :key="key"
          class="search-results__section"
        >
          <h2 class="search-results__section__title">
            {{ `${key}s` }}
          </h2>
          <Grid
            column-gap="m"
            row-gap="xl"
            :col="{ default: '12', s: '4' }"
          >
            <component
              :is="item.type === 'asset' ? 'AssetThumb' : 'Thumb'"
              v-for="(item, index) in cat"
              :key="index"
              :data="item"
            />
          </Grid>
        </div>
      </div>
      <div v-else-if="!results && !found">
        {{ emptyState }}
      </div>
      <div v-else>
        {{ placeholder }}
      </div>
    </Wrapper>
    <Spacer
      top="page--l"
      use-margin
    />
  </div>
</template>

<script>
import groupBy from 'lodash.groupby';
import Thumb from '@/components/thumb';
import AssetThumb from '@/components/asset-bar';
import SearchInput from '@/components/blocks/search.vue';

export default {
  name: 'Search',
  components: {
    AssetThumb,
    Thumb,
    SearchInput,
  },
  data() {
    return {
      results: [],
      found: true,
      placeholder: 'Searching...',
      emptyState: 'Found nothing',
    };
  },
  computed: {
    resultsNumber() {
      return Object.values(this.results).flat(1).length;
    },
  },
  watch: {
    $route(to, old) {
      if (to.query.s && to.query.s !== old.query.s) {
        this.setRequest(to.query.s);
      }
    },
  },
  mounted() {
    if (this.$route.query.s) this.setRequest(this.$route.query.s);
  },
  methods: {
    setRequest(params) {
      this.$store.dispatch('getSearch', params).then(({ data }) => {
        if (data && data.length > 0) {
          this.found = true;
          this.results = groupBy(data, (el) => el.type);
        } else {
          this.found = false;
          this.results = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
  .search-results {
    display: grid;
    row-gap: var(--row-gap-xxl);
    &__section {
      display: grid;
      row-gap: var(--row-gap-l);

      &__title {
        text-transform: capitalize;
      }
    }
  }
</style>
