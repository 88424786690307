<template>
  <main v-if="!loading">
    <Media
      ref="product-gallery"
      class="product__gallery"
      :data="{
        type: 'gallery',
        gallery: productGallery,
        ratio: true,
        lazyload: false
      }"
    />
    <Title :data="{ value: post.name }" />
    <Richtext
      :data="{
        value: product.description ? product.description : post.description
      }"
    />
    <div v-if="hasVariations">
      <div
        v-for="(attribute, index) in attributes"
        :key="index"
      >
        <Flex>
          <Select
            v-model="attribute.model"
            class="product__variation-selector"
            :data="getAttribute(attribute)"
            :model.sync="attribute.model"
          >
            <label
              slot="label"
              for="terms"
            >{{ attribute.name }}</label>
          </Select>
          <Icon
            v-if="attribute.model"
            name="close"
            theme="small"
            :fn="() => resetAttribute(attribute)"
          />
        </Flex>
      </div>
    </div>
    <Spacer
      v-if="hasMetaInfos"
      y="m"
    >
      <ValidationObserver
        ref="observer"
        v-slot="{ handleSubmit }"
      >
        <form
          :id="'product-'+product.id"
          tag="form"
          novalidate
          class="form"
          @submit.prevent="handleSubmit(callback)"
        >
          <div
            v-for="(metaInfo, index) in product.acf.meta_infos"
            :key="index"
          >
            <component
              :is="metaInfo.type"
              :data="{
                id: metaInfo.slug,
                type: 'text',
                model: 'null',
              }"
              :model.sync="metaInfos[metaInfo.slug]"
            >
              <label
                slot="label"
                :for="metaInfo.slug"
              >{{ metaInfo.title }}
              </label>
            </component>
          </div>
        </form>
      </ValidationObserver>
    </Spacer>
    <Price
      v-if="product"
      :product="product"
    />
    <Availability :product="product" />
    <Number
      :data="{
        id: `number`,
        fn: numberChanged,
        itemKey: product.id,
        readonly: cartLoading || isOos,
      }"
      :value.sync="numberToBeAdded"
    />
    <Spacer y="m">
      <Cta
        :data="{
          title: $labels.add_to_cart,
          url: false,
          fn: addToCart,
        }"
        :disabled="!addableToCart"
        theme="button"
      />
    </Spacer>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import isEqual from 'lodash.isempty';
import { addableToCart, isOos } from '@/assets/js/utils-wc';

import data from '@/mixins/data';

import Media from '@/components/blocks/media';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Richtext from '@/components/typo/richtext.vue';
import Icon from '@/components/ui/icon.vue';
import Price from '@/components/wc/price.vue';
import Availability from '@/components/wc/availability.vue';

import Number from '@/components/form/fields/number';
import Select from '@/components/form/fields/select';
import Textarea from '@/components/form/fields/textarea';
import Input from '@/components/form/fields/input';

// N.B. testato fino a 2 attributi

export default {
  name: 'SingleProduct',
  components: {
    Media,
    Title,
    Cta,
    Richtext,
    Icon,
    Price,
    Availability,
    Number,
    Select,
    Textarea,
    Input,
    ValidationObserver,
  },
  mixins: [data],
  data() {
    return {
      loading: true,
      product: null,
      variations: [],
      attributes: [],
      selectedVariation: null,
      selectedImage: null,
      numberToBeAdded: 1,
      metaInfos: {},
    };
  },
  computed: {
    ...mapGetters(['cartLoading']),
    hasVariations() {
      return this.post.type.startsWith('variable');
    },
    hasMetaInfos() {
      return this.product.acf && this.product.acf.meta_infos && this.product.acf.meta_infos[0];
    },
    isOos() {
      return isOos(this.product);
    },
    addableToCart() {
      if (this.hasVariations && !this.selectedVariation) {
        return false;
      }
      if (!addableToCart(this.product) || isOos(this.product)) {
        return false;
      }
      return true;
    },
    productGallery() {
      this.variations.forEach((variation) => {
        this.post.gallery.push(variation.gds_featured_image);
      });
      return [this.post.gds_featured_image].concat(this.post.gallery ? this.post.gallery : []);
    },
  },
  watch: {
    $route() {
      this.setSelectedVariation();
    },
    selectedImage() {
      if (this.$refs['product-gallery'] && this.$refs['product-gallery'].$children[0]) {
        setTimeout(() => {
          this.$refs['product-gallery'].$children[0].gallery.selectCell(`[data-item-id="${this.selectedImage}"]`);
        }, 100); // Await flickity loading
      }
    },
    loading(val) {
      if (!val) {
        this.$nextTick(() => {
          if (this.$refs['product-gallery'] && this.$refs['product-gallery'].$children[0]) {
            this.$refs['product-gallery'].$children[0].gallery.selectCell(`[data-item-id="${this.selectedImage}"]`);
          }
        });
      }
    },
  },
  async created() {
    this.product = this.post;
    if (this.hasVariations) {
      this.$store.commit('SET_CART_LOADING', true);
      const variations = await this.$store.dispatch('getVariations', { slug: this.post.slug });

      this.variations = variations;
      const attributesKeys = Object.keys(this.post.attributes_slugs);
      attributesKeys.forEach((attribute_slug, index) => {
        const newAttribute = {
          type: 'select',
          model: null,
          id: attribute_slug,
          name: this.post.attributes[index].name,
          hidden: false,
          placeholder: null,
          options: {},
          originalOptions: {},
          fn: this.updateSelectedVariation,
        };
        this.post.attributes[index].options.forEach((option, i) => {
          // Check if exists any variation with this attribute
          if (this.variations.findIndex((variation) => variation.attributes_slugs[attribute_slug] === this.post.attributes_slugs[attribute_slug][i]) > -1) {
            newAttribute.options[this.post.attributes_slugs[attribute_slug][i]] = option;
            newAttribute.originalOptions[this.post.attributes_slugs[attribute_slug][i]] = option;
          }
        });
        this.attributes.push(newAttribute);
      });
      this.setSelectedVariation();
      this.$store.commit('SET_CART_LOADING', false);
      this.loading = false;
    } else {
      this.loading = false;
    }
  },
  async mounted() {
    // Marketing
    this.$bus.$emit('viewItem', this.product);
  },
  methods: {
    callback() {
      this.addToCart();
    },
    addToCart() {
      let cart_item_data = {};
      if (this.hasMetaInfos) {
        const custom_item_data = {};
        this.product.acf.meta_infos.forEach((product) => {
          custom_item_data[product.slug] = this.metaInfos[product.slug];
        });
        cart_item_data = {
          custom_item_data,
        };
      }
      this.$store.dispatch('addToCart', Object.assign(this.post, {
        id: String(this.post.id),
        variation_id: this.selectedVariation,
        variation: this.variations.find((variation) => this.selectedVariation === variation.id),
        quantity: this.numberToBeAdded,
        quantityDelta: this.numberToBeAdded,
        cart_item_data,
      }));
    },
    numberChanged(val) {
      if (val[0]) {
        /* eslint-disable */
        this.numberToBeAdded = val[1];
      } else {
        this.numberToBeAdded = val.target.value
      }
      if (this.numberToBeAdded < 1) {
        this.numberToBeAdded = 1
      }
      if (this.product.stock_quantity && this.numberToBeAdded > this.product.stock_quantity) {
        this.numberToBeAdded = this.product.stock_quantity
      }
    },
    getAttribute(attribute) {

      const select = document.querySelector(`select#${attribute.id}`)
      const currentFixedOption = document.querySelector('.field.focus select')
      if (currentFixedOption && select && select.closest('.field') && !select.closest('.field').classList.contains('focus')) {

        // Re set original value
        const originalOptionsKeys = Object.keys(attribute.originalOptions)
        originalOptionsKeys.forEach(originalOptionsKey => {
          if (!attribute.options[originalOptionsKey]) {
            attribute.options[originalOptionsKey] = attribute.originalOptions[originalOptionsKey]
          }
        })

        const currentFixedKey = currentFixedOption.getAttribute('id')
        const currentFixedValue = currentFixedOption.value
        const optionsKeys = Object.keys(attribute.options)
        optionsKeys.forEach(optionsKey => {
          const existingVariation = this.variations.filter(variation => {
            return variation.attributes_slugs[currentFixedKey] === currentFixedValue && variation.attributes_slugs[attribute.id] === optionsKey
          })
          if (!existingVariation[0]) {
            delete attribute.options[optionsKey] 
          }
        })
      }

      return attribute
    },
    resetAttribute(attribute) {
      attribute.model = null
      this.resetSelectedVariation()
    },
    resetSelectedVariation() {
      this.selectedVariation = null
      this.selectedImage = null
    },
    setSelectedVariation() {
      const queryVariations = Object.keys(this.$route.query);
      if (queryVariations.length === 0) {
        this.attributes.forEach((attribute) => {
          attribute.model = null;
        });
        this.resetSelectedVariation()
      } else {
        // Re-attribute model if reloading page
        queryVariations.forEach((queryVariation) => {
          this.attributes.forEach((attribute) => {
            if (attribute.id === queryVariation.replace('attribute_', '')) {
              attribute.model = this.$route.query[queryVariation];
            }
          });
        });

        const selectedVariation = this.variations.find((variation) => {
          let isRightVariation = false;
          let correctAttributes = 0;
          this.attributes.forEach((attribute) => {
            if (attribute.model === variation.attributes_slugs[attribute.id]) {
              correctAttributes += 1;
            }
          });
          if (correctAttributes === variation.attributes.length) {
            isRightVariation = true;
          }
          return isRightVariation;
        });
        if (selectedVariation) {
          this.selectedVariation = selectedVariation.id;
          this.selectedImage = selectedVariation.gds_featured_image ? selectedVariation.gds_featured_image.id : null;
          this.product = selectedVariation;
        } else {
          this.resetSelectedVariation()
        }
      }
      // Marketing
      this.$bus.$emit('selectItem', this.product);
    },
    updateSelectedVariation() {
      let attributesSelected = 0;
      const query = {};
      this.attributes.forEach((attribute) => {
        if (attribute.model) {
          query[`attribute_${attribute.id}`] = attribute.model;
          attributesSelected += 1;
        }
      });
      if (attributesSelected === this.attributes.length && !isEqual(query, this.$route.query)) {
        this.$router.push({ query });
      }
    },
  },
};
</script>

<style lang="scss">

.product__variation-selector {
  min-width: 200px;
}

.product__gallery {
  &.gallery {
    .item {
      width: 100vw;
    }
  }
}

</style>
