<template>
  <Wrapper
    size="l"
    :class="[
      'page-menu__container',
      mobileHeaderIsVisible && !coverIsVisible
        ? 'page-menu__container--shifted'
        : false,
    ]"
  >
    <div class="page-menu__shadow" />
    <nav
      ref="pageMenu"
      :class="['page-menu', isOverflown ? 'page-menu--overflown' : false]"
    >
      <router-link
        v-for="section in sections"
        :id="`anchor--${section.menu_name.replace(/\s/g, '-').toLowerCase()}`"
        :key="section.menu_name"
        ref="anchors"
        class="typo--p--600"
        :to="`#${section.menu_name.replace(/\s/g, '-').toLowerCase()}`"
        @click.native="scrollToAnchor"
      >
        {{ section.menu_name }}
      </router-link>
    </nav>
  </Wrapper>
</template>

<script>
import gsap, { ScrollToPlugin } from 'gsap/all';
import { mapGetters } from 'vuex';

gsap.registerPlugin(ScrollToPlugin);

export default {
  props: {
    sections: {
      type: [Array, Boolean],
      default: false,
    },
    observedSection: {
      type: String,
      default: '',
    },
    setSignal: {
      type: Function,
      default: () => {},
    },
  },
  emits: ['reset-observer'],
  data() {
    return {
      isOverflown: false,
      coverIsVisible: true,
    };
  },
  computed: {
    ...mapGetters(['headerHeight', 'mobileHeaderIsVisible']),
  },
  watch: {
    observedSection(val) {
      if (val === '') {
        this.$router.replace({
          hash: null,
        });
      } else if (this.$route.hash !== `#${val}`) {
        this.$router.replace({
          hash: `#${val}`,
        });
      }
    },
    $route(route) {
      const id = route.hash.substring(1);

      if (route.hash !== '') {
        const anchor = this.$refs.anchors.find(
          (e) => e.$el.id === `anchor--${id}`,
        );
        const menu = this.$refs.pageMenu;
        const padding = window
          .getComputedStyle(menu)
          .getPropertyValue('padding-left');

        menu.scroll({
          top: 0,
          left: anchor.$el.offsetLeft - parseInt(padding, 10),
          behavior: 'smooth',
        });
      }
    },
  },
  mounted() {
    this.setMenuOffset();
    this.setScrollbarWidth();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.setMenuOffset();
      this.setScrollbarWidth();

      const width = this.$refs.pageMenu.scrollWidth;
      const visibleWidth = this.$refs.pageMenu.clientWidth;
      if (visibleWidth - width < 0) {
        this.isOverflown = true;
      } else {
        this.isOverflown = false;
      }
    },
    setMenuOffset() {
      const menu = this.$refs.pageMenu;
      menu.style.marginTop = `-${menu.offsetHeight}px`;
    },
    handleScroll() {
      const delta = this.$refs.pageMenu.getBoundingClientRect().top;
      const favoriteToggle = document.querySelector('.favorite-toggle');

      if (this.mobileHeaderIsVisible && this.$mq.isMobileS) {
        if (delta > this.headerHeight) {
          if (this.$route.hash !== '') {
            this.$emit('reset-observer');
          }
          if (this.coverIsVisible !== true) {
            this.coverIsVisible = true;
          }
        } else if (this.coverIsVisible !== false) {
          this.coverIsVisible = false;
        }
      } else if (delta === 0) {
        favoriteToggle.style.opacity = '0';
      } else {
        if (this.$route.hash !== '') {
          this.$emit('reset-observer');
        }
        favoriteToggle.removeAttribute('style');
      }
    },
    scrollToAnchor() {
      if (!this.signal) {
        this.setSignal(true);
        gsap.to(window, {
          scrollTo: {
            y: document.querySelector(this.$route.hash),
            offsetY: this.$refs.pageMenu.offsetHeight,
            autoKill: true,
          },
          duration: 0.5,
          ease: 'expo.out',
          onInterrupt: () => {
            this.setSignal(false);
          },
          onComplete: () => {
            this.$nextTick(() => {
              this.setSignal(false);
            });
          },
        });
      }
    },
    setScrollbarWidth() {
      const root = document.documentElement;
      const scrollbarWidth = window.innerWidth - document.body.clientWidth;

      root.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
    },
  },
};
</script>

<style lang="scss">
  .page-menu {
    &__container {
      background-color: var(--green);
      max-width: calc(100vw - var(--scrollbar-width));
      padding: 0;
      position: sticky;
      top: 0;
      transition: top 0.2s ease;
      z-index: 1;

      &--shifted {
        top: var(--header-height);

        @include mq(s) {
          top: 0;
        }
      }
    }

    column-gap: var(--column-gap-l);
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    overflow-x: auto;
    padding: var(--spacer-m);
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &__shadow {
      box-shadow: inset calc(-1 * var(--spacer-m) - 10px) 0 15px -15px var(--green),
        inset calc(var(--spacer-m) + 10px) 0 15px -15px var(--green);
      inset: 0;
      pointer-events: none;
      position: absolute;
      z-index: 1;
    }

    &--overflown {
      justify-content: initial;
    }

    a {
      opacity: 0.4;
      white-space: nowrap;
    }

    @media (hover: hover) {
      a:hover {
        opacity: 1;
      }
    }

    .router-link-active {
      opacity: 1;
    }
  }
</style>
