<template>
  <aside class="sidebar">
    <SidebarMenu />
    <SidebarFooter />
  </aside>
</template>

<script>
import SidebarMenu from '@/components/ui/app-sidebar-menu';
import SidebarFooter from '@/components/ui/app-sidebar-footer';

export default {
  name: 'AppSidebar',
  components: {
    SidebarMenu,
    SidebarFooter,
  },
};
</script>

<style lang="scss">
  .sidebar {
    align-content: space-between;
    background: var(--white);
    border-right: var(--line) var(--grey-l) solid;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    width: var(--sidebar-width);
    z-index: 2;

    @include mq(s) {
      display: grid;
    }
  }
</style>
