<template>
  <div
    ref="imageSlider"
    :class="['image-slider', theme ? `image-slider--${theme}` : null]"
  >
    <div
      v-for="(image, index) in data.images"
      :key="index"
      class="image-slider__image"
      :data-item-id="image.id"
    >
      <Figure
        :ref="index"
        :data="{
          default: image,
          ratio: theme === 'image-bank' ? false : true,
          object_fit: 'contain',
        }"
      />
    </div>
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

import Figure from '@/components/media/figure';

require('flickity-imagesloaded');

export default {
  name: 'ImageSlider',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      slider: null,
      settings: {
        cellAlign: 'center',
        cellSelector: '.image-slider__image',
        imagesLoaded: true,
        pageDots: false,
        wrapAround: true,
      },
    };
  },
  watch: {
    $mq: {
      deep: true,
      handler() {
        if (this.$mq.isMobileS) {
          this.settings.cellAlign = 'left';
          this.slider.destroy();
          this.slider = new Flickity(
            this.$refs.imageSlider,
            Object.assign(this.settings, this.extraSettings),
          );
          this.setFlickityButtons();
        } else {
          this.settings.cellAlign = 'center';
          this.slider.destroy();
          this.slider = new Flickity(
            this.$refs.imageSlider,
            Object.assign(this.settings, this.extraSettings),
          );
          this.setFlickityButtons();
        }
      },
    },
  },
  mounted() {
    if (this.$mq.isMobileS) {
      this.settings.cellAlign = 'left';
    }

    this.slider = new Flickity(
      this.$refs.imageSlider,
      Object.assign(this.settings, this.extraSettings),
    );
    this.setFlickityButtons();
  },
  beforeUnmount() {
    this.slider.destroy();
  },
  methods: {
    setFlickityButtons() {
      const prev = document.querySelector('.flickity-button.previous');
      const next = document.querySelector('.flickity-button.next');
      const arrowRight = `
        <div class="icon icon-theme--default icon--size-xl icon-arrow">
          <svg height="8" viewBox="0 0 12 8" width="12" xmlns="http://www.w3.org/2000/svg">
            <path vector-effect="non-scaling-stroke" d="m0 4h12" />
            <path vector-effect="non-scaling-stroke" d="m12 4c-2.222256 0-4-1.7777846-4-4" />
            <path vector-effect="non-scaling-stroke" d="m12 4c-2.222256 0-4 1.7777846-4 4" />
          </svg>
        </div>`;
      const arrowLeft = `
        <div class="icon icon-theme--default icon--size-xl icon-arrow-left">
          <svg height="8" viewBox="0 0 12 8" width="12" xmlns="http://www.w3.org/2000/svg">
            <path vector-effect="non-scaling-stroke" d="m12 4h-12" />
            <path vector-effect="non-scaling-stroke" d="m0 4c2.222256 0 4-1.7777846 4-4" />
            <path vector-effect="non-scaling-stroke" d="m0 4c2.222256 0 4 1.7777846 4 4" />
          </svg>
        </div>`;

      prev.innerHTML = arrowLeft;
      next.innerHTML = arrowRight;
    },
  },
};
</script>

<style lang="scss">
  .image-slider {
    align-items: center;
    display: grid;

    > * {
      grid-area: 1 / 1;
    }

    .flickity {
      &-slider {
        display: grid;
        align-items: center;
      }

      &-viewport {
        overflow: visible;
      }

      &-button {
        background: transparent;
      }

      &-prev-next-button {
        width: min-content;
        height: initial;
        position: initial;
        transform: initial;
        z-index: 1;
        mix-blend-mode: difference;
        margin: var(--spacer-m);

        path {
          stroke: var(--white);
        }

        &.next {
          justify-self: end;
        }
      }
    }

    &__image {
      width: calc(4 / 5 * 100%);
      transition: transform 0.2s ease;
      margin-right: var(--column-gap-m);

      @include mq(s) {
        width: calc(2 / 3 * 100%);
        &:not(.is-selected) {
          transform: scale(0.9);
          margin-right: 0;
        }
      }
    }

    &--image-bank {
      .image-slider {
        &__image {
          width: 100%;
          height: 100%;
          margin-right: var(--column-gap-m);

          &:not(.is-selected) {
            transform: initial;
          }
        }
      }
    }
  }
</style>
