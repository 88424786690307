var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"overlay",staticClass:"image-bank-slider",attrs:{"tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.setCurrent(false)}}},[_c('Icon',{attrs:{"name":"plus","size":"l","rotation":45,"fn":function () { return _vm.setCurrent(false); }}}),_c('ImageSlider',{ref:"slider",attrs:{"data":{
      images: _vm.current.category.items.map(function (item) { return item.gds_featured_image; }),
    },"extra-settings":{ initialIndex: _vm.current.initialIndex },"theme":"image-bank"}}),(_vm.currentAsset)?_c('AssetBar',{key:_vm.currentAsset.id,staticClass:"asset-bar--no-border",attrs:{"remove-actions":['collection'],"data":{
      title: _vm.currentAsset.title,
      acf: {
        asset: _vm.currentAsset.gds_featured_image,
      },
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }