<template>
  <div
    class="login-background"
    :style="backgroundImage"
  >
    <div
      class="login-title login-title--right typo--1 t-left"
      size="m"
      v-html="titleRight"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LoginRightPanel',
  components: {},
  computed: {
    ...mapGetters(['wcSettings']),
    backgroundImage() {
      if (this.$store.state.wp.options && this.$store.state.wp.options.background_image) {
        return {
          'background-image': `url(${this.$store.state.wp.options.background_image})`,
        };
      }
      return null;
    },
    titleRight() {
      if (this.$store.state.wp.options && this.$store.state.wp.options.login_title_right) {
        return this.$store.state.wp.options.login_title_right;
      }
      return null;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.login-background {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: none;
    @include mq(map-get($mq-breakpoints, 's')) {
      display: block;
    }
  }
</style>
