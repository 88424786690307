export const isTouchDevice = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = (query) => window.matchMedia(query).matches;
  /* eslint-disable-next-line no-mixed-operators, no-undef */
  if ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch)) {
    return true;
  }
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
    '',
  );
  return mq(query);
};

export const flatAndSort = (object, key) => {
  const keys = Object.keys(object);
  const sortedKeys = keys.sort((a, b) => object[a][key] - object[b][key]);
  const sortedObject = [];
  sortedKeys.forEach((k) => sortedObject.push({ key: k, ...object[k] }));
  return sortedObject;
};

export const getBreakpoint = () => JSON.parse(
  window
    .getComputedStyle(document.documentElement)
    .getPropertyValue('--breakpoints'),
);

export const is = (breakpoint) => {
  const b = window.innerWidth;
  const c = parseInt(
    window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(`--breakpoints-${breakpoint}`),
    10,
  );
  if (c) {
    return b < c;
  }
  return false;
};

export const isSafari = () => typeof navigator !== 'undefined'
  && navigator.vendor
  && navigator.vendor.includes('Apple')
  && navigator.userAgent
  && !navigator.userAgent.includes('CriOS')
  && !navigator.userAgent.includes('Chrome')
  && !navigator.userAgent.includes('FxiOS');

export const isIOS = () => [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
].includes(navigator.platform)
  || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export const isEdge = () => typeof window !== 'undefined' && window.navigator.userAgent.includes('Edge');

export const isIE = () => typeof window !== 'undefined'
  && (window.navigator.userAgent.includes('MSIE')
    || window.navigator.userAgent.includes('Trident/'));

export const norm = (val, max, min) => (val - min) / (max - min);

export const viewport = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
  ratio: window.innerWidth / window.innerHeight,
});

export const lerp = (a, b, n) => (1 - n) * a + n * b;

export const modulate = (value, rangeA, rangeB, limit) => {
  if (limit == null) {
    limit = false;
  } // eslint-disable-line
  const [fromLow, fromHigh] = Array.from(rangeA);
  const [toLow, toHigh] = Array.from(rangeB);
  const result = toLow + ((value - fromLow) / (fromHigh - fromLow)) * (toHigh - toLow);

  if (limit === true) {
    if (toLow < toHigh) {
      if (result < toLow) {
        return toLow;
      }
      if (result > toHigh) {
        return toHigh;
      }
    } else {
      if (result > toLow) {
        return toLow;
      }
      if (result < toHigh) {
        return toHigh;
      }
    }
  }

  return result;
};

export const loadScript = (src) => new Promise((resolve) => {
  const s = document.createElement('script');
  let r = false;
  s.type = 'text/javascript';
  s.src = src;
  // eslint-disable-next-line
  s.onload = s.onreadystatechange = function () {
    if (!r && (!this.readyState || this.readyState === 'complete')) {
      r = true;
      resolve();
    }
  };
  const t = document.getElementsByTagName('script')[0];
  t.parentNode.insertBefore(s, t);
});

export const getParm = (url, base) => {
  const re = new RegExp(`(\\?|&)${base}\\=([^&]*)(&|$)`);
  const matches = url.match(re);
  if (matches) {
    return matches[2];
  }
  return '';
};

export const parseVideoURL = (url) => {
  const retVal = {};
  const matches = url.match(/vimeo.com\/(\d+)/);

  if (url.indexOf('youtube.com/watch') !== -1) {
    retVal.provider = 'youtube';
    retVal.id = getParm(url, 'v');
  } else if (matches) {
    retVal.provider = 'vimeo';
    // eslint-disable-next-line prefer-destructuring
    retVal.id = matches[1];
  }

  return retVal;
};

export const setContent = (item) => {
  const {
    label,
    tag_label,
    size_label,
    title,
    tag_title,
    size_title,
    rich_text,
    ctas,
    wrapper,
    layout,
    cta_layout,
  } = item;

  return {
    label,
    tag_label,
    size_label,
    title,
    tag_title,
    size_title,
    rich_text,
    ctas,
    wrapper,
    layout,
    cta_layout,
  };
};

export const setMedia = (item) => {
  const { media } = item;

  return media;
};

export const checkContent = (item) => {
  const {
    label, title, rich_text, ctas,
  } = item;
  return label || title || rich_text || ctas;
};

export const checkMedia = (item) => {
  if (item.media) {
    const { media } = item;

    let check = true;

    if (media.type === 'image') {
      check = media.image.default;
    }

    return media && media[media.type] && check;
  } return false;
};

export const filesizeformat = (bytes, binary, precision) => {
  /*
      Javascript filesizeformater.
      Inspired by jinja2 and some gists.
      @version 1.0.0
      @copyright 2014 Julian Hille
      @author Julian Hille
   */
  binary = typeof binary !== 'undefined' ? binary : false;
  precision = typeof precision !== 'undefined' ? precision : 2;
  const base = binary ? 1024 : 1000;
  const prefixes = [
    (binary ? 'KiB' : 'kB'),
    (binary ? 'MiB' : 'MB'),
    (binary ? 'GiB' : 'GB'),
    (binary ? 'TiB' : 'TB'),
    (binary ? 'PiB' : 'PB'),
    (binary ? 'EiB' : 'EB'),
    (binary ? 'ZiB' : 'ZB'),
    (binary ? 'YiB' : 'YB'),
  ];
  if (!Number.isFinite(bytes)) {
    return '- Bytes';
  }
  if (bytes === 1) {
    return '1 Byte';
  }
  if (bytes < base) {
    return `${bytes} Bytes`;
  }
  const index = Math.floor(Math.log(bytes) / Math.log(base));
  return `${parseFloat((bytes / (base ** Math.floor(index))).toFixed(precision)).toString()} ${prefixes[index - 1]}`;
};

export const getSimpleBreadcrumbs = (data) => {
  if (data.parents && data.parents[0]) {
    const titles = data.parents.map((x) => x.post_title);
    titles.push(data.title.rendered);
    return `${titles.join(' / ')}`;
  }
  return null;
};
