<template>
  <Spacer
    all="m"
    :class="[
      'sidebar-footer',
      layout && layout !== '' ? `sidebar-footer--${layout}` : null,
    ]"
  >
    <Icon
      :fn="togglePopUp"
      name="account"
    />
    <Icon
      :to="{ name: 'Search' }"
      name="search"
    />
    <transition name="fade">
      <PopUp
        v-if="popUpIsVisible"
        @hide-pop-up="togglePopUp"
      />
    </transition>
  </Spacer>
</template>

<script>
import Icon from '@/components/ui/icon';
import PopUp from '@/components/ui/account-pop-up';

export default {
  name: 'AppSidebarFooter',
  components: {
    Icon,
    PopUp,
  },
  props: {
    layout: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      popUpIsVisible: false,
    };
  },
  methods: {
    togglePopUp() {
      this.popUpIsVisible = !this.popUpIsVisible;
      // this.$router.push({ name: 'Account' });
    },
  },
};
</script>

<style lang="scss">
  .sidebar-footer {
    box-shadow: 0 calc(-1 * var(--spacer-m)) 15px -15px var(--white);
    column-gap: var(--column-gap-s);
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    width: 100%;
    z-index: 1;

    &--mobile {
      align-items: center;
      background-color: var(--white);
      position: fixed;
      bottom: 0;
      left: 0;
      padding: var(--spacer-m);
      border-top: var(--line) var(--grey-l) solid;
      min-height: var(--header-height);
    }
  }
</style>
