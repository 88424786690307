var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Spacer',[(_vm.data.label && _vm.data.label !== '')?_c('Label',{attrs:{"data":{
      value: _vm.data.label,
      tag: _vm.data.tag_label,
      size: _vm.data.size_label
    }}}):_vm._e(),(_vm.data.title && _vm.data.title !== '')?_c('Title',{attrs:{"data":{
      value: _vm.data.title,
      tag: _vm.data.tag_title,
      size: _vm.data.size_title,
      justify: _vm.data.title_justification
    }}}):_vm._e(),(_vm.data.rich_text && _vm.data.rich_text !== '')?_c('RichText',{attrs:{"data":{
      value: _vm.data.rich_text,
      size: _vm.data.size_rich_text
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }