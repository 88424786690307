<template>
  <main class="main">
    <PageCover
      ref="cover"
      :data="{ ...post.acf.page_cover, id: post.id }"
    />
    <PageMenu
      v-if="post.acf.page_menu && post.acf.page_menu.active"
      ref="pageMenu"
      :sections="post.acf.sections"
      :observed-section="observedSection"
      :set-signal="setSignal"
      @reset-observer="resetObserver()"
    />
    <Spacer
      v-else
      :bottom="'page--top'"
      use-margin
    />
    <Section
      v-for="(section, parentIndex) in post.acf.sections"
      v-show="!favoritesAreEmpty(section)"
      :key="parentIndex"
      :layout="{
        ...layout(section),
        section_id: section.menu_name
          ? section.menu_name.replace(/\s/g, '-').toLowerCase()
          : null,
      }"
      :class="[`section--${parentIndex}`, 'fading']"
      :style="
        section.blocks &&
          section.blocks.some((e) => e.acf_fc_layout === 'image_slider')
          ? { overflow: 'hidden' }
          : null
      "
    >
      <Wrapper
        v-for="(block, index) in section.blocks"
        :key="index"
        :size="block.wrapper"
        :boxed="false"
        :class="
          block.background_color && block.background_color !== ''
            ? `background-color--${block.background_color}`
            : null
        "
      >
        <component
          :is="components.get(block.acf_fc_layout)"
          ref="blocks"
          :class="[
            'block',
            `block--${block.acf_fc_layout}`,
            block.block_classes && block.block_classes !== ''
              ? block.block_classes.split(' ')
              : null,
          ]"
          :data="block"
        />
      </Wrapper>
    </Section>
    <Spacer
      top="page--bottom"
      use-margin
    />
  </main>
</template>
<script>
import { mapGetters } from 'vuex';
import medusa from '@/assets/js/observer';
import components from '@/assets/js/components';
import data from '@/mixins/data';

import Section from '@/components/section';
import PageCover from '@/components/blocks/page-cover';
import PageMenu from '@/components/ui/page-menu';

export default {
  name: 'Page',
  components: {
    Section,
    PageCover,
    PageMenu,
  },
  mixins: [data],
  data() {
    return {
      components,
      observedSection: '',
      signal: false,
      sections: [],
      statementIsVisible: false,
    };
  },
  computed: {
    ...mapGetters(['favourites']),
  },
  mounted() {
    if (this.post.acf.page_menu && this.post.acf.page_menu.active) {
      this.sections = Array.from(this.$el.querySelectorAll('section[id]'));
      // this.$aion.add(this.checkSection, 'checkSection', true);
      medusa.ref.addTarget({
        id: 'page-menu-sections-observer',
        nodes: [],
        mode: 'byPixel',
        callback: this.checkSection,
        autoremove: false,
      });

      this.$nextTick(() => {
        medusa.ref.pushToTarget('page-menu-sections-observer', this.sections);
      });
    }

    if (
      this.post.acf.sections
      && this.post.acf.sections.some(
        (section) => section.blocks
          && section.blocks.some(
            (block) => block.acf_fc_layout === 'multi_statement',
          ),
      )
    ) {
      medusa.ref.addTarget({
        id: 'statement-observer',
        threshold: 0,
        nodes: [],
        mode: 'once',
        callback: this.handleStatementObserver,
        autoremove: true,
      });

      const statements = this.$refs.blocks
        .filter((block) => block.$refs.multiStatement)
        .map((statement) => statement.$el);

      this.$nextTick(() => {
        medusa.ref.pushToTarget('statement-observer', statements);
      });
    }
  },
  beforeDestroy() {
    if (this.post.acf.page_menu && this.post.acf.page_menu.active) {
      medusa.ref.removeTarget('page-menu-sections-observer');
    }
    if (
      this.post.acf.sections
      && this.post.acf.sections.some(
        (section) => section.blocks
          && section.blocks.some(
            (block) => block.acf_fc_layout === 'multi_statement',
          ),
      )
    ) {
      medusa.ref.removeTarget('statement-observer');
    }
  },
  methods: {
    layout(section) {
      const {
        background_color, section_wrapper, section_spacer, gap,
      } = section;
      return {
        background_color,
        section_wrapper,
        section_spacer,
        gap,
      };
    },
    resetObserver() {
      this.observedSection = '';
    },
    setSignal(val) {
      this.signal = val;
    },
    checkSection(entry) {
      if (
        !this.signal
        && entry.isIntersecting
        && Math.floor(entry.intersectionRect.y)
          <= this.$refs.pageMenu.$el.offsetHeight
        && Math.floor(entry.intersectionRect.bottom)
          > this.$refs.pageMenu.$el.offsetHeight
      ) {
        this.observedSection = entry.target.id;
      }
    },
    favoritesAreEmpty(section) {
      if (
        section.blocks
        && section.blocks.some((e) => e.acf_fc_layout === 'favorites')
      ) {
        if (this.favourites && this.favourites.length === 0) {
          return true;
        }
      }
      return false;
    },
    handleStatementObserver(entry) {
      if (entry.isIntersecting) {
        // eslint-disable-next-line
        entry.target.__vue__.startTimer();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  &--locked {
    position: fixed;
    right: 0;
    width: calc(100% - var(--sidebar-width));
  }
}
</style>
