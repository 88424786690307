import difference from 'lodash.difference';
import orderBy from 'lodash.orderby';

import {
  fetchItems,
  fetchSingle,
  // fetchSearch,
  sendAjax,
  // toggleFavourite,
} from '@/api/wp';

import {
  fetchSingleById,
} from '@/api/wc';

export default {
  async getSingleById(_, {
    type, id,
  }) {
    const response = await fetchSingleById({ type, params: { id } }).then(({ data }) => data);
    return response;
  },
  async getSingleBySlug({ commit, getters }, {
    type, slug, lang,
  }) {
    let response = null;
    const responseFromState = getters.singleBySlug({ type, slug, lang });
    if (!responseFromState) {
      await fetchSingle({ type, params: { slug, lang } }).then(({ data: [item] }) => {
        commit('ADD_ITEM', { type, item, lang });
        response = item;
      });
    } else {
      response = responseFromState;
    }
    return response;
  },
  async getItems({ getters, commit }, {
    type, params,
  }) {
    let response = null;
    const responseFromState = getters.request({ type, params });
    const responseWithParams = responseFromState && params.slug && responseFromState.length !== params.slug.length;

    if (!responseFromState || responseWithParams) {
      let itemsFromStore = [];
      let prevParamsSlug = null;
      if (responseWithParams) {
        prevParamsSlug = params.slug;
        const slugFromStore = difference(params.slug, responseFromState);
        Object.assign(params, { slug: slugFromStore });
        itemsFromStore = responseFromState.map((slug) => getters.singleBySlug({ type, slug, lang: params.lang }));
      }
      await fetchItems({ type, params })
        .then(({ data: items, headers: { 'x-wp-total': total, 'x-wp-totalpages': totalPages } }) => {
          if (type !== 'search') {
            items.forEach((item) => commit('ADD_ITEM', { type, item, lang: params.lang }));
            commit('ADD_REQUEST', {
              type,
              request: {
                type,
                params,
                total: parseInt(total, 10),
                totalPages: parseInt(totalPages, 10),
                data: items.map((i) => i.slug),
              },
            });

            const sortResponse = responseWithParams ? orderBy([...items, ...itemsFromStore],
              [(i) => prevParamsSlug.findIndex((e) => e === i.slug)]) : items;

            const parseResponse = params.per_page ? sortResponse.slice(0, params.per_page) : sortResponse;

            response = parseResponse;
          } else {
            response = items;
          }
        });
    } else {
      const parseResponseFromState = params.per_page ? responseFromState.slice(0, params.per_page) : responseFromState;
      response = parseResponseFromState.map((slug) => getters.singleBySlug({ type, slug, lang: params.lang }));
    }

    // Marketing
    if (type === 'product') {
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$bus.$emit('viewItemsList', response);
    }

    return response;
  },
  // async toggleFavourite({ commit }, id) {
  //   const { data } = await toggleFavourite({
  //     id,
  //     // nonce: state.wp.session.nonce,
  //   });
  //   console.log(data.favourites);
  //   commit('SET_FAVOURITES', data.favourites);
  //   // state.session.favourites = data.favourites;
  // },
  getSearch(_, request) {
    console.log('request: ', request);
    return fetchItems({ type: 'search', params: { search: request } });
  },
  sendRequest(_, request) {
    return sendAjax(request);
  },
  onLogin({ commit }) {
    commit('DO_LOGIN');
  },
};
