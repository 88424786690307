<template>
  <nav
    ref="sidebarMenu"
    :class="[
      'sidebar-menu',
      menuOverflow ? 'sidebar-menu--overflown' : null,
      layout && layout !== '' ? `sidebar-menu--${layout}` : null,
    ]"
  >
    <router-link
      class="logo typo--3"
      to="/"
    >
      <inline-svg :src="require('@/assets/svg/logo-desktop.svg')" />
    </router-link>
    <div>
      <div class="sidebar-menu--1">
        <div
          v-for="item in sidebarMenu1"
          :key="item.id"
        >
          <div
            v-if="checkIfBlockIsVisible(item.acf.roles_admitted, userRole)"
            class="sidebar-menu__block"
          >
            <h4 class="trim--4">
              {{ item.content }}
            </h4>
            <div
              v-for="child in item.children"
              :key="child.id"
            >
              <div v-if="checkIfBlockIsVisible(child.acf.roles_admitted, userRole)">
                <Cta
                  v-if="child.children.length === 0"
                  theme="overflow"
                  :data="{
                    url: child.url,
                    title: child.content,
                  }"
                />
                <Sub
                  v-else
                  class="sidebar-menu__block"
                  :item="child"
                  @check-overflow="checkOverflow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar-menu__block">
        <Cta
          v-for="item in sidebarMenu2"
          :key="item.id"
          theme="overflow"
          :data="{
            url: item.url,
            title: item.content,
            target: item.target,
          }"
        />
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';
import Sub from '@/components/ui/app-sidebar-menu-sub';

export default {
  name: 'AppSidebarMenu',
  components: {
    Cta,
    Sub,
  },
  props: {
    layout: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      menuOverflow: false,
    };
  },
  computed: {
    ...mapGetters(['menu', 'user', 'customer']),
    sidebarMenu1() {
      return this.menu('Sidebar 1').items || null;
    },
    sidebarMenu2() {
      return this.menu('Sidebar 2').items || null;
    },
    userRole() {
      if (this.customer && this.customer.role && this.customer.role[0]) {
        return this.customer.role[0];
      }
      return undefined;
    },
  },
  async mounted() {
    await this.$store.dispatch('loadCustomerData');
    this.setScrollbarOverlay();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    checkOverflow() {
      this.$nextTick(() => {
        this.setScrollbarOverlay();
      });
    },
    setScrollbarOverlay() {
      const menu = this.$refs.sidebarMenu;
      const scrollbarWidth = menu.offsetWidth - menu.clientWidth;

      menu.style.paddingRight = `calc(var(--spacer-m) - ${scrollbarWidth}px)`;
    },
    handleResize() {
      this.setScrollbarOverlay();
    },
    checkIfBlockIsVisible(permissionNeeded, permission) {
      if (permissionNeeded[0]) {
        if (permissionNeeded.indexOf(permission) > -1) {
          return true;
        }
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
  .sidebar-menu {
    align-content: start;
    background: var(--white);
    display: grid;
    overflow-x: hidden;
    overflow-y: auto;
    row-gap: var(--row-gap-l);
    white-space: nowrap;
    padding: var(--spacer-m);

    > div {
      display: grid;
      row-gap: var(--row-gap-l);
    }

    .logo {
      display: none;
      fill: var(--black);
      font-size: 16px;
      svg {
        max-width: 156px;
        max-height: 61px;
      }

      @include mq(s) {
        display: grid;
        gap: var(--spacer-xxs)
      }
    }

    &--mobile {
      height: calc(var(--vh) * 100 - (var(--header-height) * 2));
      padding: var(--spacer-m);
    }

    &--overflown {
      align-content: start;
      border-bottom: var(--line) var(--grey-l) solid;
    }

    &--1 {
      display: grid;
      row-gap: var(--row-gap-m);
    }

    .cta__title {
      transition: opacity 0.2s;
      @media (hover: hover) {
        &:hover {
          opacity: 0.6;
        }
      }
    }

    &__block {
      display: grid;
      row-gap: var(--row-gap-xs);

      > div:empty {
        display: none;
      }

      h4 {
        font-weight: bold;
      }

      &--sub {
        margin-left: var(--spacer-xs);
      }
    }
  }
</style>
