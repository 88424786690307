import labels from '@/assets/js/labels';

import {
  addToCart,
  updateCartItem,
  removeCartItem,
  getCart,
  clearCart,
  calculateCart,
  createOrder,
  // getCoupons,
  addCoupon,
  removeCoupon,
  setShippingMethod,
  setPaymentGateway,
  createUser,
  getCustomer,
  getOrder,
  getOrders,
  updateAddress,
  updateUser,
  getCountries,
  cancelOrder,
  updatePaymentMethod,
  getSubscription,
  getSubscriptions,
  changeSubscriptionStatus,
  updateSubscriptionShipping,
  // calculateShippingMethods,
  // getPaymentGateways,
  fetchVariations,
} from '@/api';

import {
  toggleFavourite,
} from '@/api/wp';

import sessionHandler from '@/assets/js/sessionHandler';

import { removeCartCookies } from '@/store/modules/auth/actions';

const getCartFn = async ({ commit }) => {
  commit('SET_CART_LOADING', true);
  await calculateCart({
    payLoad: {},
  });
  const { data } = await getCart();
  commit('SET_CART', data);
  commit('SET_CART_LOADING', false);
};

const addCheckoutMetas = (body, metas) => {
  body.metas = {};
  if (metas.stripe) {
    body.metas.stripe = {
      client_secret: metas.stripe.clientSecret,
    };
  }
  return body;
};

export default {
  async initActions({ dispatch }) {
    await dispatch('checkUser');
  },
  getCart: getCartFn,
  async addToCart({ commit }, initialData) {
    const {
      id, variation_id, quantity, cart_item_data,
    } = initialData;
    commit('SET_CART_LOADING', true);
    const { data, status } = await addToCart({
      payLoad: {
        product_id: id,
        variation_id,
        quantity,
        cart_item_data,
      },
    });
    if (status === 200) {
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$bus.$emit('addedToCart', initialData);

      const { data: dataCart } = await getCart();

      commit('SET_CART', dataCart);
    } else {
      commit('SET_SNACKBAR', { active: true, content: data.message });
    }
    commit('SET_CART_LOADING', false);
  },
  async updateCartItem({ commit }, initialData) {
    const { key, quantity } = initialData;
    commit('SET_CART_LOADING', true);
    const { data, status } = await updateCartItem({
      payLoad: {
        cart_item_key: key,
        quantity,
      },
    });
    if (status === 200) {
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$bus.$emit('addedToCart', initialData);

      const { data: dataCart } = await getCart();

      commit('SET_CART', dataCart);
    } else {
      commit('SET_SNACKBAR', { active: true, content: data.message });
    }
    commit('SET_CART_LOADING', false);
  },
  async removeCartItem({ commit }, initialData) {
    const { key } = initialData;
    commit('SET_CART_LOADING', true);
    const { data, status } = await removeCartItem({
      payLoad: {
        cart_item_key: key,
      },
    });
    if (status === 200) {
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$bus.$emit('removedFromCart', initialData);

      const { data: dataCart } = await getCart();

      commit('SET_CART', dataCart);
    } else {
      commit('SET_SNACKBAR', { active: true, content: data.message });
    }
    commit('SET_CART_LOADING', false);
  },
  async clearCart({ state, commit }) {
    commit('SET_CART_LOADING', true);
    const result = await clearCart();
    removeCartCookies();
    state.paymentGateway = null;
    if (result === true) {
      commit('SET_CART', {});
    }
    commit('SET_CART_LOADING', false);
  },
  // getCartTotals: getCartTotalsFn,
  async setShippingMethod({ state, commit, getters }, body) {
    commit('SET_CART_LOADING', true);
    const { data, status } = await setShippingMethod(addCheckoutMetas(body, getters.paymentMetas));
    if (status === 200) {
      getCartFn({ commit });
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$bus.$emit('addShippingInfo', state.cart);
    } else {
      commit('SET_SNACKBAR', { active: true, content: data.message });
    }
    commit('SET_CART_LOADING', false);
  },
  async setPaymentGateway({ state, commit, getters }, body) {
    commit('SET_CART_LOADING', true);
    const { data, status } = await setPaymentGateway(addCheckoutMetas(body, getters.paymentMetas));
    state.paymentGateway = body.gateway_id;
    if (status === 200) {
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$bus.$emit('addPaymentInfo', Object.assign(state.cart, { paymentGateway: state.paymentGateway }));
    } else {
      commit('SET_SNACKBAR', { active: true, content: data.message });
    }
    commit('SET_CART_LOADING', false);
  },
  async addCoupon({ commit, getters }, body) {
    commit('SET_CART_LOADING', true);
    const { data, status } = await addCoupon(addCheckoutMetas(body, getters.paymentMetas));

    if (status === 200) {
      getCartFn({ commit });
      // commit('SET_CART', data);
      // commit('SET_SNACKBAR', { active: true, content: data.notices.success[0], theme: 'success' });
    } else {
      commit('SET_SNACKBAR', { active: true, content: data.message });
    }
    commit('SET_CART_LOADING', false);
  },
  async removeCoupon({ commit, getters }, body) {
    commit('SET_CART_LOADING', true);
    const { data, status } = await removeCoupon(addCheckoutMetas(body, getters.paymentMetas));
    if (status === 200) {
      getCartFn({ commit });
      // commit('SET_CART', data);
    } else {
      commit('SET_SNACKBAR', { active: true, content: data.message });
    }
    commit('SET_CART_LOADING', false);
  },
  async createOrder({ commit }, body) {
    commit('SET_CART_LOADING', true);
    const { data, status } = await createOrder(body);
    commit('SET_CART_LOADING', false);
    if (status === 200) {
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$bus.$emit('purchased', data.order);

      return data;
    }
    commit('SET_SNACKBAR', { active: true, content: data });
    return false;
  },
  // async paymentGateways({ commit, getters }) {
  //   if (!getters.paymentGateways || Object.keys(getters.paymentGateways).length === 0) {
  //     const { data } = await getPaymentGateways();
  //     commit('SET_PAYMENT_GATEWAYS', data);
  //   }
  // },
  async createUser({ commit }, body) {
    try {
      const { data } = await createUser(body);
      commit('SET_SNACKBAR', { active: true, content: labels.account_created, theme: 'success' });
      if (typeof data === 'number') {
        await sessionHandler.login({
          username: body.email,
          password: body.password,
        });
      }
      return true;
    } catch (error) {
      const { response } = error;
      commit('SET_SNACKBAR', { active: true, content: response.data.message });
      return false;
    }
  },
  async simpleCreateUser({ commit }, body) {
    try {
      const { data } = await createUser(body);
      return data;
    } catch (error) {
      const { response } = error;
      commit('SET_SNACKBAR', { active: true, content: response.data.message });
      return false;
    }
  },
  async toggleFavourite({ commit }, id) {
    const { data } = await toggleFavourite({
      id,
      // nonce: state.wp.session.nonce,
    });
    commit('SET_FAVOURITES', data.favourites);
  },
  async checkUser({ commit, getters, dispatch }) {
    commit('SET_AUTH_LOADING', true);
    // console.log('checkUser');
    if (sessionHandler.user != null && !getters.user) {
      commit('SET_USER', sessionHandler.user);
    }
    if (getters.user) {
      if (!getters.customer || Object.keys(getters.customer).length === 0) {
        const { data, status } = await getCustomer();
        if (status === 200) {
          commit('SET_CUSTOMER', data.user);
          commit('SET_FAVOURITES', data.favourites);
          // commit('SET_CUSTOMER_SAVED_METHODS', data.customer_saved_methods_list);
        } else {
          dispatch('logout');
        }
      }
    }
    commit('SET_AUTH_LOADING', false);
  },
  closeSnackbar({ commit }) {
    commit('SET_SNACKBAR', { active: false, content: null });
  },
  // eslint-disable-next-line no-empty-pattern
  getOrder({}, params) {
    return getOrder(params);
  },
  async getOrders({ commit }) {
    // if (!getters.orders) { richiesti per sicurezza sempre altrimenti alla fine di un acquisto uno non li vede
    commit('SET_CART_LOADING', true);
    const response = await getOrders();

    if (response) {
      const { data } = response;
      commit('SET_ORDERS', data);
    }
    commit('SET_CART_LOADING', false);
    // }
  },
  async editAddress({ commit }, body) {
    try {
      const response = await updateAddress(body);
      if (response) {
        const { data } = response;
        commit('SET_CUSTOMER', data.user);
      }
      commit('SET_SNACKBAR', { active: true, content: labels.saved_changes, theme: 'success' });
    } catch (error) {
      commit('SET_SNACKBAR', { active: true, content: labels.error_user_addresses_update });
    }
  },
  async editUser({ commit, getters }, body) {
    try {
      const { data: user } = await updateUser(body);
      if (user) {
        if (user.access_token) {
          const { data } = user.wp_user;
          commit('SET_USER', data);
        } else {
          const newUser = Object.assign(getters.user, user);
          commit('SET_USER', newUser);
        }

        const { data } = await getCustomer();
        commit('SET_CUSTOMER', data.user);
      }
      commit('SET_SNACKBAR', { active: true, content: labels.saved_changes, theme: 'success' });
    } catch (error) {
      commit('SET_SNACKBAR', { active: true, content: labels.error_user_update });
    }
  },
  async loadCustomerData({ commit, dispatch }) {
    try {
      const { data } = await getCustomer();
      // if (sessionHandler.logged) {
      if (!data[1]) {
        await dispatch('logout');
      }
      if (data[0].role && data[0].role[0] === 'subscriber') {
        await dispatch('logout');
      }
      commit('SET_CUSTOMER', data[0]);
      // nonce = data[1]
      commit('SET_FAVOURITES', data[2]);
      // commit('SET_CUSTOMER_SAVED_METHODS', data.customer_saved_methods_list);
      // }
      // getCartFn({ commit });
      // const { data: dataCart } = await getCart();
      // commit('SET_CART', dataCart);
      return data[0];
    } catch (error) {
      commit('SET_SNACKBAR', { active: true, content: labels.error_generic_customer });
      return false;
    }
  },
  async getCountries({ commit, getters }) {
    if (!getters.geoData) {
      const response = await getCountries();

      if (response) {
        const { data } = response;
        commit('SET_COUNTRIES', data);
      }
    }
  },
  async cancelOrder({ commit }, payLoad) {
    let completed;
    commit('SET_CART_LOADING', true);
    const { status } = await cancelOrder(payLoad);
    if (status === 200) {
      commit('SET_SNACKBAR', { active: true, content: labels.order_cancelled, theme: 'success' });
      // dispatch('getOrders');
      completed = true;
    } else {
      commit('SET_SNACKBAR', { active: true, content: labels.error_generic });
      completed = false;
    }
    commit('SET_CART_LOADING', false);
    return completed;
  },
  async updatePaymentMethod({ commit, dispatch }, { body, data }) {
    let completed;
    commit('SET_CART_LOADING', true);
    const { data: order, status } = await updatePaymentMethod(body, data);
    if (status === 200) {
      // commit('SET_SNACKBAR', { active: true, content: labels.order_updated, theme: 'success' });
      dispatch('getOrders');
      completed = order;
    } else {
      commit('SET_SNACKBAR', { active: true, content: labels.error_generic });
      completed = false;
    }
    commit('SET_CART_LOADING', false);
    return completed;
  },
  // async calculateShippingMethods({ commit, getters }) {
  //   const { customer } = getters;
  //   const { data: customerShippingMethods, status } = await calculateShippingMethods({
  //     country: customer.shipping.country ? customer.shipping.country : customer.billing.country,
  //     state: customer.shipping.state ? customer.shipping.state : customer.billing.state,
  //     city: customer.shipping.city ? customer.shipping.city : customer.billing.city,
  //     postcode: customer.shipping.postcode ? customer.shipping.postcode : customer.billing.postcode,
  //     return_methods: true,
  //   });
  //   if (status === 200) {
  //     commit('SET_CUSTOMER_SHIPPING_METHODS', customerShippingMethods);
  //     return customerShippingMethods;
  //   }
  //   commit('SET_SNACKBAR', { active: true, content: customerShippingMethods.message });
  //   return false;
  // },
  async getVariations({ getters, rootState }, {
    slug,
  }) {
    let response = null;
    const responseFromState = getters.checkVariations(slug);
    if (!responseFromState) {
      await fetchVariations({ params: { slug } }).then(({ data }) => {
        response = data;
        rootState.wp.product[rootState.lang][slug].variations = response;
      });
    } else {
      response = responseFromState;
    }
    return response;
  },
  async getSubscription({ commit }, params) {
    commit('SET_CART_LOADING', true);
    const { data } = await getSubscription(params);
    commit('SET_CART_LOADING', false);
    return data;
  },
  async getSubscriptions({ commit }) {
    commit('SET_CART_LOADING', true);
    const response = await getSubscriptions();

    if (response) {
      const { data } = response;
      commit('SET_SUBSCRIPTIONS', data);
    }
    commit('SET_CART_LOADING', false);
  },
  async changeSubscriptionStatus({ commit }, params) {
    commit('SET_CART_LOADING', true);
    await changeSubscriptionStatus(params);
    commit('SET_CART_LOADING', false);
    return true;
  },
  async updateSubscriptionShipping({ commit }, { params, body }) {
    commit('SET_CART_LOADING', true);
    const { status } = await updateSubscriptionShipping(params, body);
    if (status === 200) {
      commit('SET_SNACKBAR', { active: true, content: labels.saved_changes, theme: 'success' });
    }
    commit('SET_CART_LOADING', false);
    return true;
  },
};
