<template>
  <div
    ref="overlay"
    class="image-bank-slider"
    tabindex="0"
    @keyup.esc="setCurrent(false)"
  >
    <Icon
      name="plus"
      size="l"
      :rotation="45"
      :fn="() => setCurrent(false)"
    />
    <ImageSlider
      ref="slider"
      :data="{
        images: current.category.items.map((item) => item.gds_featured_image),
      }"
      :extra-settings="{ initialIndex: current.initialIndex }"
      theme="image-bank"
    />
    <AssetBar
      v-if="currentAsset"
      :key="currentAsset.id"
      class="asset-bar--no-border"
      :remove-actions="['collection']"
      :data="{
        title: currentAsset.title,
        acf: {
          asset: currentAsset.gds_featured_image,
        },
      }"
    />
  </div>
</template>

<script>
import ImageSlider from '@/components/blocks/image-slider';
import AssetBar from '@/components/asset-bar';
import Icon from '@/components/ui/icon';

export default {
  components: {
    AssetBar,
    ImageSlider,
    Icon,
  },
  props: {
    current: {
      type: [Object, Boolean],
      default: false,
    },
    currentAsset: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['set-current'],
  mounted() {
    this.$refs.overlay.focus();
  },
  methods: {
    setCurrent(value) {
      this.$emit('set-current', value);
    },
  },
};
</script>

<style lang="scss">
  .image-bank-slider {
    background-color: var(--overlay-white-09);
    display: grid;
    height: calc(var(--vh) * 100 - var(--header-height));
    left: var(--sidebar-width);
    grid-template-rows: auto 1fr auto;
    padding: var(--spacer-m);
    padding-bottom: 0;
    position: fixed;
    top: var(--header-height);
    width: calc(100% - var(--sidebar-width));
    z-index: 1;

    .icon-plus {
      justify-self: end;
    }
  }
</style>
