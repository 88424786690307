var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Cta',{attrs:{"theme":"menu","data":{
      title: _vm.item.content,
      icon: 'select',
      iconRotation: !_vm.menuIsVisible ? -90 : 0,
      iconSize: 's',
      fn: function () { return _vm.toggleMenu(); },
    }}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuIsVisible),expression:"menuIsVisible"}],staticClass:"sidebar-menu__block sidebar-menu__block--sub"},_vm._l((_vm.item.children),function(child){return _c('div',{key:child.id},[(_vm.checkIfBlockIsVisible(child.acf.roles_admitted, _vm.userRole))?_c('Cta',{attrs:{"theme":"menu","data":{
          url: child.url,
          title: child.content,
        }}}):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }