<template>
  <div
    :class="['wysiwyg', mergeProps.size ? `typo--${mergeProps.size}` : null]"
    v-html="mergeProps.value"
  />
</template>

<script>
export default {
  name: 'Wysiwyg',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    mergeProps() {
      const defaultProps = {
        value: '',
        size: 's',
      };

      Object.keys(this.data).forEach(
        (key) => this.data[key] === undefined && delete this.data[key],
      );
      return { ...defaultProps, ...this.data };
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    if (window.twttr && this.mergeProps.value.includes('twitter-tweet')) {
      // eslint-disable-next-line no-undef
      window.twttr.widgets.load();
    }

    // // eslint-disable-next-line no-undef
    // FB.init({
    //   xfbml: true,
    //   version: 'v10.0',
    // });
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/src/04_typography";

  .wysiwyg {
    p a {
      border-bottom: 1px solid currentColor;
      transition: border 0.2s $ease-custom;

      @include mq($and: $hover) {
        &:hover {
          border-color: transparent;
        }
      }
    }

    ul,
    ol {
      display: block;
      padding-left: $unit * 2;
    }

    ul {
      list-style-type: disc;
    }

    h4 {
      @extend %typo--p--600;

      &:not(:last-child) {
        margin-bottom: var(--spacer-xs);
      }
    }

    h5 {
      @extend %typo--p;
    }

    .iframe {
      &-youtube {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%; /*16:9*/

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .wp-block {
      &-image,
      &-video,
      &-embed {
        img,
        video {
          max-width: 100%;
        }

        figcaption {
          @extend %typo--p;
          color: var(--grey-l);
          text-align: center;
        }
      }

      &-image {
        margin-left: auto;
        margin-right: auto;

        &.is-style-small {
          max-width: var(--wrapper-xs);

          img {
            width: 100%;
          }
        }
        &.is-style-medium {
          max-width: var(--wrapper-s);

          img {
            width: 100%;
          }
        }
        &.is-style-large {
          max-width: var(--wrapper-m);

          img {
            width: 100%;
          }
        }
      }

      &-video {
        max-width: var(--wrapper-s);
        margin-left: auto;
        margin-right: auto;

        video {
          width: 100%;
        }
      }

      &-quote {
        border-left: var(--black) solid;
        padding: var(--spacer-m);
      }

      &-pullquote {
        border-top: var(--black) solid;
        border-bottom: var(--black) solid;
        padding: var(--spacer-m);

        p {
          @extend %typo--3;
        }
      }

      &-code {
        font-family: "Courier New", Courier, monospace;
        border: var(--black) solid;
        padding: var(--spacer-m);
      }

      &-table {
        td {
          border: var(--black) solid;
          padding: var(--spacer-s);
        }
      }

      &-gallery {
        .blocks-gallery-grid {
          display: grid;
          gap: var(--spacer-s);
          list-style: none;
          margin: 0;
          padding: 0;
        }

        @for $i from 1 to 9 {
          &.columns-#{$i} .blocks-gallery-grid {
            grid-template-columns: repeat($i, 1fr);
          }
        }
      }

      &-buttons,
      &-file {
        display: flex;
        gap: var(--spacer-s);
        align-items: center;
      }

      &-button,
      &-file__button {
        background: var(--grey-l);
        width: min-content;
        cursor: pointer;
        padding: var(--spacer-s);
      }

      &-media-text {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: column;
        gap: var(--spacer-s);
      }

      &-columns {
        display: grid;
        grid-auto-flow: column;
        gap: var(--spacer-s);
      }

      &-spacer {
        display: none;
      }
    }

    .is {
      &-content-justification {
        &-center {
          justify-content: center;
          &.is-vertical {
            align-items: center;
          }
        }
        &-right {
          justify-content: flex-end;
        }
      }

      &-vertical {
        flex-direction: column;
      }

      &-vertically-aligned {
        &-center {
          align-items: center;
        }
        &-bottom {
          align-items: end;
        }
      }
    }

    .has {
      &-drop-cap::first-letter {
        font-size: 100px;
      }

      &-medium-font-size {
        @extend %typo--p;
      }

      &-large-font-size {
        @extend %typo--3;
      }

      &-text-align {
        &-center {
          text-align: center;
        }
        &-right {
          text-align: right;
        }
      }

      &-media-on-the-right div {
        grid-column: 1;
      }
    }
  }
</style>
