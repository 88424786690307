<template>
  <ValidationProvider
    v-if="!data.hidden"
    v-slot="{ errors, classes }"
    mode="lazy"
    :rules="data.rules"
    :vid="data.vid"
    tag="div"
  >
    <div class="field field--checkbox">
      <slot
        name="label"
        class="field-label"
      />

      <div
        class="field-input"
        :class="classes"
      >
        <input
          :id="data.id ? data.id : formId ? `${formId}-${data.id || $vnode.key}` : data.id || $vnode.key"
          :ref="data.vid ? data.vid : null"
          v-model.trim="computedModel"
          :name="data.id || $vnode.key"
          :checked="data.checked ? 'checked' : false"
          :disabled="data.disabled ? 'disabled' : false"
          type="checkbox"
          @change="data.fn ? data.fn() : false"
        >
        <div class="field-after-input" />
      </div>

      <div
        slot="errors"
        class="input-errors"
      >
        {{ errors[0] }}
      </div>

      <!-- <Icon
        v-if="data.tooltip"
        v-tooltip.top-center="data.tooltip"
        name="help"
        size="xs"
      /> -->
    </div>
  </ValidationProvider>
</template>

<script>
// import Icon from '@/components/ui/icon';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'Checkbox',
  components: {
    ValidationProvider,
    // Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: Boolean,
      default: false,
    },
    formId: {
      type: String,
      default: () => {},
    },
  },
  computed: {
    computedModel: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit('update:model', val);
      },
    },
  },
};
</script>

<style>

</style>
