var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.page_previews && _vm.data.page_previews.length !== 0)?_c('div',{staticClass:"preview-row"},[_vm._l((_vm.data.page_previews),function(item,index){return _c('Thumb',{key:index,attrs:{"data":{
      acf: {
        page_preview: item.page.page_preview,
      },
      link: item.page.slug,
      title: {
        rendered: item.page.post_title,
      },
    }}})}),_c('div',{class:[
      'preview-row__images',
      _vm.data.layout,
      _vm.data.page_previews.length === 1 ? 'single' : null ]},_vm._l((_vm.data.page_previews),function(item,index){return _c('router-link',{key:index,attrs:{"to":item.page.slug}},[(item.page.page_preview.image)?_c('Figure',{ref:"image",refInFor:true,attrs:{"data":Object.assign({}, item.page.page_preview.image,
          {ratio: _vm.data.layout === 'symmetrical' ? 3 / 4 : true})}}):_vm._e()],1)}),1),_c('div',{class:[
      'preview-row__texts',
      _vm.data.layout,
      _vm.data.page_previews.length === 1 ? 'single' : null ]},_vm._l((_vm.data.page_previews),function(item,index){return _c('div',{key:index,staticClass:"preview-row__text"},[_c('router-link',{staticClass:"preview-row__text__content",attrs:{"to":item.page.slug}},[_c('h3',{staticClass:"typo--3"},[_vm._v(" "+_vm._s(item.page.post_title)+" ")]),_c('p',[_vm._v(_vm._s(item.page.page_preview.excerpt))])]),_c('Cta',{attrs:{"data":{
          icon: 'arrow',
          title: _vm.$labels.cta,
          url: item.page.slug,
        }}})],1)}),0)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }