<template>
  <Flex
    class="page-404"
    align="center"
    justify="center"
  >
    <div class="t-center">
      <div class="typo--1">
        404
      </div>
      <div>We're sorry, this page doesn't exists.</div>
    </div>
  </Flex>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style language="scss">
.page-404 {
  background-color: var(--green);
}
</style>
