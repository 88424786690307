var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"inspirationalScroll",staticClass:"inspirational-scroll"},_vm._l((_vm.sections),function(sectionContainer,indexA){return _c('div',{key:sectionContainer.section.title,ref:"section",refInFor:true,staticClass:"inspirational-scroll__section",attrs:{"id":("inspirational-scroll__section--" + indexA)}},[_c('div',{staticClass:"inspirational-scroll__section__images"},_vm._l((sectionContainer.section.images),function(image,indexB){return _c('div',{key:image.image.id,ref:"imagesContainers",refInFor:true,style:(_vm.setImageLayout(indexB, sectionContainer.alignment, image.width))},[_c('Figure',{ref:"images",refInFor:true,attrs:{"data":{
            default: image.image,
            ratio: true,
          }}})],1)}),0),_c('div',{ref:"sectionText",refInFor:true,staticClass:"inspirational-scroll__section__text"},_vm._l((_vm.textSections),function(textSectionContainer,indexC){return _c('h2',{key:indexC,class:[
          'typo--1',
          sectionContainer.section.title !==
            textSectionContainer.section.title
            ? 'inspirational-scroll__section__text--placeholder'
            : null ]},[_vm._v(" "+_vm._s(textSectionContainer.section.title)+" ")])}),0)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }