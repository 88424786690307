export default {
  user: (state) => state.user,
  customer: (state) => state.customer,
  customerSavedMethods: (state) => state.customerSavedMethods,
  favourites: (state) => state.favourites,
  favouritesArray: (state) => {
    const favs = [];
    const keys = Object.getOwnPropertyNames(state.favourites);
    keys.forEach((k) => {
      if (state.favourites[k].id) {
        favs.push(state.favourites[k].id);
      }
    });
    return favs;
  },
  checkIsFavourite: (state) => (id) => {
    if (state.favourites) {
      const keys = Object.getOwnPropertyNames(state.favourites);
      let fav = false;
      keys.forEach((k) => {
        if (state.favourites[k].id === id) {
          fav = true;
        }
      });
      return fav;
    }
    return false;
  },
  orders: (state) => (state.customer ? state.customer.orders : []),
  subscriptions: (state) => (state.customer ? state.customer.subscriptions : []),
  addresses: (state) => (state.customer ? {
    billing: state.customer.billing,
    shipping: state.customer.shipping,
  } : null),
  cart: (state) => state.cart,
  showCart: (state) => state.showCart,
  wcSettings: (state) => state.settings,
  paymentGateways: (state) => state.settings.woocommerce_payments,
  paymentGatewaysActive: (state) => state.paymentGatewaysActive,
  paymentGateway: (state) => state.paymentGateway, // user selected gateway
  paymentMetas: (state) => state.paymentMetas,
  geoData: (state) => state.countries,
  cartLoading: (state) => state.cartLoading,
  customerShippingMethods: (state) => state.customerShippingMethods,
  checkVariations: (state, getters, rootState) => (slug) => {
    const { variations } = rootState.wp.product[rootState.lang][slug];
    if (Array.isArray(variations)) {
      if (typeof variations[0] === 'number') {
        return false;
      }
      return variations;
    }
    return false;
  },
};
