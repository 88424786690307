<template>
  <Spacer
    :class="['asset-bar', theme !== '' ? `asset-bar--${theme}` : null]"
    y="m"
  >
    <h5
      v-if="theme !== 'thumb'"
      class="typo--p--600"
    >
      {{ asset.post_title ? asset.post_title : asset.title.rendered }}
      <span
        v-if="theme !== 'collection-bar'"
        class="asset-bar__info typo--6"
      >
        {{
          `.${asset.acf.asset.url.split(".").at(-1)} (${(
            asset.acf.asset.filesize /
            1024 /
            1000
          ).toFixed(2)} MB)`
        }}
      </span>
    </h5>
    <div class="asset-bar__actions">
      <component
        :is="action.component"
        v-for="(action, index) in filteredActions"
        :key="index"
        :data="setFn(action.action)"
        :added="action.action === 'collection' ? added : undefined"
        :asset="action.action === 'share' ? asset : undefined"
        :theme="theme"
      />
    </div>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';

import Collection from '@/components/asset-bar/asset-bar-collection';
import Download from '@/components/asset-bar/asset-bar-download';
import Share from '@/components/asset-bar/asset-bar-share';

export default {
  name: 'AssetBar',
  components: {
    Collection,
    Download,
    Share,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    removeState: {
      type: Boolean,
      default: false,
    },
    removeActions: {
      type: Array,
      default: () => {},
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      actions: [
        {
          action: 'collection',
          component: 'Collection',
        },
        {
          action: 'download',
          component: 'Download',
        },
        {
          action: 'share',
          component: 'Share',
        },
      ],
      added: this.removeState,
    };
  },
  computed: {
    ...mapGetters(['assetsInCollection']),
    asset() {
      return this.data.asset_bar ? this.data.asset_bar : this.data;
    },
    filteredActions() {
      return this.removeActions && this.removeActions.length > 0
        ? this.actions.filter(
          (action) => !this.removeActions.includes(action.action),
        )
        : this.actions;
    },
  },
  watch: {
    assetsInCollection(val) {
      this.checkStatus(val);
    },
  },
  mounted() {
    this.checkStatus(this.assetsInCollection);
  },
  methods: {
    checkStatus(val) {
      const check = val.find((item) => item.id === this.asset.acf.asset.id);
      if (!check) {
        this.added = false;
      } else if (!this.added) {
        this.added = true;
      }
    },
    fnCollection() {
      if (this.added) {
        this.added = false;
        this.$store.dispatch('onRemoveFromCollection', this.asset.acf.asset.id);
      } else {
        this.added = true;
        const {
          id, title, filesize, url,
        } = this.asset.acf.asset;

        const name = this.asset.title
          ? this.asset.title.rendered
          : this.asset.post_title;

        const newAssetInCollection = {
          id,
          name,
          title,
          filesize,
          url,
        };

        this.$store.dispatch('onAddToCollection', newAssetInCollection);
      }
    },
    setFn(action) {
      if (action === 'download') {
        return {
          external: true,
          download: this.asset.acf.asset.title,
          url: this.asset.acf.asset.url,
          target: '_blank',
        };
      }
      if (action === 'collection') {
        return {
          fn: this.fnCollection,
        };
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.asset-bar {
  align-items: center;
  border-bottom: var(--line) solid var(--grey-l);
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  grid-column: 1 / -1;

  .cta:not(.keep-text) {
    .cta__title {
      display: none;

      @include mq(s) {
        display: initial;
      }
    }
  }

  &--no-border {
    border-bottom: none;
  }

  &__info {
    color: var(--grey);
    text-transform: uppercase;
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    column-gap: var(--column-gap-l);
    row-gap: var(--row-gap-s);
  }

  &--thumb {
    border-bottom: none;
    padding: 0;

    .asset-bar {
      &__actions {
        grid-auto-flow: row;
      }
    }
  }
}
</style>
