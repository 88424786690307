<template>
  <div class="thumb__container">
    <FavoriteToggle
      v-if="favorite"
      :id="data.id"
      theme="thumb"
    />
    <router-link
      class="thumb"
      :to="data.link"
    >
      <Figure
        v-if="data.acf.page_preview && data.acf.page_preview.image"
        :data="{ ...data.acf.page_preview.image, ratio: 3 / 4 }"
      />
      <div
        v-else
        class="thumb__placeholder ratio--43"
      />
      <div>
        <Title
          :data="{
            value: data.taxonomy ? data.name : data.title.rendered,
            tag: 'h3',
          }"
        />
        <p
          v-if="
            data.acf.page_preview.excerpt &&
              data.acf.page_preview.excerpt !== ''
          "
        >
          {{ data.acf.page_preview.excerpt }}
        </p>
        <Cta
          :data="{
            icon: 'arrow',
            title: $labels.cta,
            url: data.link,
          }"
        />
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta.vue';
import FavoriteToggle from '@/components/ui/favorite-toggle.vue';

export default {
  name: 'Thumb',
  components: {
    Figure,
    Title,
    Cta,
    FavoriteToggle,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    favorite: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(['toggleFavourite']),
  },
};
</script>

<style lang="scss">
  .thumb {
    &__container {
      display: grid;

      > * {
        grid-area: 1 / 1;
      }

      .icon-star {
        z-index: 1;
        margin: var(--spacer-xs);
      }

      .favorite-toggle {
        width: max-content;
        height: max-content;
      }
    }

    &__placeholder {
      background-color: var(--green);
    }

    display: grid;
    row-gap: var(--row-gap-m);
  }
</style>
