<template>
  <div
    v-if="data.page_previews && data.page_previews.length !== 0"
    class="preview-row"
  >
    <Thumb
      v-for="(item, index) in data.page_previews"
      :key="index"
      :data="{
        acf: {
          page_preview: item.page.page_preview,
        },
        link: item.page.slug,
        title: {
          rendered: item.page.post_title,
        },
      }"
    />
    <div
      :class="[
        'preview-row__images',
        data.layout,
        data.page_previews.length === 1 ? 'single' : null,
      ]"
    >
      <router-link
        v-for="(item, index) in data.page_previews"
        :key="index"
        :to="item.page.slug"
      >
        <Figure
          v-if="item.page.page_preview.image"
          ref="image"
          :data="{
            ...item.page.page_preview.image,
            ratio: data.layout === 'symmetrical' ? 3 / 4 : true,
          }"
        />
      </router-link>
    </div>
    <div
      :class="[
        'preview-row__texts',
        data.layout,
        data.page_previews.length === 1 ? 'single' : null,
      ]"
    >
      <div
        v-for="(item, index) in data.page_previews"
        :key="index"
        class="preview-row__text"
      >
        <router-link
          :to="item.page.slug"
          class="preview-row__text__content"
        >
          <h3 class="typo--3">
            {{ item.page.post_title }}
          </h3>
          <p>{{ item.page.page_preview.excerpt }}</p>
        </router-link>
        <Cta
          :data="{
            icon: 'arrow',
            title: $labels.cta,
            url: item.page.slug,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Figure from '@/components/media/figure';
import Cta from '@/components/typo/cta';
import Thumb from '@/components/thumb';

export default {
  name: 'PreviewRow',
  components: {
    Figure,
    Cta,
    Thumb,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .preview-row {
    display: grid;
    row-gap: var(--row-gap-xl);

    @include mq(s) {
      row-gap: var(--row-gap-m);
    }

    .thumb__container {
      @include mq(s) {
        display: none;
      }
    }

    &__images,
    &__texts {
      column-gap: var(--column-gap-m);
      display: none;

      @include mq(s) {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
      }

      &.single,
      &.asymmetrical_left,
      &.asymmetrical_right {
        @include mq(s) {
          grid-template-columns: repeat(12, 1fr);
        }
      }

      &.single {
        :nth-child(1) {
          @include mq(s) {
            grid-column: span 9;
          }
        }
      }

      &.asymmetrical_left {
        :nth-child(1) {
          @include mq(s) {
            grid-column: span 4;
          }
        }
        :nth-child(2) {
          @include mq(s) {
            grid-column: span 7 / -1;
          }
        }
      }

      &.asymmetrical_right {
        :nth-child(1) {
          @include mq(s) {
            grid-column: span 6;
          }
        }
        :nth-child(2) {
          @include mq(s) {
            grid-column: span 5 / -1;
          }
        }
      }
    }

    &__images {
      align-items: end;
    }

    &__text {
      // align-content: space-between;
      // display: grid;
      // row-gap: var(--row-gap-m);

      // &__content {
      //   display: grid;
      // }
    }

    .cta {
      margin-top: var(--spacer-m);
    }
  }
</style>
