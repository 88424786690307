<template>
  <Flex class="login-page">
    <div>
      <Spacer
        class="typo--2 t-center"
        x="m"
        :top="{
          default: 'l',
          s: 'xl'
        }"
        bottom="m"
      >
        {{ $labels.recover }}
      </Spacer>
      <Wrapper
        size="m"
        boxed
      >
        <Form
          id="recovery-password"
          class="form--single"
        />
      </Wrapper>
    </div>
    <LoginRightPanel />
  </Flex>
</template>

<script>
import { mapGetters } from 'vuex';

import Form from '@/components/form';
import LoginRightPanel from '@/components/wc/login-right-panel';

export default {
  name: 'RecoverPassword',
  components: {
    Form,
    LoginRightPanel,
  },
  computed: {
    backgroundImage() {
      if (this.$store.state.wp.options && this.$store.state.wp.options.background_image) {
        return {
          'background-image': `url(${this.$store.state.wp.options.background_image})`,
        };
      }
      return null;
    },
    ...mapGetters(['user']),
  },
  created() {
    if (this.user) {
      this.$router.push({ name: 'Account' });
    }
  },
};
</script>
