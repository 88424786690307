<template>
  <div
    ref="inspirationalScroll"
    class="inspirational-scroll"
  >
    <div
      v-for="(sectionContainer, indexA) in sections"
      :id="`inspirational-scroll__section--${indexA}`"
      :key="sectionContainer.section.title"
      ref="section"
      class="inspirational-scroll__section"
    >
      <div class="inspirational-scroll__section__images">
        <div
          v-for="(image, indexB) in sectionContainer.section.images"
          ref="imagesContainers"
          :key="image.image.id"
          :style="
            setImageLayout(indexB, sectionContainer.alignment, image.width)
          "
        >
          <Figure
            ref="images"
            :data="{
              default: image.image,
              ratio: true,
            }"
          />
        </div>
      </div>
      <div
        ref="sectionText"
        class="inspirational-scroll__section__text"
      >
        <h2
          v-for="(textSectionContainer, indexC) in textSections"
          :key="indexC"
          :class="[
            'typo--1',
            sectionContainer.section.title !==
              textSectionContainer.section.title
              ? 'inspirational-scroll__section__text--placeholder'
              : null,
          ]"
        >
          {{ textSectionContainer.section.title }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import medusa from '@/assets/js/observer';
import Figure from '@/components/media/figure';

export default {
  name: 'InspirationalScroll',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      direction: 'down',
      previousY: 0,
      columnSpans: [4, 5, 6],
    };
  },
  computed: {
    sections() {
      const { sections } = this.data;
      let alignment = 'right';

      sections.forEach((e, index) => {
        if (index === 0) {
          e.alignment = alignment;
        } else if (sections[index - 1].section.images.length % 2 === 1) {
          if (alignment === 'right') {
            alignment = 'left';
          } else {
            alignment = 'right';
          }

          e.alignment = alignment;
        } else e.alignment = alignment;
      });

      return sections;
    },
    textSections() {
      return this.data.sections.filter((e) => e.section.title);
    },
    allText() {
      const array = this.$refs.sectionText;
      array.pop();
      return array;
    },
  },
  mounted() {
    this.observeSections();
    this.observeText();
    this.observeImages();
    this.lazyObserve();
    // window.addEventListener('scroll', this.handleScroll);
    // window.addEventListener('mousemove', this.handleMouseMove);
  },
  beforeDestroy() {
    if (this.$refs.section && this.$refs.section.length !== 0) {
      medusa.ref.removeTarget('sections-observer');
      medusa.ref.removeTarget('text-observer');
      medusa.ref.removeTarget('images-observer');
    }
    // window.removeEventListener('scroll', this.handleScroll);
    // window.removeEventListener('mousemove', this.handleMouseMove);
  },
  methods: {
    observerThreshold(steps) {
      const array = Array(steps + 1)
        .fill(0)
        .map((_, index) => index / steps || 0);

      return array;
    },
    observeSections() {
      medusa.ref.addTarget({
        id: 'sections-observer',
        threshold: this.observerThreshold(200),
        nodes: [],
        mode: 'default',
        callback: this.handleSectionsObserver,
        autoremove: false,
      });
    },
    observeText() {
      medusa.ref.addTarget({
        id: 'text-observer',
        threshold: this.observerThreshold(100),
        nodes: [],
        mode: 'default',
        callback: this.handleTextObserver,
        autoremove: false,
      });
    },
    observeImages() {
      medusa.ref.addTarget({
        id: 'images-observer',
        threshold: 0,
        nodes: [],
        mode: 'once',
        callback: this.handleImagesObserver,
        autoremove: true,
      });
    },
    lazyObserve() {
      this.$nextTick(() => {
        const sections = this.$refs.section;
        medusa.ref.pushToTarget('sections-observer', sections);

        const text = this.$refs.sectionText;
        medusa.ref.pushToTarget('text-observer', text);

        const images = [];
        this.$refs.images.forEach((e) => images.push(e.$el));
        medusa.ref.pushToTarget('images-observer', images);
      });
    },
    scrollDirection() {
      const currentY = Math.round(window.scrollY);

      if (currentY > this.previousY) {
        this.direction = 'down';
      } else if (currentY < this.previousY) {
        this.direction = 'up';
      }

      this.previousY = currentY;
    },
    handleSectionsObserver(entry) {
      this.scrollDirection();

      const sectionsLength = this.$refs.section.length;
      const sectionNumber = parseInt(entry.target.id.slice(-1), 10) + 1;
      const sectionChildren = Object.values(entry.target.children);
      const text = sectionChildren.find((e) => e.classList.contains('inspirational-scroll__section__text'));

      const topIsVisible = entry.boundingClientRect.top > 0;
      const bottomIsVisible = window.innerHeight - entry.boundingClientRect.bottom > 0;

      if (sectionNumber === 1) {
        if (this.direction === 'down' && !topIsVisible) {
          text.classList.add('inspirational-scroll__section__text--fixed');
        } else if (this.direction === 'up' && topIsVisible) {
          text.classList.remove('inspirational-scroll__section__text--fixed');
        }
      }

      if (sectionNumber > 1 && sectionNumber < sectionsLength) {
        if (this.direction === 'down' && !topIsVisible) {
          text.classList.add('inspirational-scroll__section__text--fixed');
        } else if (this.direction === 'up' && topIsVisible) {
          text.classList.remove('inspirational-scroll__section__text--fixed');
        }
      }

      if (sectionNumber === sectionsLength) {
        if (this.direction === 'down' && bottomIsVisible) {
          this.allText.forEach((e) => {
            e.style.display = 'none';
          });
          text.children.forEach((e) => {
            e.style.visibility = 'initial';
          });
        } else if (this.direction === 'up' && !bottomIsVisible) {
          text.children.forEach((e) => {
            e.removeAttribute('style');
          });
          this.allText.forEach((e) => {
            e.removeAttribute('style');
          });
        }
      }
    },
    handleTextObserver(entry) {
      if (entry.isIntersecting) {
        entry.target.style.opacity = -1 + 2 * entry.intersectionRatio;
      }
    },
    handleImagesObserver(entry) {
      if (entry.isIntersecting) {
        setTimeout(() => {
          entry.target.style.transform = 'scale(1.06)';
        }, 100);
      }
    },
    setImageLayout(index, alignment, width) {
      const style = {};

      style.gridRow = index % 2 === 0 ? `${index + 1} / span 1` : `${index + 1} / span 2`;

      if (alignment === 'right') {
        style.gridColumn = index % 2 === 1 ? `1 / span ${width}` : `span ${width} / -1`;
      } else {
        style.gridColumn = index % 2 === 1 ? `span ${width} / -1` : `1 / span ${width}`;
      }

      return style;
    },
    handleScroll() {
      // const { top } = this.$refs.inspirationalScroll.getBoundingClientRect();
      // if ((top - window.innerHeight) < 0) {
      //   const layer1 = `${top / 8}px`;
      //   const images = [];
      //   this.$refs.images.forEach((e) => images.push(e.$el));
      //   images.forEach((e, index) => {
      //     if (index % 3 === 0) {
      //       e.style.transform = `translateY(${layer1})`;
      //     }
      //   });
      // }
    },
    handleMouseMove(event) {
      const { imagesContainers } = this.$refs;
      const x = event.clientX;
      const y = event.clientY;

      imagesContainers.forEach((container) => {
        container.style.transform = `translate(-${x / 50}px, -${y / 50}px)`;
      });
    },
  },
};
</script>

<style lang="scss">
  .inspirational-scroll {
    &__section {
      display: grid;
      width: 100%;

      > * {
        grid-row: 1 / 1;
        grid-column: 1 / -1;
      }

      &__images {
        gap: var(--column-gap-m);
        display: grid;
        grid-template-columns: repeat(8, minmax(0, 1fr));
        min-height: 100vh;
        grid-auto-rows: minmax(300px, auto);

        @include mq(xs) {
          grid-auto-rows: minmax(400px, auto);
        }

        @include mq(s) {
          grid-template-columns: repeat(12, minmax(0, 1fr));
          grid-auto-rows: minmax(600px, auto);
        }

        > * {
          overflow: hidden;
          > * {
            transition: transform 1.2s ease-out;
          }
        }
      }

      &__text {
        width: 100%;
        display: grid;
        place-content: center;
        text-align: center;
        position: sticky;
        top: 0;
        height: 100vh;

        > * {
          max-width: 900px;
        }

        h2 {
          margin-bottom: 0;
          font-size: 50px;

          @include mq(s) {
            font-size: 90px;
          }
        }

        &--fixed {
          position: fixed;
          left: 0;
          padding-left: calc(var(--sidebar-width) + var(--spacer-wrapper));
          padding-right: var(--spacer-wrapper);
          z-index: 1;
        }

        &--placeholder {
          visibility: hidden;
        }
      }
    }
  }
</style>
