<template>
  <div>
    <AssetBar
      v-for="(asset, index) in data.assets"
      :key="index"
      :data="asset"
    />
  </div>
</template>

<script>
import AssetBar from '@/components/asset-bar';

export default {
  name: 'AssetBars',
  components: {
    AssetBar,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
