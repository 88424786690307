<template>
  <div class="search">
    <h2
      v-if="data && data.title"
      class="t-center"
    >
      {{ data.title }}
    </h2>
    <form
      class="search__form"
      @submit.prevent="submit"
    >
      <input
        v-model="input"
        class="typo--3"
        type="text"
        name="search"
        :placeholder="$labels.search_placeholder"
      >
      <Icon
        name="arrow"
        tag="button"
        size="l"
      />
    </form>
  </div>
</template>

<script>
import Icon from '@/components/ui/icon';

export default {
  name: 'SearchInput',
  components: {
    Icon,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      input: '',
    };
  },
  mounted() {
    if (this.$route.query.s !== '') {
      this.input = this.$route.query.s;
    }
  },
  methods: {
    submit() {
      if (this.input !== '') {
        this.$router.push({
          name: 'Search',
          query: {
            s: this.input,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
  .search {
    display: grid;
    row-gap: var(--row-gap-xl);

    h2 {
      margin-bottom: 0;
    }

    &__form {
      align-items: center;
      border-bottom: var(--line) solid;
      display: grid;
      grid-template-columns: 1fr min-content;

      ::placeholder {
        color: var(--grey);
      }

      input {
        min-height: 60px;
        width: 100%;
      }
    }
  }
</style>
