<template>
  <div
    :class="[
      'cta',
      `cta-theme--${theme}`,
      data.size ? `typo--${data.size}` : null,
      disabled ? 'cta-disabled' : null,
    ]"
  >
    <template v-if="data.fn">
      <button
        class="cta--icon"
        :style="data.iconFirst ? { flexDirection: 'row-reverse' } : null"
        @click.stop="!disabled ? data.fn() : null"
      >
        <div
          class="trim--p cta__title"
          v-html="data.title"
        />
        <Icon
          v-if="data.icon"
          :rotation="data.iconRotation ? data.iconRotation : null"
          :name="data.icon"
          :theme="theme === 'red' ? 'warning' : 'default'"
          :size="data.iconSize ? data.iconSize : 'm'"
          @click.native="!disabled ? data.fn() : null"
        />
      </button>
    </template>
    <template v-else-if="modal">
      <button
        class="cta--icon"
        @click="openModal"
      >
        <div
          class="trim--p cta__title"
          v-html="data.title"
        />
        <Icon
          v-if="data.icon"
          :rotation="data.iconRotation ? data.iconRotation : null"
          :name="data.icon"
          @click.native="openModal"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="hash">
      <button
        class="cta--icon"
        @click="goTo"
      >
        <div class="trim--p cta__title">
          {{ data.title }}
        </div>
        <Icon
          v-if="data.icon"
          :rotation="data.iconRotation ? data.iconRotation : null"
          :name="data.icon"
          @click.native="goTo"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="external">
      <a
        :href="data.url"
        :target="data.target"
        class="cta--icon"
        :download="data.download"
        @click.stop=""
      >
        <div
          class="trim--p cta__title"
          v-html="data.title"
        />
        <Icon
          v-if="data.icon"
          :rotation="data.iconRotation ? data.iconRotation : null"
          :name="data.icon"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </a>
    </template>
    <template v-else>
      <router-link
        class="cta--icon"
        :to="url"
      >
        <div
          class="trim--p cta__title"
          v-html="data.title"
        />
        <Icon
          v-if="data.icon"
          :rotation="data.iconRotation ? data.iconRotation : null"
          :name="data.icon"
          :size="data.iconSize ? data.iconSize : 'm'"
        />
      </router-link>
    </template>
  </div>
</template>

<script>
import gsap, { ScrollToPlugin } from 'gsap/all';
import Icon from '@/components/ui/icon';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'Cta',
  components: {
    Icon,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    url() {
      if (
        this.data.url
          && typeof this.data.url === 'string'
          && process.env.VUE_APP_BASE_URL
          && process.env.VUE_APP_BASE_URL !== '/'
      ) {
        return this.data.url.replace(process.env.VUE_APP_BASE_URL, '');
      }
      return this.data.url;
    },
    external() {
      return (
        this.url
          && typeof this.url === 'string'
          && (this.url.startsWith('http') || this.data.external)
      );
    },
    hash() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('#')
      );
    },
    modal() {
      return (
        this.url
          && typeof this.url === 'string'
          && this.url.startsWith('#modal-')
      );
    },
  },
  methods: {
    openModal() {
      if (!this.disabled) {
        const id = this.data.url.replace('#modal-', '');
        this.$bus.$emit('openModal', id);
      }
    },
    goTo() {
      if (!this.disabled) {
        const hash = this.data.url;
        gsap.killTweensOf(window);
        gsap.to(window, {
          scrollTo: {
            y: document.querySelector(hash),
            offsetY: 0, // header height
            autoKill: true,
          },
          duration: 1,
          ease: 'expo.out',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  a {
    display: inline-block;

    &.router-link-active {
      // style
    }
  }

  .cta-disabled {
    // cursor: progress;
    opacity: 0.5;

    * {
      // cursor: progress !important;
      pointer-events: none !important;
    }
  }

  button {
    font-size: inherit;
  }

  a,
  button {
    @media (hover: hover) {
      &:hover {
        .cta__title {
          opacity: 0.6;
        }
      }
    }
  }

  .cta {
    &--icon {
      align-items: center;
      display: flex;
      column-gap: var(--column-gap-xxs);
      width: max-content;
      // width: max-content;
    }
    &-theme {
      &--default {
        @extend %typo--4;
        text-transform: none;
        .cta__title {
          transition: opacity 0.2s;
        }
      }

      &--bold {
        @extend %typo--p--600;
      }

      &--menu {
        .cta--icon {
          width: 100%;
          justify-content: space-between;
          align-items: center;
        }
      }

      &--red {
        @extend %typo--5;
        a,
        button {
          color: var(--red);
        }
      }

      &--button,
      &--button-inverted {
        width: 100%;
        a,
        button {
          display: block;
          font-size: $form-input-font-size;
          line-height: $form-input-line-height;
          padding: $form-input-padding;
          border-radius: 0;
          // background: var(--black);
          background: var(--grey-input);
          color: var(--white);
          width: 100%;
          text-align: center;
        }
      }
      &--button-inverted {
        a,
        button {
          color: var(--grey-input);
          border: 1px solid var(--grey-input);
          background: var(--white);
        }
      }
      &--warning {
        color: var(--warning-color);
      }
      &--grey {
        color: var(--grey-input-l);;
      }
      &--login {
        a,
        button {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: row-reverse;
          width: 100%;
          column-gap: var(--spacer-m);
          border-radius: var(--border-radius);
          border: var(--line) solid var(--grey);
          padding: var(--spacer-s) var(--spacer-m);
          line-height: normal;

          @include mq($and: $hover) {
            &:hover {
              background: var(--white);
            }
          }
        }
      }
    }
  }
</style>
