import Content from '@/components/blocks/content';
import Media from '@/components/blocks/media';
import Repeater from '@/components/blocks/repeater';
import List from '@/components/blocks/list';
import Archive from '@/components/blocks/archive';
import Newsletter from '@/components/blocks/newsletter';
import Hero from '@/components/blocks/hero';
import Accordion from '@/components/blocks/accordion';
import Marquee from '@/components/blocks/marquee';
import Search from '@/components/blocks/search';
import PageCover from '@/components/blocks/page-cover';
import PreviewRow from '@/components/blocks/preview-row';
import MediaGrid from '@/components/blocks/media-grid';
import TextGrid from '@/components/blocks/text-grid';
import AssetBars from '@/components/blocks/asset-bars';
import MultiStatement from '@/components/blocks/multi-statement';
import InspirationalScroll from '@/components/blocks/inspirational-scroll';
import ImageSlider from '@/components/blocks/image-slider';
import Favorites from '@/components/blocks/favorites';
import Quote from '@/components/blocks/quote';

const components = new Map();

components.set('content', Content);
components.set('heading', Content);
components.set('media', Media);
components.set('repeater', Repeater);
components.set('list', List);
components.set('archive', Archive);
components.set('hero', Hero);
components.set('marquee', Marquee);
components.set('newsletter', Newsletter);
components.set('accordion', Accordion);
components.set('search', Search);
components.set('page_cover', PageCover);
components.set('preview_row', PreviewRow);
components.set('media_grid', MediaGrid);
components.set('text_grid', TextGrid);
components.set('asset_bars', AssetBars);
components.set('multi_statement', MultiStatement);
components.set('inspirational_scroll', InspirationalScroll);
components.set('image_slider', ImageSlider);
components.set('favorites', Favorites);
components.set('quote', Quote);

export default components;
