<template>
  <div class="footer__container">
    <router-link
      v-show="!$route.meta.noFooter"
      :to="nextPage ? nextPage.url : '/'"
      class="footer"
    >
      <h5 class="typo--p--600 fading">
        {{ $labels.footer_label }}
      </h5>
      <h2
        v-if="nextPage"
        class="fading"
      >
        {{ nextPage.content }}
      </h2>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Footer',
  data() {
    return {
      nextPage: null,
      footerIsVisible: true,
    };
  },
  computed: {
    ...mapGetters(['menu']),
  },
  watch: {
    $route() {
      this.getNextPage();
    },
  },
  mounted() {
    this.getNextPage();
  },
  methods: {
    getNextPage() {
      const menu = this.menu('Sidebar 1').items;
      const flatMenu = [];

      menu.forEach((x) => {
        x.children.forEach((y) => {
          flatMenu.push(y);
        });
      });

      if (this.$route.path === '/') {
        [this.nextPage] = flatMenu;
      } else {
        const currentIndex = flatMenu.findIndex(
          (e) => e.url === this.$route.path,
        );
        if (currentIndex + 1 === flatMenu.length) {
          [this.nextPage] = flatMenu;
        } else {
          this.nextPage = flatMenu[currentIndex + 1];
        }
      }
    },
  },
};
</script>

<style lang="scss">
  .footer {
    &__container {
      overflow: hidden;
    }
    background-color: var(--green);
    display: grid;
    justify-content: center;
    justify-items: center;
    padding: var(--spacer-m);
    padding-bottom: calc(var(--spacer-m) + var(--spacer-xs));
    row-gap: var(--row-gap-xxs);
    text-align: center;
    transition: transform 0.2s ease;
    transform: translateY(var(--spacer-xs));

    @media (hover: hover) {
      &:hover {
        transform: translateY(0);
      }
    }
  }
</style>
