var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image-bank"},[(_vm.isFiltered)?_c('h2',{staticClass:"results-title"},[_vm._v(" "+_vm._s(_vm.$labels.results_title)+" ")]):_vm._e(),_vm._l((_vm.filters
      ? _vm.filteredCategories
      : _vm.categories),function(category,catIndex){return _c('div',{key:catIndex,staticClass:"image-bank__category"},[_c('div',[_c('h2',{staticClass:"image-bank__category__header"},[_vm._v(" "+_vm._s(category.name)+" ")]),(category.items.length > 0)?_c('Grid',{staticClass:"image-bank__category__images",attrs:{"col":{ default: 12, xs: 6, m: 3 },"row-gap":"l","column-gap":"m"}},_vm._l((category.items),function(item,index){return _c('div',{key:item.id,staticClass:"item",on:{"click":function($event){return _vm.setCurrent(item, category, index)}}},[_c('Thumb',{attrs:{"data":item}})],1)}),0):_vm._e()],1),_c('Cta',{attrs:{"data":{
        icon: 'arrow',
        iconRotation: 90,
        title: _vm.$labels.download_all_cta,
        fn: function () { return _vm.downloadZip(category); },
      }}})],1)}),(_vm.isFiltered && _vm.filteredCategories.length === 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$labels.results_empty)+" ")]):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.current.item)?_c('Slider',{ref:"slider",attrs:{"current":_vm.current,"current-asset":_vm.currentAsset},on:{"set-current":function($event){return _vm.setCurrent()}}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }