export default {
  lang: (state) => state.lang,
  defaultLang: (state) => state.wp.i18n.default,
  langs: (state) => state.wp.i18n.langs,
  currentPost: (state) => state.currentPost,
  transition: (state) => state.transition,
  snackbar: (state) => state.snackbar,
  assetsInCollection: (state) => state.assetsInCollection,
  mobileHeaderIsVisible: (state) => state.mobileHeaderIsVisible,
  headerHeight: (state) => parseInt(state.headerHeight, 10),
  mobileMenuIsVisible: (state) => state.mobileMenuIsVisible,
};
