var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-up"}},[(_vm.show)?_c('Wrapper',{staticClass:"asset-bar--collection__container",attrs:{"size":"l"}},[_c('Spacer',{staticClass:"asset-bar asset-bar--collection",attrs:{"y":"m"}},[_c('Cta',{staticClass:"keep-text",attrs:{"theme":"bold","data":{
          title: ("New collection with " + _vm.items),
          icon: 'select',
          iconRotation: !_vm.barIsVisible ? -90 : 0,
          iconSize: 's',
          fn: function () { return _vm.toggleBar(); },
        }}}),_c('div',{staticClass:"asset-bar__actions"},_vm._l((_vm.actions),function(action,index){return _c(action.component,{key:index,tag:"component",attrs:{"data":_vm.setFn(action.action),"asset":action.action === 'share' ? _vm.asset : undefined}})}),1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.barIsVisible),expression:"barIsVisible"}],staticClass:"asset-bar--collection__list"},_vm._l((_vm.assetsInCollection),function(assetCollection){return _c('AssetThumb',{key:assetCollection.id,attrs:{"remove-actions":['share', 'download'],"theme":"collection-bar","remove-state":"","data":{
          post_title: assetCollection.name,
          acf: {
            asset: {
              id: assetCollection.id,
            },
          },
        }}})}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }