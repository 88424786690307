<template>
  <Grid
    class="filters"
    :col="{ default: '12', s: '3' }"
    column-gap="m"
  >
    <Column :size="{ default: '1', s: template === 'image' ? '3' : '1' }">
      <Spacer
        y="xxs"
        class="filter filter--search"
      >
        <input
          id="search"
          v-model="search"
          type="text"
          :placeholder="$labels.search_placeholder"
          name="search"
          @change="setSelectedFilters"
        >
        <Icon
          :name="search.length > 0 ? 'plus' : 'arrow'"
          :rotation="search.length > 0 ? 45 : 0"
          tag="button"
          :fn="
            () => {
              if (search.length > 0) {
                search = '';
                setSelectedFilters()
              }
            }
          "
        />
      </Spacer>
    </Column>
    <Spacer
      y="xxs"
      class="filter"
    >
      <select
        id="category"
        v-model="category"
        name="category"
        @change="setSelectedFilters"
      >
        <option
          selected
          value="placeholder"
        >
          Categories
        </option>
        <option
          v-for="cat in data.category"
          :key="cat"
          :value="cat"
        >
          {{ categoryById(type, cat).name }}
        </option>
      </select>
      <Icon
        name="arrow"
        :rotation="90"
      />
    </Spacer>
    <Spacer
      v-if="template === 'asset'"
      y="xxs"
      class="filter"
    >
      <select
        id="file_type"
        v-model="file_type"
        name="file_type"
        @change="setSelectedFilters"
      >
        <option
          selected
          value="placeholder"
        >
          File type
        </option><Icon
          name="arrow"
          :rotation="90"
        />
        <option
          v-for="filetype in data.filetype"
          :key="filetype"
          :value="filetype"
        >
          {{ filetype }}
        </option>
      </select>
      <Icon
        name="arrow"
        :rotation="90"
      />
    </Spacer>
    <Spacer
      v-if="template === 'asset'"
      y="xxs"
      class="filter"
    >
      <select
        id="extension"
        v-model="extension"
        name="extension"
        @change="setSelectedFilters"
      >
        <option
          selected
          value="placeholder"
        >
          Extension
        </option>
        <option
          v-for="ext in data.extension"
          :key="ext"
          :value="ext"
        >
          {{ ext }}
        </option>
      </select>
      <Icon
        name="arrow"
        :rotation="90"
      />
    </Spacer>
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/icon';

export default {
  name: 'Filters',
  components: {
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    selectedFiltersFn: {
      type: Function,
      required: true,
    },
    template: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      search: '',
      category: 'placeholder',
      file_type: 'placeholder',
      extension: 'placeholder',
    };
  },
  computed: {
    ...mapGetters(['categoryById']),
    type() {
      return this.template ? this.template : null;
    },
  },
  methods: {
    setSelectedFilters() {
      const filters = {
        search: this.search,
        category: this.category,
        file_type: this.file_type,
        extension: this.extension,
      };
      this.selectedFiltersFn(filters);
    },
  },
};
</script>

<style lang="scss">
  .filters {
    margin-bottom: var(--spacer-xl);

    .filter {
      align-items: center;
      border-bottom: var(--grey-l) var(--line) solid;
      display: grid;
      @extend %typo--p--600;

      > * {
        grid-area: 1 / 1;
      }

      .icon {
        justify-self: end;
        pointer-events: none;
      }

      &--search {
        .icon {
          pointer-events: all;
        }

        ::placeholder {
          color: var(--grey);
        }

        input {
          width: 100%;
        }
      }
    }
  }
</style>
