<template>
  <Grid
    :col="gridCol"
    :template="gridTemplate"
    row-gap="media-grid"
    column-gap="m"
  >
    <Column
      v-for="(item, index) in data.items"
      :key="index"
      :size="{ default: '1', s: data.items.length === 1 ? '8' : '1' }"
      :offset="{ default: '', s: data.items.length === 1 ? '4' : '' }"
      class="item"
    >
      <Media
        v-if="checkMedia(item)"
        :data="setMedia(item)"
      />
    </Column>
  </Grid>
</template>

<script>
import { setMedia, checkMedia } from '@/assets/js/utils';
import Media from '@/components/blocks/media';

export default {
  name: 'MediaGrid',
  components: {
    Media,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      setMedia,
      checkMedia,
    };
  },
  computed: {
    gridCol() {
      let cols = null;
      const elementsLength = this.data.items?.length
          || this.data.categories?.length
          || this.data.posts?.length;
      let defaultColsNumber = 4;
      if (elementsLength) {
        if (elementsLength === 1) {
          defaultColsNumber = 1;
        } else if (elementsLength < 5) {
          defaultColsNumber = 12 / elementsLength;
        } else {
          defaultColsNumber = 4;
        }
      }
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_size } = layout;
          cols = {
            ...cols,
            [media_query]:
                layout_size === 'auto'
                  ? defaultColsNumber.toString()
                  : layout_size,
          };
        });
      } else {
        cols = {
          default: 12,
          s: defaultColsNumber.toString(),
        };
      }
      return cols;
    },
    gridTemplate() {
      let template = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_type } = layout;
          template = {
            ...template,
            [media_query]: layout_type,
          };
        });
      } else {
        template = 'grid';
      }
      return template;
    },
  },
};
</script>

<style lang="scss"></style>
