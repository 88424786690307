<template>
  <div
    id="app"
    :class="{'simple-view' : $route.meta.simpleView === true}"
  >
    <AppSidebar v-if="$route.meta.simpleView !== true" />
    <AppHeader v-if="$route.meta.simpleView === true" />
    <MobileHeader v-if="$route.meta.simpleView !== true" />
    <transition
      :css="false"
      appear
      mode="out-in"
      @enter="enter"
      @leave="leave"
    >
      <router-view :key="$route.path" />
    </transition>
    <div
      v-if="$route.meta.simpleView !== true"
      ref="mask"
      class="mask"
    />
    <AppFooter v-if="$route.meta.simpleView !== true" />
    <LoginFooter v-if="$route.meta.simpleView === true" />

    <Modal />
    <Snackbar />
    <Loader />

    <Cookie v-if="options && options.cookies" />

    <CollectionBar />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import gsap from 'gsap';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';
import { loadScript } from '@/assets/js/utils';

import AppSidebar from '@/components/ui/app-sidebar';
import AppHeader from '@/components/ui/app-header';
import LoginFooter from '@/components/ui/login-footer';
import MobileHeader from '@/components/ui/mobile-header';
import AppFooter from '@/components/ui/footer';
import Modal from '@/components/ui/modal';
import Snackbar from '@/components/ui/snackbar';
import Loader from '@/components/ui/loader';
import Cookie from '@/components/ui/cookie';
import CollectionBar from '@/components/asset-bar/collection-bar';

// Un comment for WC
// import Cart from '@/components/wc/cart';

export default {
  name: 'App',
  components: {
    AppSidebar,
    AppHeader,
    LoginFooter,
    AppFooter,
    Modal,
    Cookie,
    Loader,
    Snackbar,
    CollectionBar,
    // Cart,
    MobileHeader,
  },
  mixins: [lazyload],
  computed: {
    ...mapGetters(['currentPost', 'options']),
  },
  created() {
    medusa.init();
  },
  async mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
      }, 400),
    );

    if (this.options.scripts && this.options.scripts.scripts_repeater) {
      this.options.scripts.scripts_repeater.forEach((item) => {
        if (item.inline) {
          const s = document.createElement('span');
          s.style.display = 'none';
          s.innerHTML = item.script;
          document.getElementsByTagName('body')[0].appendChild(s);
        } else {
          loadScript(item.script_src);
        }
      });
    }

    // autologin
    if (this.$store.state.wp.session && this.$store.state.wp.session.user && this.$store.state.wp.session.user.id && this.$store.state.wp.session.user.id > 0) {
      // const loggedIn = await this.$store.dispatch('login', {
      //   username: '',
      //   password: '',
      // });
      // if (loggedIn) {
      await this.$store.dispatch('loadCustomerData');
      //   // this.$router.push('/');
      // }
    }

    // this.$store.dispatch('onInitAssetsToCollection', localStorage.assetsInCollection ? JSON.parse(localStorage.assetsInCollection) : []);
  },
  methods: {
    enter(el, done) {
      const { from } = this.$store.state.route;
      // console.log(from);
      if (from.meta.simpleView) {
        if (this.$refs.mask) {
          gsap.set(this.$refs.mask, {
            scaleY: from ? 1 : 0,
            transformOrigin: '50% 0%',
          });
          const tl = gsap.timeline({
            defaults: { duration: 0.6, ease: 'ease.in' },
          });
          tl.add(
            gsap.to(this.$refs.mask, {
              scaleY: 0,
              duration: 0.6,
            }),
            0,
          );
        }
        done();
        return;
      }
      const fading = Array.from(el.querySelectorAll('.fading'));

      gsap.set(el, {
        y: window.innerHeight / 2,
      });
      gsap.set(this.$refs.mask, {
        scaleY: from ? 1 : 0,
        transformOrigin: '50% 0%',
      });
      gsap.set(fading, {
        autoAlpha: 0,
      });

      const tl = gsap.timeline({
        defaults: { duration: 0.6, ease: 'ease.in' },
      });

      if (from) {
        tl.add(
          gsap.to(this.$refs.mask, {
            scaleY: 0,
            duration: 0.6,
          }),
          0,
        );
      }

      tl.to(
        el,
        {
          y: 0,
          clearProps: 'all',
          duration: 0.4,
        },
        0,
      )
        .to(
          fading,
          {
            autoAlpha: 1,
            duration: 0.2,
            ease: 'ease.in',
          },
          0.2,
        )
        .then(() => {
          done();
        });
    },
    leave(el, done) {
      const fading = Array.from(el.querySelectorAll('.fading'));
      const { from } = this.$store.state.route;
      // console.log(from.meta);
      if (from.meta.simpleView) {
        done();
        return;
      }

      gsap.set(el, {
        y: 0,
      });
      gsap.set(this.$refs.mask, {
        scaleY: 0,
        transformOrigin: '50% 100%',
      });

      const tl = gsap.timeline({
        defaults: { duration: 0.6, ease: 'ease.out' },
      });

      tl.to(el, {
        y: (window.innerHeight / 2) * -1,
        duration: 0.4,
      })
        .to(
          fading,
          {
            autoAlpha: 0,
            y: -10,
            duration: 0.2,
            ease: 'ease.out',
          },
          0,
        )
        .to(
          this.$refs.mask,
          {
            scaleY: 1,
            duration: 0.6,
          },
          0,
        )
        .then(() => {
          window.scroll(0, 0);
          done();
        });
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/style.scss";
  #app {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;

    @include mq(s) {
      grid-template-rows: 1fr auto;
    }

    &.simple-view {
      display: block;
    }

    &:not(.simple-view) {
      > *:not(.sidebar):not(.snackbar) {
        margin-left: var(--sidebar-width);
      }
    }
  }

  .app-loaded {
    #loader {
      display: none;
    }
  }

  .mask {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    pointer-events: none;
    z-index: 1;
    background-color: var(--green);
  }
</style>
