<template>
  <transition name="slide-up">
    <Wrapper
      v-if="show"
      size="l"
      class="asset-bar--collection__container"
    >
      <Spacer
        class="asset-bar asset-bar--collection"
        y="m"
      >
        <Cta
          class="keep-text"
          theme="bold"
          :data="{
            title: `New collection with ${items}`,
            icon: 'select',
            iconRotation: !barIsVisible ? -90 : 0,
            iconSize: 's',
            fn: () => toggleBar(),
          }"
        />
        <div class="asset-bar__actions">
          <component
            :is="action.component"
            v-for="(action, index) in actions"
            :key="index"
            :data="setFn(action.action)"
            :asset="action.action === 'share' ? asset : undefined"
          />
        </div>
      </Spacer>
      <div
        v-show="barIsVisible"
        class="asset-bar--collection__list"
      >
        <AssetThumb
          v-for="assetCollection in assetsInCollection"
          :key="assetCollection.id"
          :remove-actions="['share', 'download']"
          theme="collection-bar"
          remove-state
          :data="{
            post_title: assetCollection.name,
            acf: {
              asset: {
                id: assetCollection.id,
              },
            },
          }"
        />
      </div>
    </Wrapper>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { fetchZip } from '@/api/wp';

import Collection from '@/components/asset-bar/asset-bar-collection';
import Download from '@/components/asset-bar/asset-bar-download';
import Share from '@/components/asset-bar/asset-bar-share';
import Cta from '@/components/typo/cta';
import AssetThumb from '@/components/asset-bar';

export default {
  name: 'CollectionBar',
  components: {
    Collection,
    Download,
    Share,
    Cta,
    AssetThumb,
  },
  data() {
    return {
      items: '0 items',
      actions: [
        {
          action: 'clear',
          component: 'Collection',
          label: this.$labels.collection_clear,
          icon: 'plus',
          iconRotation: 45,
        },
        {
          action: 'download',
          component: 'Download',
        },
        {
          action: 'share',
          component: 'Share',
        },
      ],
      added: false,
      show: false,
      barIsVisible: false,
      asset: {
        type: 'collection',
        url: '',
        title: {
          rendered: '',
        },
      },
    };
  },
  computed: {
    ...mapGetters(['assetsInCollection', 'customer']),
  },
  watch: {
    assetsInCollection(val) {
      if (val.length > 0) {
        this.show = true;

        this.setAssetUrl();
        this.asset.avoid = false;

        if (val.length === 1) {
          this.items = '1 item';
        } else {
          this.items = `${val.length} items`;
        }
      } else {
        this.show = false;
        this.items = '0 items';
      }
    },
  },
  methods: {
    async getZipLink() {
      const ids = this.$store.state.assetsInCollection.map((item) => item.id);
      const user_id = this.customer.id;
      try {
        const { data: dataPost } = await fetchZip({
          ids,
          user_id,
          // nonce: this.$store.state.wp.session.nonce,
        });
          // console.log(dataPost);
          // return dataPost.collectionUrl;
        return dataPost.fileUrl;
      } catch (err) {
        // this.$store.dispatch('onShowAlert', {
        //   title: 'Download Assets',
        //   content: 'Si è verificato un errore nel download dei file. <br>Se il problema persiste contattare il reparto tecnico.',
        //   persistent: false,
        // });
        return err;
      }
    },
    async downloadZip() {
      this.loadingDownload = true;
      const { url } = this.asset;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Kerakoll Assets Download.zip');
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.loadingDownload = false;
      // this.$store.dispatch('onClearAssetsFromCollection');
    },
    clearCollection() {
      this.$store.dispatch('onClearAssetsFromCollection');
    },
    setFn(action) {
      if (action === 'download') {
        return {
          ...this.actions[action],
          fn: this.downloadZip,
        };
      }
      if (action === 'clear') {
        return {
          ...this.actions[0],
          fn: this.clearCollection,
        };
      }
      return false;
    },
    toggleBar() {
      this.barIsVisible = !this.barIsVisible;
    },
    async setAssetUrl() {
      this.asset.avoid = true;
      this.asset.url = await this.getZipLink();
      this.asset.title.rendered = `Kerakoll Assets Download - ${this.items}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .slide-up {
    &-enter-active,
    &-leave-active {
      transition: transform 0.2s ease;
    }

    &-enter,
    &-leave-to {
      transform: translateY(100%);
    }
  }

  .asset-bar {
    &--collection {
      border-bottom: none;
      &__container {
        background: var(--yellow);
        position: sticky;
        bottom: 0;
        z-index: 1;
        width: initial;
      }

      &__list {
        overflow-y: auto;
        max-height: calc(70 * var(--vh) - var(--header-height));
      }
    }
  }
</style>
