<template>
  <div class="field field--number">
    <slot
      name="label"
      class="field-label"
    />
    <div class="field-input">
      <Icon
        name="minus"
        theme="circle"
        size="xs"
        :fn="() => data.fn && ! data.readonly ? data.fn([data.itemKey, computedValue-1]) : false"
      />
      <input
        :id="formId ? `${formId}-${data.id || $vnode.key}` : data.id || $vnode.key"
        v-model.trim="computedValue"
        :data-key="data.itemKey"
        :name="data.id || $vnode.key"
        type="number"
        :placeholder="data.placeholder"
        :readonly="data.readonly"
        @change="data.fn ? data.fn($event) : false"
      >
      <Icon
        name="plus"
        theme="circle"
        size="xs"
        :fn="() => data.fn && ! data.readonly ? data.fn([data.itemKey, computedValue+1]) : false"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/icon';

export default {
  name: 'Input',
  components: {
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    formId: {
      type: String,
      default: () => {},
    },
  },
  computed: {
    computedValue: {
      get() {
        return Number(this.value);
      },
      set(val) {
        this.$emit('update:value', Number(val));
      },
    },
  },
  methods: {

  },
};
</script>

<style lang="scss">
</style>
