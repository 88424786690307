<template>
  <header
    ref="menu"
    :class="[
      'mobile-header__container',
      mobileMenuIsVisible ? 'mobile-header__container--active' : false,
      !mobileHeaderIsVisible ? 'mobile-header__container--hidden' : false,
    ]"
  >
    <Spacer
      class="mobile-header"
      all="m"
    >
      <router-link
        class="mobile-header__logo"
        to="/"
      >
        <inline-svg :src="require('@/assets/svg/logo.svg')" />
      </router-link>
      <Icon
        size="l"
        :name="mobileMenuIsVisible ? 'plus' : 'menu'"
        :rotation="mobileMenuIsVisible ? 45 : 0"
        @click.native="TOGGLE_MOBILE_MENU()"
      />
    </Spacer>
    <!-- <transition name="fade-in"> -->
    <div v-if="mobileMenuIsVisible">
      <Menu layout="mobile" />
      <Footer layout="mobile" />
    </div>
    <!-- </transition> -->
  </header>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import Menu from '@/components/ui/app-sidebar-menu';
import Footer from '@/components/ui/app-sidebar-footer';
import Icon from '@/components/ui/icon.vue';

export default {
  name: 'AppHeader',
  components: {
    Menu,
    Footer,
    Icon,
  },
  data() {
    return {
      scrollY: 0,
    };
  },
  computed: {
    ...mapGetters([
      'mobileHeaderIsVisible',
      'headerHeight',
      'mobileMenuIsVisible',
    ]),
  },
  watch: {
    mobileMenuIsVisible() {
      this.toggleScroll();
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapMutations(['TOGGLE_MOBILE_HEADER', 'TOGGLE_MOBILE_MENU']),
    toggleScroll() {
      let scrollY = 0;
      const main = document.querySelector('.main');
      // const { menu } = this.$refs;

      if (this.mobileMenuIsVisible) {
        scrollY = window.scrollY;
        // setTimeout(() => {
        main.classList.add('main--locked');
        main.style.top = `-${scrollY}px`;
        // menu.style.position = 'fixed';
        // }, 400);
      } else {
        scrollY = main.style.top;
        main.classList.remove('main--locked');
        main.removeAttribute('style');
        // menu.removeAttribute('style');
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      }
    },
    handleScroll() {
      const position = window.scrollY;

      if (position > this.headerHeight && position > this.scrollY) {
        this.TOGGLE_MOBILE_HEADER(false);
        this.scrollY = position;
      } else {
        this.TOGGLE_MOBILE_HEADER(true);
        this.scrollY = position;
      }
    },
  },
};
</script>

<style lang="scss">
  .mobile-header {
    &__container {
      position: sticky;
      top: 0;
      transition: transform 0.2s ease, position 0.4s;
      width: 100%;
      z-index: 3;

      &--active {
        position: fixed;
      }

      &--hidden {
        transform: translateY(-100%);
      }

      @include mq(s) {
        display: none;
      }
    }

    align-items: center;
    background: var(--white);
    border-bottom: var(--line) var(--grey-l) solid;
    display: grid;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    min-height: var(--header-height);

    &__logo {
      display: grid;

      svg {
        fill: var(--black);
        height: 18px;
        width: auto;
      }
    }
  }
</style>
