<template>
  <div>
    <Cta
      :data="{
        icon: data.icon ? data.icon : added ? 'minus' : 'plus',
        title: data.label ? data.label : added ? $labels.remove : $labels.collection_cta,
        ...data,
      }"
      :theme="added || data.action === 'clear' ? 'red' : 'default'"
    />
  </div>
</template>

<script>
import Cta from '@/components/typo/cta';

export default {
  components: {
    Cta,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    added: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
