var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main"},[_c('PageCover',{ref:"cover",attrs:{"data":Object.assign({}, _vm.post.acf.page_cover, {id: _vm.post.id})}}),(_vm.post.acf.page_menu && _vm.post.acf.page_menu.active)?_c('PageMenu',{ref:"pageMenu",attrs:{"sections":_vm.post.acf.sections,"observed-section":_vm.observedSection,"set-signal":_vm.setSignal},on:{"reset-observer":function($event){return _vm.resetObserver()}}}):_c('Spacer',{attrs:{"bottom":'page--top',"use-margin":""}}),_vm._l((_vm.post.acf.sections),function(section,parentIndex){return _c('Section',{directives:[{name:"show",rawName:"v-show",value:(!_vm.favoritesAreEmpty(section)),expression:"!favoritesAreEmpty(section)"}],key:parentIndex,class:[("section--" + parentIndex), 'fading'],style:(section.blocks &&
        section.blocks.some(function (e) { return e.acf_fc_layout === 'image_slider'; })
        ? { overflow: 'hidden' }
        : null),attrs:{"layout":Object.assign({}, _vm.layout(section),
      {section_id: section.menu_name
        ? section.menu_name.replace(/\s/g, '-').toLowerCase()
        : null})}},_vm._l((section.blocks),function(block,index){return _c('Wrapper',{key:index,class:block.background_color && block.background_color !== ''
          ? ("background-color--" + (block.background_color))
          : null,attrs:{"size":block.wrapper,"boxed":false}},[_c(_vm.components.get(block.acf_fc_layout),{ref:"blocks",refInFor:true,tag:"component",class:[
          'block',
          ("block--" + (block.acf_fc_layout)),
          block.block_classes && block.block_classes !== ''
            ? block.block_classes.split(' ')
            : null ],attrs:{"data":block}})],1)}),1)}),_c('Spacer',{attrs:{"top":"page--bottom","use-margin":""}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }