<template>
  <Flex class="login-page">
    <Flex
      dir="column"
      justify="center"
    >
      <div>
        <Spacer
          class="login-title typo--2 t-center"
          x="m"
          top="m"
          bottom="m"
        >
          <Wrapper
            size="m"
            v-html="titleLeft"
          />
        </Spacer>
        <Wrapper
          size="s"
        >
          <!--   <div class="login-main-wrapper">
            <LoginForm theme="single" />
            <router-link
              class="password-lost"
              :to="{
                name: 'RecoverPassword'
              }"
              v-html="$labels.password_lost"
            />
          </div>
          <Cta
            theme="grey"
            :data="{
              title: $labels.register,
              url: '/register',
            }"
          />-->
          <div class="form--single form-theme--default">
            <Cta
              theme="button-inverted"
              :data="{
                title: $labels.login_sso,
                url: ssoLoginUrl,
                fn: microsoftLoginSSO
              }"
            />
          </div>
        </Wrapper>
      </div>
    </Flex>
    <LoginRightPanel />
  </Flex>
</template>

<script>
// import LoginForm from '@/components/wc/login';
import LoginRightPanel from '@/components/wc/login-right-panel';
import Cta from '@/components/typo/cta';

export default {
  name: 'Login',
  components: {
    // LoginForm,
    LoginRightPanel,
    Cta,
  },
  computed: {
    titleLeft() {
      if (this.$store.state.wp.options && this.$store.state.wp.options.login_title_left) {
        return this.$store.state.wp.options.login_title_left;
      }
      return null;
    },
    ssoLoginUrl() {
      // eslint-disable-next-line no-undef
      const url = `${__VUE_WORDPRESS__.routing.url}/admin`;
      return url;
    },
  },
  methods: {
    microsoftLoginSSO() {
      if (document.querySelector('.tlco-sso-mssignin-spacearound a')) {
        document.querySelector('.tlco-sso-mssignin-spacearound a').click();
      }
    },
  },
};
</script>

<style lang="scss">

.login-page {
  height: fit-content;
  min-height: calc(100vh - 64px);
  * {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: initial;
  }
  & > div {
    position: relative;
    width: 100%;
    @include mq(map-get($mq-breakpoints, 's')) {
      width: 50%;
    }
  }
  .wrapper {
    max-width: 500px;
    margin: 0 auto;
  }
  .wrapper--boxed {
    padding-left: 0;
    padding-right: 0;
  }
  .trim--p {
    line-height: inherit;
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
  .cta--icon {
    width: 100%;
    justify-content: center;
  }
  .cta.cta-theme--button {
    // font-weight: bold;
  }
  .iubenda-link,
  .cta-theme--default {
    font-size: $form-input-font-size;
    line-height: $form-input-line-height;
  }
}
.typo--2.login-title {
  font-size: 36px;
  @include mq(map-get($mq-breakpoints, 's')) {
    font-size: 40px;
  }
}
.login-title--right {
  color: var(--white);
  position: absolute;
  bottom: 0;
  padding: var(--spacer-l);
}
.login-main-wrapper {
  position: relative;
  .field--checkbox {
    color: var(--grey-input-l);
  }
  .form-footer {
    padding-top: var(--spacer-m);
  }
}
.password-lost {
  position: absolute;
  right: 20px;
  bottom: 120px;
  color: var(--grey-input-l);
  @include mq(map-get($mq-breakpoints, 's')) {
    right: 30px;
    bottom: 140px;
  }
}
</style>
