<template>
  <div>
    <Cta
      theme="menu"
      :data="{
        title: item.content,
        icon: 'select',
        iconRotation: !menuIsVisible ? -90 : 0,
        iconSize: 's',
        fn: () => toggleMenu(),
      }"
    />
    <div
      v-show="menuIsVisible"
      class="sidebar-menu__block sidebar-menu__block--sub"
    >
      <div
        v-for="child in item.children"
        :key="child.id"
      >
        <Cta
          v-if="checkIfBlockIsVisible(child.acf.roles_admitted, userRole)"
          theme="menu"
          :data="{
            url: child.url,
            title: child.content,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';

export default {
  components: {
    Cta,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['check-overflow'],
  data() {
    return {
      menuIsVisible: false,
    };
  },
  computed: {
    ...mapGetters(['menu', 'user', 'customer']),
    userRole() {
      if (this.customer && this.customer.role && this.customer.role[0]) {
        return this.customer.role[0];
      }
      return undefined;
    },
  },
  watch: {
    menuIsVisible() {
      this.$emit('check-overflow');
    },
  },
  methods: {
    toggleMenu() {
      this.menuIsVisible = !this.menuIsVisible;
    },
    checkIfBlockIsVisible(permissionNeeded, permission) {
      if (permissionNeeded[0]) {
        if (permissionNeeded.indexOf(permission) > -1) {
          return true;
        }
        return false;
      }
      return true;
    },
  },
};
</script>
