<template>
  <Spacer
    class="login-footer"
    y="m"
    x="s"
  >
    <Spacer
      top="s"
      bottom="s"
    >
      <inline-svg
        class="login-footer__logo"
        :src="require('@/assets/svg/icons/logo-white.svg')"
      />
    </Spacer>
    <Grid
      y="s"
      col="1"
      class="login-footer__menu"
    >
      <div
        v-for="(c, i) in footerCols"
        :key="i"
        class="login-footer__col"
      >
        <Spacer
          top="s"
          bottom="xs"
        >
          <p
            class="typo--3 login-footer__title"
            v-html="c.title"
          />
        </Spacer>
        <div
          class="typo--3 login-footer__content"
          v-html="c.content"
        />
      </div>
    </Grid>
    <Spacer
      class="login-footer__policies"
      top="s"
      bottom="m"
    >
      <div v-html="footerPolicies" />
    </Spacer>
    <Spacer
      class="login-footer__address"
      top="s"
      bottom="m"
    >
      <div v-html="footerAddress" />
    </Spacer>
  </Spacer>
</template>

<script>

export default {
  name: 'LoginFooter',
  computed: {
    footerCols() {
      if (this.$store.state.wp.options && this.$store.state.wp.options.login_footer_menu) {
        return this.$store.state.wp.options.login_footer_menu;
      }
      return [];
    },
    footerPolicies() {
      if (this.$store.state.wp.options && this.$store.state.wp.options.login_footer_policies) {
        return this.$store.state.wp.options.login_footer_policies;
      }
      return null;
    },
    footerAddress() {
      if (this.$store.state.wp.options && this.$store.state.wp.options.login_footer_address) {
        return this.$store.state.wp.options.login_footer_address;
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.login-footer {
  background: black;
}
.login-footer__logo {
  width: 120px;
  height: 24px;
}
.login-footer__menu {
  & > div {
    grid-column-start: 1;
    grid-column-end: 13;
    @include mq(map-get($mq-breakpoints, 's')) {
      &:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 5;
      }
      &:nth-child(2) {
        grid-column-start: 5;
        grid-column-end: 8;
      }
      &:nth-child(3) {
        grid-column-start: 8;
        grid-column-end: 11;
      }
      &:nth-child(4) {
        grid-column-start: 11;
        grid-column-end: 13;
      }
    }
  }
}
.login-footer {
  .login-footer__title,
  .login-footer__content,
  .login-footer__policies {
    font-size: 18px;
    line-height: 170%;
    font-weight: 200;
  }
  .login-footer__title {
    color: var(--grey);
  }
  .login-footer__content,
  .login-footer__policies {
    color: var(--white);
  }
  a {
    transition: all .4s cubic-bezier(.215, .61, .355, 1);;
    &:hover {
      color: var(--grey);
    }
  }
  .login-footer__address {
    p {
      max-width: none;
    }
  }
}
</style>
