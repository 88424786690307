<template>
  <Cta
    :data="{
      icon: 'arrow',
      iconRotation: 90,
      title: $labels.download_cta,
        ...data,
    }"
  />
</template>

<script>
import Cta from '@/components/typo/cta';

export default {
  components: {
    Cta,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style></style>
