<template>
  <router-link
    :to="{name: 'Login'}"
    class="header"
  >
    <inline-svg :src="require('@/assets/svg/logo-inline.svg')" />
  </router-link>
</template>

<script>

export default {
  name: 'AppHeader',
};
</script>

<style lang="scss">
  .header {
    display: block;
    padding: var(--spacer-s);
    box-shadow: 10px 0 20px 0 #0000001a;
    height: 66px;
    position: relative;
    svg {
      height: 22px;
      width: 256px;
    }
  }
</style>
