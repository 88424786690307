/* eslint-disable no-undef */

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import Page from '@/views/Page.vue';
import Search from '@/views/Search.vue';

import Single from '@/views/Single.vue';
// import SingleWork from '@/views/SingleWork.vue';
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import RecoverPassword from '@/views/RecoverPassword.vue';
import Account from '@/views/Account.vue';
import NotFound from '@/views/404.vue';
import Preview from '@/views/Preview.vue';
// Un comment for WC
// import wcRoutes from './wc';

// Un comment for WC
// const importedRoutes = wcRoutes;
const importedRoutes = [];

const singles = new Map();

// HINT: Add custom Post Type - 2
singles.set('post', Single);
singles.set('asset', Single);
singles.set('collection', Single);
singles.set('image', Single);

Vue.use(VueRouter);

const { show_on_front, page_on_front } = __VUE_WORDPRESS__.routing;

const { postTypes, i18n } = __VUE_WORDPRESS__.state;

const { name: siteTitle, description } = __VUE_WORDPRESS__.state.site;

let langs = null;

if (i18n) {
  store.commit('ADD_LANGUAGES', Object.keys(i18n.langs));

  langs = Object.keys(i18n.langs).filter((lang) => lang !== i18n.default);
  langs = langs.join('|');
} else {
  store.commit('ADD_LANGUAGES', ['default']);
}

const baseRoutes = [
  {
    path: '/login/',
    name: 'Login',
    component: Login,
    meta: {
      simpleView: true,
    },
  },
  {
    path: '/register/',
    name: 'Register',
    component: Register,
    meta: {
      simpleView: true,
    },
  },
  {
    path: '/password-lost/',
    name: 'RecoverPassword',
    component: RecoverPassword,
    meta: {
      simpleView: true,
    },
  },
  {
    path: '/account/',
    name: 'Account',
    component: Account,
    // meta: {
    //   simpleView: true,
    // },
  },
  {
    path: langs ? `/:lang(${langs})?/404` : '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: langs ? `/:lang(${langs})?/` : '/',
    name: 'Index',
    component: Page,
    meta: {
      slug: page_on_front,
      type: show_on_front ? 'pages' : 'posts',
    },
  },
  {
    path: langs ? `/:lang(${langs})?/search` : '/search',
    name: 'Search',
    component: Search,
  },
  {
    path: langs ? `/:lang(${langs})?/:parent?/:slug` : '/:parent?/:slug',
    name: 'Page',
    component: Page,
  },
  {
    path: langs ? `/:lang(${langs})?/:parent?/:parent2?/:slug` : '/:parent?/:parent2?/:slug',
    name: 'Page2',
    component: Page,
  },
  {
    path: langs ? `/:lang(${langs})?/preview/:type/:id` : '/preview/:type/:id',
    name: 'Preview',
    component: Preview,
  },
];

const routes = importedRoutes.concat(baseRoutes);

Object.keys(postTypes).forEach((key) => {
  if (key !== 'page' && key !== 'product') {
    routes.push({
      path: langs
        ? `/:lang(${langs})?/${postTypes[key].rewrite.slug}/:slug`
        : `/${postTypes[key].rewrite.slug}/:slug`,
      name: `${singles.get(key).name}_${key}`,
      component: singles.get(key),
      meta: {
        type: postTypes[key].rest_base,
      },
    });
  }
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    // if (to.hash) {
    // return {
    //   selector: to.hash,
    //   offset: { y: 100 },
    //   behavior: 'smooth',
    // };
    // }
    // if (from.path !== to.path) {
    //   return { x: 0, y: 0 };
    // }
    return false;
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.fetchCountry) {
    await store.dispatch('getCountries');
  }

  store.commit('TOGGLE_MOBILE_MENU', false);

  const noSpacePages = ['/download-area/', '/image-bank/', '/account', '/account/', '/404'];
  const noFooterPages = ['/download-area/', '/image-bank/', '/search', '/account', '/account/', '/404'];
  const noFavoritePages = ['/', '/download-area/', '/image-bank/', '/account', '/account/', '/404'];

  if (noSpacePages.some((e) => to.path === e)) {
    to.meta.noSpace = true;
  } else {
    to.meta.noSpace = false;
  }

  if (noFooterPages.some((e) => to.path === e)) {
    to.meta.noFooter = true;
  } else {
    to.meta.noFooter = false;
  }

  if (noFavoritePages.some((e) => to.path === e)) {
    to.meta.noFavorite = true;
  } else {
    to.meta.noFavorite = false;
  }

  if (
    to.meta.customView
    || to.name === '404'
    || to.name === 'Search'
    || to.name === 'Preview'
    || to.name === 'Login'
    || to.name === 'Register'
    || to.name === 'RecoverPassword'
    || to.name === 'Account'
  ) {
    if (from.path !== to.path) {
      store.commit('SET_CURRENT_POST', null);
    }

    document.title = `${to.name} — ${siteTitle}`;
    if (!document.body.classList.contains('app-loaded')) {
      document.body.classList.add('app-loaded');
    }
    next();
    return;
  }

  const slug = to.meta.slug ? to.meta.slug : to.params.slug;
  const lang = to.params.lang
    ? to.params.lang
    : i18n
      ? i18n.default
      : 'default';

  store.commit('SET_LANG', lang);

  const request = {
    type: to.meta.type || 'pages',
    slug,
    lang,
  };

  const promises = [];

  promises.push(store.dispatch('getSingleBySlug', request));

  Promise.all(promises).then((res) => {
    if (!document.body.classList.contains('app-loaded')) {
      document.body.classList.add('app-loaded');
    }

    const page = res[0];

    if (page && slug) {
      if (to.path === '/') {
        if (description) {
          document.title = `${siteTitle} — ${description}`;
        }
        document.title = `${siteTitle}`;
      } else {
        // Avoid HTML entities in title
        const p = document.createElement('p');
        p.innerHTML = page.title ? page.title.rendered : page.name;
        document.title = `${p.innerText} — ${siteTitle}`;
      }

      if (i18n) {
        const { default_locale } = i18n.langs[lang];
        document.documentElement.lang = default_locale.replace('_', '-');
      }

      next();
    } else {
      router.push({ name: '404' });
    }
  }).catch(() => {
    next({ name: 'Login' });
  });
});

export default router;
