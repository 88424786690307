<template>
  <div
    ref="multiStatement"
    class="multi-statement"
  >
    <div class="multi-statement__anchors">
      <h1
        v-for="(part, indexA) in data.multi_statement"
        :key="indexA"
        :class="[
          'multi-statement__anchor',
          descriptionIsVisible ? 'multi-statement__anchor--inactive' : null,
          activePart === part ? 'multi-statement__anchor--active' : null,
        ]"
        @mouseenter="handleMouseEnter(part)"
        @mouseleave="hideDescription()"
      >
        {{ part.anchor }}<sup>{{ indexA + 1 }}</sup>
      </h1>
    </div>
    <transition name="fade">
      <div
        v-show="descriptionIsVisible"
        class="multi-statement__description typo--6"
      >
        <sup>{{
          data.multi_statement.findIndex((part) => part === activePart) + 1
        }}</sup>
        <div v-html="activePart.description" />
      </div>
    </transition>
    <div class="multi-statement__descriptions typo--6">
      <div
        v-for="(part, indexB) in data.multi_statement"
        :key="indexB"
        class="multi-statement__description--mobile"
      >
        <sup>
          {{ indexB + 1 }}
        </sup>
        <div v-html="part.description" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      descriptionIsVisible: false,
      activePart: false,
      interval: null,
    };
  },
  watch: {
    $mq: {
      deep: true,
      handler() {
        if (this.$mq.isMobileS) {
          clearInterval(this.interval);
        }
      },
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    startTimer() {
      if (this.$mq.isMobileS) {
        return;
      }
      let index = 0;
      this.setActivePart(this.data.multi_statement[index]);

      this.interval = setInterval(() => {
        if (index < this.data.multi_statement.length - 1) {
          index += 1;
        } else {
          index = 0;
        }
        this.setActivePart(this.data.multi_statement[index]);
      }, 2000);
    },
    setActivePart(value) {
      this.activePart = value;
      this.descriptionIsVisible = true;
    },
    handleMouseEnter(value) {
      if (this.$mq.isMobileS) {
        return;
      }
      this.setActivePart(value);
      clearInterval(this.interval);
    },
    hideDescription() {
      this.descriptionIsVisible = false;
    },
  },
};
</script>

<style lang="scss">
  .multi-statement {
    display: grid;
    align-content: center;
    min-height: 700px;
    max-width: 1200px;
    padding: var(--row-gap-xl) 0;
    row-gap: var(--row-gap-xl);

    &__anchors {
      text-align: center;
      cursor: default;
    }

    &__anchor {
      display: inline;
      transition: opacity 0.2s;

      &--inactive {
        opacity: 0.4;
      }

      &--active,
      &:hover {
        opacity: 1;
      }

      sup {
        font-size: 14px;
        margin-left: var(--spacer-xs);
        vertical-align: text-top;

        @include mq(s) {
          font-size: 20px;
        }
      }
    }

    &__descriptions {
      display: grid;
      row-gap: var(--row-gap-m);

      @include mq(s) {
        display: none;
      }
    }

    &__description {
      bottom: var(--spacer-m);
      left: 0;
      display: none;
      position: absolute;
      text-align: center;
      justify-items: center;
      width: 100%;

      @include mq(s) {
        display: grid;
      }

      &--mobile {
        column-gap: var(--column-gap-xs);
        display: grid;
        grid-auto-flow: column;
        justify-content: start;

        sup {
          @extend %typo--5;
        }
      }

      sup {
        @extend %typo--5;
        font-size: 10px;
      }
    }
  }
</style>
