import drop from 'lodash.drop';
import uniq from 'lodash.uniq';
import groupBy from 'lodash.groupby';
import orderBy from 'lodash.orderby';
import intersection from 'lodash.intersection';
import { mapGetters } from 'vuex';

import medusa from '@/assets/js/observer';

export default {
  data() {
    return {
      request: null,
      type: null,
      items: [],
      categories: [],
      placeholder: 'Loading',
      offset: 0,
      skeletonItems: this.data.infinite_scroll ? parseInt(this.data.posts_per_page, 10) : this.data.archive_type === 'posts' ? this.data.posts.length : 12,
      selectedFilters: null,
      isFiltered: false,
      filters: null,
    };
  },
  computed: {
    ...mapGetters(['categoryById']),
    itemsFiltered() {
      if (this.selectedFilters) {
        return this.parseFilteredItems();
      }
      return this.items;
    },

    categoryFiltered() {
      return this.categories;
    },
  },
  watch: {
    selectedFilters: {
      deep: true,
      handler(val) {
        if (val) {
          if (val.search !== ''
            || val.category !== 'placeholder'
            || val.file_type !== 'placeholder'
            || val.extension !== 'placeholder'
          ) {
            this.isFiltered = true;
          } else {
            this.isFiltered = false;
          }
        }

        if (this.data.template === 'image') {
          this.parseCategory();
        }
      },
    },
  },
  mounted() {
    this.setRequest();

    this.loadItems().then(() => {
      if (this.data.infinite_scroll) {
        this.$nextTick(this.setInfiniteScroll);
      } else {
        this.$root.$children[0].lazyObserve(this.$el);
      }
    });
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes('infinite-scroll')) {
      medusa.ref.removeTarget('infinite-scroll');
    }
  },
  methods: {
    async loadItems() {
      const items = await this.$store.dispatch('getItems', this.request);
      if (items) {
        this.items = [...this.items, ...items];
      }

      if (this.data.filters) {
        this.setFilters(items);
      }

      if (this.data.template === 'image') {
        this.parseCategory();
      }

      return items;
    },
    setRequest() {
      let postType = null;
      const categories = {};
      let include = [];
      let slugArray = [];

      this.type = this.data.archive_type;

      if (this.type === 'archive') {
        const { archive, archive_category, tags } = this.data;
        postType = archive === 'post' || archive === 'page' || archive === 'asset' || archive === 'image' ? `${archive}s` : archive;
        if (archive_category) {
          const taxonomy = archive_category.taxonomy === 'category' ? 'categories' : archive_category.taxonomy;
          categories[taxonomy] = archive_category.term_id;
        }
        if (tags) {
          categories.tags = tags;
        }
        include = this.data[postType] ? this.data[postType] : [];
      } else if (this.type === 'posts') {
        const currentPostType = this.data[this.type][0].post_type;
        postType = currentPostType !== 'product' ? `${currentPostType}s` : currentPostType;
        slugArray = currentPostType !== 'product' ? this.data[this.type].map((item) => item.post_name) : [];
        include = currentPostType === 'product' ? this.data[this.type].map((item) => item.ID) : [];
      } else if (this.type === 'taxonomy') {
        postType = 'categories';
        include = this.data[postType].map((item) => item.term_id);
      }

      const slug = slugArray.length > 0 ? this.data.infinite_scroll ? drop(slugArray, this.offset) : slugArray : null;
      const offset = this.type !== 'posts' ? this.offset : 0;

      this.request = {
        type: postType,
        params: {
          ...categories,
          slug,
          include: include.length > 0 ? include : null,
          per_page: this.data.infinite_scroll ? parseInt(this.data.posts_per_page, 10) || 12 : parseInt(this.data.max_posts, 10) || 100,
          offset,
          orderby: slugArray.length > 0 ? 'include_slugs' : this.type === 'taxonomy' ? 'id' : 'menu_order',
          lang: this.$store.state.lang,
        },
      };
      if (this.request.params.orderby === 'menu_order') {
        this.request.params.order = 'asc';
      }
    },
    setInfiniteScroll() {
      medusa.ref.addTarget({
        id: 'infinite-scroll',
        threshold: 0.0,
        nodes: [],
        mode: 'default',
        callback: this.setOffset,
        autoremove: false,
      });

      medusa.ref.pushToTarget('infinite-scroll', this.$el.querySelector('.infinite'));
    },
    setOffset(entry) {
      if (entry.isIntersecting) {
        this.requestOffset();
      }
    },
    requestOffset() {
      if (this.type === 'posts' && this.offset + (parseInt(this.data.posts_per_page, 10) || 12) >= this.data[this.type].length) {
        medusa.ref.removeTarget('infinite-scroll');
        return;
      }
      this.offset += parseInt(this.data.posts_per_page, 10) || 12;
      this.setRequest();
      this.loadItems().then((newItems) => {
        this.$root.$children[0].lazyObserve(this.$el);

        if (newItems.length === 0) {
          medusa.ref.removeTarget('infinite-scroll');
        } else if ((window.pageYOffset + window.innerHeight) === document.body.offsetHeight) {
          this.requestOffset();
        }
      });
    },
    setFilters(items) {
      if (this.data.template === 'asset') {
        const category = uniq(items.map((item) => item.asset_category[0]));
        const filetype = uniq(items.map((item) => item.acf.asset.type));
        const extension = uniq(items.map((item) => item.acf.asset.subtype));

        this.filters = {
          category,
          filetype,
          extension,
        };
      } else if (this.data.template === 'image') {
        const category = uniq(items.map((item) => item.gds_taxonomies.image_category[0].term_id));

        this.filters = {
          category,
        };
      }
    },
    selectedFiltersFn(filters) {
      this.selectedFilters = filters;
    },
    parseCategory() {
      this.categories = [];
      const categoriesTemp = [];
      const categories = groupBy(this.itemsFiltered, (item) => item.gds_taxonomies.image_category[0].term_id);
      Object.keys(categories).forEach((key) => {
        const categoryObj = this.categoryById('image', parseInt(key, 10));
        const category = {
          id: key,
          name: categoryObj.name,
          slug: categoryObj.slug,
          items: categories[key],
        };
        categoriesTemp.push(category);
      });
      this.categories = orderBy(categoriesTemp, ['slug'], ['asc']);
    },
    parseFilteredItems() {
      let itemsSearch = null;
      let itemsCategory = null;
      let itemsFileType = null;
      let itemsExtension = null;
      const filters = [];
      const { items } = this;

      if (this.selectedFilters.search !== '') {
        filters.push('search');
        itemsSearch = this.items.filter((item) => item.title.rendered.toLowerCase().includes(this.selectedFilters.search.toLowerCase()));
      }

      if (this.selectedFilters.category !== 'placeholder') {
        filters.push('category');

        if (this.data.template === 'asset') {
          itemsCategory = this.items.filter((item) => item.asset_category[0] === this.selectedFilters.category);
        } else if (this.data.template === 'image') {
          itemsCategory = this.items.filter((item) => item.gds_taxonomies.image_category[0].term_id === this.selectedFilters.category);
        }
      }

      if (this.selectedFilters.file_type !== 'placeholder') {
        filters.push('file_type');
        itemsFileType = this.items.filter((item) => item.acf.asset.type === this.selectedFilters.file_type);
      }

      if (this.selectedFilters.extension !== 'placeholder') {
        filters.push('extension');
        itemsExtension = this.items.filter((item) => item.acf.asset.subtype === this.selectedFilters.extension);
      }

      return filters.length > 0 ? intersection(itemsSearch || items, itemsCategory || items, itemsFileType || items, itemsExtension || items) : items;
    },
  },
};
