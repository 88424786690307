import axios from 'axios';
import useAuthorizationAndRetryMiddleware from './auth';

// eslint-disable-next-line no-undef
let { url } = __VUE_WORDPRESS__.routing;
url = url.replace(':8888', ':3000');

// eslint-disable-next-line no-undef
const { nonce } = __VUE_WORDPRESS__.state.session;

const urlWp = `${url}/wp-json/`;

const ajax = axios.create(
  {
    baseURL: urlWp,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-WP-Nonce': nonce,
    },
  },
);
useAuthorizationAndRetryMiddleware(ajax);

export const ajaxCustom = (options) => {
  let baseURL;
  const { table } = options;

  switch (table) {
  case 'zip':
    baseURL = `${url}/wp-json/gds/v1/attachments/zip-builder/`;
    break;
  case 'search':
    baseURL = `${url}/wp-json/gds/v1/brand-portal/search/`;
    break;
  case 'favourites':
    baseURL = `${url}/wp-json/gds/v1/brand-portal/favourites/`;
    break;
  case 'last-updates':
    baseURL = `${url}/wp-json/gds/v1/brand-portal/last-updates/`;
    break;
  default:
    baseURL = urlWp;
  }

  const ajaxCustomValue = axios.create(
    {
      baseURL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-WP-Nonce': nonce,
      },
    },
  );
  useAuthorizationAndRetryMiddleware(ajaxCustomValue);
  return ajaxCustomValue;
};

export default ajax;
